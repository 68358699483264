import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Modal, Button, TextField, MenuItem, CircularProgress } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LocalImages } from "../../Utils/images";
import CustomInput from "../../Components/Form/CustomInput";
import { isValidString } from "../../Utils/validators";
import { collectionVideoData, collectionVideoUpdate } from "../../Features/Services/collectionVideo";
import LatestVideoShow from "./latestVideoShow";
import './collection.css';

const EditCollection = ({ onClose, collection }) => {
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [videos, setVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(true);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoCount, setVideoCount] = useState(0);

console.log("collection new", collection);

  const handleClose = () => {
    setOpen(false); 
    if (onClose) onClose(); 
  };
  const handleOpenVideoModal = () => {
    setOpen(false); 
    setOpenVideoModal(true); 
  };
  const handleCloseVideoModal = () => setOpenVideoModal(false);

  const [preview, setPreview] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileBase64(reader.result.split(",")[1]);
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSave = (collection) => {
    console.log("collection----", collection.id);
    
    setVideos(collection);
    setOpenModal(false);
  };

 

  const initialValues = {
    title: "",
    des: "",
    privacy: "",
    image: "",
  };

  const collectionSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    des: Yup.string().required("Description Name is required"),
    privacy: Yup.string().required("privacy is required")
  });

  const collectionForm = useFormik({
    initialValues,
    validationSchema: collectionSchema,
    onSubmit: async (values) => {
      try {
        const collectionPayload = {
          id: collection.id,
          title: values.title,
          des: values.des,
          privacy: values.privacy,
          image: fileBase64,
        };

        await collectionVideoUpdate(collectionPayload);
        setMessage("Collection update successfully!");
        onClose();
      } catch (error) {
        console.error("Error updating collection:", error);
        setMessage("Error updating collection!");
      }
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    // if (!collection || !collection.id) return;

    const successCallback = (data) => {
      if (data && data.length > 0) {
        const user = data[0];

        // Map data to formik fields
        collectionForm.setFieldValue("title", user.title || "");
        collectionForm.setFieldValue("des", user.des || "");
        collectionForm.setFieldValue("privacy", user.privacy || "");

        if (user.video_id && Array.isArray(user.video_id)) {
          setVideoCount(user.video_id.length);
        } else {
          setVideoCount(0);
        }

        setPreview(user.image ? `data:image/png;base64,${user.image}` : "");
      } else {
        console.error("No collection data found");
      }
    };

    const failureCallback = (err) => {
      console.error("Error fetching collection data:", err);
      setError(err);
    };

    // Fetch data using collection ID
    collectionVideoData(collection.id, successCallback, failureCallback);
  }, [collection]);
  
  
  return (
    <>
       <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 974,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <form onSubmit={collectionForm.handleSubmit} className="form collection-form">
          
            <div className="collection-heading">
              <span>Edit Collection</span>
              <div className="collection-close" onClick={handleClose}>
                <Box sx={{ position: "relative", marginRight: "10px" }}>
                  <img
                    src={LocalImages.closeIcon} 
                    alt="Close Icon"
                    style={{
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </div>
            </div>

            <div className="collection-video-count-show">
              <div className="video-icon">
              <Box sx={{ position: "relative", marginRight: "10px" }}>
                  <img
                    src={LocalImages.videoIconfilm} 
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </Box>
              </div>
              <div className="video-count-title">{videoCount} videos</div>
              </div>
            <div className="collection-info">
            
              <div className="collection-image">
                <input
                  type="file"
                  accept="image/*"
                  id="cover-image"
                  style={{ display: "none" }} 
                  onChange={handleFileChange}
                />

                {preview && <img src={preview} alt="Preview" style={{ width: "271px", height: "auto" }} />}
                <div className="image-label" onClick={() => document.getElementById("cover-image").click()}>
                  <Box sx={{ position: "relative", marginRight: "10px" }}>
                    <img
                      src={LocalImages.vector}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                      alt="Upload Icon"
                    />
                  </Box>
                  <label className="label-field">Upload Image</label>
                </div>


              </div>
            
              <div className="collection-info-main">
              
                {/* Title */}
                <label className="label-field">Collection Title</label>
                <CustomInput
                  fullWidth
                  margin="normal"
                  label="collection title"
                  id="collection-title"
                  name="title"
                  className="form-field"
                  value={collectionForm.values.title}
                  onChangeText={(value) => collectionForm.setFieldValue("title", value)}
                  handleBlur={collectionForm.handleBlur("title")}
                  isError={
                    isValidString(collectionForm.errors.title) && collectionForm.touched.title
                  }
                  errorMsg={collectionForm.errors.title}
                />

                {/* Description */}
                <label className="label-field">Description</label>
                <CustomInput
                  fullWidth
                  margin="normal"
                  label="collection des"
                  id="collection-des"
                  name="description"
                  className="form-field"
                  value={collectionForm.values.des}
                  onChangeText={(value) => collectionForm.setFieldValue("des", value)}
                  handleBlur={collectionForm.handleBlur("des")}
                  isError={
                    isValidString(collectionForm.errors.des) && collectionForm.touched.des
                  }
                  errorMsg={collectionForm.errors.des}
                />

                {/* Privacy */}
                <label className="label-field">Privacy</label>
                <TextField
                  select
                  fullWidth
                  margin="normal"
                  label="Privacy"
                  id="privacy"
                  name="privacy"
                  value={collectionForm.values.privacy}
                  onChange={collectionForm.handleChange}
                  onBlur={collectionForm.handleBlur}
                  error={collectionForm.touched.privacy && Boolean(collectionForm.errors.privacy)}
                  helperText={collectionForm.touched.privacy && collectionForm.errors.privacy}
                >
                  <MenuItem value="Private">Private - Only me</MenuItem>
                  <MenuItem value="Members">Members Only</MenuItem>
                  <MenuItem value="Public">Public</MenuItem>
                </TextField>

                <div className="submit-button">
                  <Button
                    variant="contained"
                    color="secondary"
                    collection={collection.id}
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenVideoModal}
                  >
                    Add Videos
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ cursor: "pointer" }}
                    disabled={!collectionForm.isValid || collectionForm.isSubmitting}
                  >
                    {collectionForm.isSubmitting ? <CircularProgress size={24} /> : "Save"}
                  </Button>
                </div>
              </div>

            </div>

          </form>
        </Box>
      </Modal>
      <Modal open={openVideoModal} collection={collection.id} onClose={handleCloseVideoModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
          }}
        >
          <LatestVideoShow onSave={handleSave} collection={collection} onClose={handleCloseVideoModal} />
        </Box>
      </Modal>
    </>
  );
};

export default EditCollection;
