import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import MenuItem from "@mui/material/MenuItem";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import { LocalImages } from "../Utils/images";
import { AuthContext } from "../Context/AuthContext";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Typography, useMediaQuery } from "@mui/material";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useEffect } from "react";
import jacoProfile from "../Assets/Images/michael_jaco.png";
import axios from "axios";
import moment from "moment";
import CastIcon from "@mui/icons-material/Cast";
import { SECRETS } from "../Utils/secrets";
import io from 'socket.io-client';
import { profileUserData } from "../Features/Services/profile";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  //   marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  border: "1px solid #ffa900",
  borderRadius: "22px",
  padding: 0,
  margin: 0,
  height: "44px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const pages = [
  { pageName: "Live Schedule", pageUrl: "/ourShows" },
  { pageName: "Video Library", pageUrl: "/videoGallery" },
  {
    pageName: "IWC Gear",
    pageUrl: "https://michaelkjaco.com/shop",
    pageTarget: "_blank",
  },
  {
    pageName: "IWC Social",
    pageUrl: "https://michaelkjacosocial.com",
    pageTarget: "_blank",
  },
  { pageName: "About", pageUrl: "/" },
  { pageName: "Contact", pageUrl: "/getInTouch" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function LoginHeader({ showLive }) {

  const [userData, setUserData] = useState({
    profile_image_url: null,
  });

  useEffect(() => {
    document.body.classList.add("videogallery-bg");
  }, []);

  useEffect(() => {
    const successCallback = (data) => {
      console.log("Admin Data", data)
      if (data && data.length > 0) {
        const user = data[0];
        setUserData({
          profile_image_url: user.profile_image_url || null,
          Role: user.Role,
        });
      }
    };

    const failureCallback = (err) => {
      console.error("Error fetching user data:", err);
    };

    profileUserData(null, successCallback, failureCallback);
  }, []);

  const socket = io("wss://michaelkjaco-streaming-node-app.onrender.com:443", {
    transports: ["websocket", "polling"],
    secure: true
  });

  const [isStreaming, setIsStreaming] = useState(false);
  useEffect(() => {
    socket.on('streamStatus', (status) => {
      console.log('Streaming Status ==> ', status);
      setIsStreaming(status);
    });

    return () => {
      socket.off('streamStatus');
    };
  }, []);
  const menuItems = [
    {
      text: "Video Gallery",
      icon: <CollectionsOutlinedIcon />,
      path: "/videoGallery",
    },
    {
      text: "Latest Videos",
      icon: <VideocamIcon />,
      path: "/latestVideos",
    },
    {
      text: "IWC Social",
      icon: <ViewComfyOutlinedIcon />,
      path: "https://michaelkjacosocial.com/choice/",
      pageTarget: "_blank",
    },
    {
      text: "Recently Watched",
      icon: <HistoryOutlinedIcon />,
      path: "/recentlyWatched",
    },
    ...(userData.Role === 1 ||
    userData.Role === 2 ||
    userData.Role === 3 ||
    userData.Role === 5 ||
    userData.Role === 6 ||
    userData.Role === null
      ? [
          {
            text: "Saved Videos",
            icon: <CollectionsBookmarkOutlinedIcon />,
            path: "/savedVideos",
          },
        ]
      : []),
    ...(userData.Role === 4
      ? [
          {
            text: (
              <>
                {isStreaming ? (
                  <span className="text-color-red" style={{ color: "#E00000" }}>
                    Live Streaming Now
                  </span>
                ) : (
                  "Live Stream"
                )}
              </>
            ),
            icon: (
              <LiveTvOutlinedIcon
                style={{ color: isStreaming ? "#E00000" : "inherit" }}
              />
            ),
            path: "/admin/livestream",
          },
        ]
      : []),
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [isAboutClick, setIsAboutClick] = useState(false);

  const {
    liveStreamStatus,
    toggleLiveStreamStatus,
    searchQuery,
    setSearchQuery,
    isLoggedIn,
    toggleLogin,
  } = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl01, setAnchorEl01] = useState(null);
  const [anchorEl02, setAnchorEl02] = useState(null);
  const [anchorEl03, setAnchorEl03] = useState(null);
  const [bell, setBell] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const isMenuOpen = Boolean(anchorEl02);

  const open = Boolean(anchorEl01);

  const handleClicked = (event) => {
    setAnchorEl03(event.currentTarget);
    setBell(!bell); // Toggle the menu visibility
  };

  const handleClosed = () => {
    setBell(false);
  };
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VIDEO_BASE_URL}notification/listNotification`
      );
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (bell) {
      fetchNotifications();
    }
  }, [bell]);

  const handleClick = (event) => {
    setAnchorEl01(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl01(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl02(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenu2Close = () => {
    setAnchorEl02(null);
  };

  const menuId = "primary-search-account-menu";

  const handleAboutClick = (e) => {
    e.preventDefault();
    setIsAboutClick(true);
    navigate("/", { state: { isAboutClick: true } });
  };

  useEffect(() => {
    if (location.state && location.state.isAboutClick) {
      const element = document.getElementById("aboutSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  console.log("isLoggedIn value in headr---", isLoggedIn);

  // const renderMenu = (
  //   <Menu
  //     sx={{ marginTop: '48px' }}
  //     anchorEl={anchorEl}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={isMenuOpen}
  //     onClose={handleMenu2Close}
  //   >
  //     <MenuItem onClick={() => {
  //       handleMenu2Close();
  //       navigate("/admin");
  //     }}>
  //       <HomeIcon style={{ marginRight: 12 }} />
  //       My Account
  //     </MenuItem>
  //     <Divider sx={{ my: 0.5 }} />

  //     <MenuItem onClick={() => {
  //       handleMenu2Close();
  //       navigate("/admin/send-notification");
  //     }}>
  //       <NotificationsIcon style={{ marginRight: 8 }} />
  //       Send Notification
  //     </MenuItem>
  //     <MenuItem onClick={handleMenu2Close}>
  //       <PlayCircleOutlinedIcon style={{ marginRight: 8 }} />
  //       New Live Stream
  //     </MenuItem>
  //     <MenuItem onClick={handleMenu2Close}>
  //       <VideocamIcon style={{ marginRight: 8 }} />
  //       My Videos
  //     </MenuItem>
  //   </Menu>
  // );

  const renderMenu = (role) => (
    <Menu
      sx={{ marginTop: "48px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenu2Close}
    >
      {userData.Role === 1 || userData.Role === 2 || userData.Role === 3 || userData.Role === 5 || userData.Role === 6 || userData.Role === null ? (
        <Box sx={{}}>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/watchlater");
            }}
          >
            <HomeIcon style={{ marginRight: 12 }} />
            My Account
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/collection");
            }}
          >
            <img
                      style={{
                        marginRight: 12
                      }}
                      src={ LocalImages.collection }
                    />
            My Collections
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/recentlyWatched");
            }}
          >
            <img
                      style={{
                        marginRight: 12
                      }}
                      src={ LocalImages.recently }
                    />
            Recently Watched
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/savedVideos");
            }}
          >
            <CollectionsBookmarkOutlinedIcon style={{ marginRight: 12 }} />
            Saved Videos
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/profile");
            }}
          >
             <img
                      style={{
                        marginRight: 12
                      }}
                      src={ LocalImages.setting }
                    />
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/getInTouch");
            }}
          >
            <HelpOutlinedIcon style={{ marginRight: 12 }} />
            Help & Support
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/getInTouch");
            }}
          >
            <QuestionAnswerOutlinedIcon style={{ marginRight: 12 }} />
            Send Feedback
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={toggleLogin}>
            <LogoutOutlinedIcon style={{ marginRight: 12 }} />
            Logout
          </MenuItem>
        </Box>
      ) : (
        <Box sx={{}}>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/admin");
            }}
          >
            <HomeIcon style={{ marginRight: 12 }} />
            My Account
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />

          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/admin/send-notification");
            }}
          >
            <NotificationsIcon style={{ marginRight: 8 }} />
            Send Notification
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/admin/livestream");
            }}
          >
            <PlayCircleOutlinedIcon style={{ marginRight: 8 }} />
            New Live Stream
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/savedVideos");
            }}
          >
            <VideocamIcon style={{ marginRight: 8 }} />
            My Videos
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />

          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/add-video-admin");
            }}
          >
            <HelpOutlinedIcon style={{ marginRight: 12 }} />
            Add Video
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/profile");
            }}
          >
             <img
                      style={{
                        marginRight: 12
                      }}
                      src={ LocalImages.setting }
                    />
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/getInTouch");
            }}
          >
            <HelpOutlinedIcon style={{ marginRight: 12 }} />
            Help & Support
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenu2Close();
              navigate("/getInTouch");
            }}
          >
            <QuestionAnswerOutlinedIcon style={{ marginRight: 12 }} />
            Send Feedback
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={toggleLogin}>
            <LogoutOutlinedIcon style={{ marginRight: 12 }} />
            Logout
          </MenuItem>
        </Box>
      )}
    </Menu>
  );

  const userRole = "admin"; // or 'user'

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ py: 1 }} className="header-content" position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>


          {/* <Box
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              style={{
                width: "120px",
                height: "110px",
                position: "absolute",
                top: -8,
                zIndex: 1200,
                borderRadius: "50%",
                ...(showLive || liveStreamStatus
                  ? {
                      border: "1px solid red",
                      backgroundColor: "rgba(210, 210, 210, 0.9)",
                      boxShadow: "0px 0px 12px 4px red",
                    }
                  : {}),
              }}
              src={LocalImages.logo}
              alt="Logo"
            />
            {showLive ? (
              <Box
                sx={{
                  top: 100,
                  left: 16,
                  zIndex: 1250,
                  position: 'absolute',
                  backgroundColor: "red",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  mt: "-8px",
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Typography variant="body" component={"body1"} color={"#fff"}>
                  Live
                </Typography>
              </Box>
            ) : null}
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              mt: "-10px",
            }}
          >
            <img
              className="logo-image"
              style={{
                width: "110px",
                height: "auto",
                borderRadius: "50%",
                position: showLive || liveStreamStatus || isStreaming ? "block" : 'absolute',
                top: -8,
                left: 12,
                ...(showLive || liveStreamStatus || isStreaming
                  ? {
                    border: "1px solid red",
                    backgroundColor: "rgba(210, 210, 210, 0.9)",
                    boxShadow: "0px 0px 12px 4px red",
                  }
                  : {}),
              }}
              src={LocalImages.iwclogo}
              alt="Logo"
              onClick={() => {
                navigate(isStreaming ? "/admin/livestream" : "/videoGallery");
              }}
            />
            {/* {showLive || isStreaming ? (
              <Box
                sx={{
                  backgroundColor: "red",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  mt: "-8px",
                  mb: "-20px",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Typography variant="body" component={"body1"} color={"#fff"}>
                  Live
                </Typography>
              </Box>
            ) : null} */}
          </Box>

          {/* 
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              mt: "-10px"
            }}
          >
            <img
              style={{
                width: "110px",
                height: "auto",
                borderRadius: "50%",
                position: showLive || liveStreamStatus ? "block" : 'absolute',
                top: -8,
                left: 12,
                ...(showLive || liveStreamStatus
                  ? {
                      border: "1px solid red",
                      backgroundColor: "rgba(210, 210, 210, 0.9)",
                      boxShadow: "0px 0px 12px 4px red",
                    }
                  : {}),
                
              }}
              src={LocalImages.logo}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
            />
            {showLive ? (
              <Box
                sx={{
                  backgroundColor: "red",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  mt: "-8px",
                  mb: "-20px",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Typography variant="body" component={"body1"} color={"#fff"}>
                  Live
                </Typography>
              </Box>
            ) : null}
          </Box> */}
          <Box sx={{ display: "flex", width: "65vw", position: "relative" }}>
            <Stack
              spacing={1}
              direction="row"
              sx={{ position: "absolute", top: "-23px", right: "-10px" }}
            >
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
                anchorEl={anchorEl01}
                open={open}
                onClose={handleClose}
                className="video-gallery-dropdown"
              >
                {menuItems.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.path && !item.path.startsWith("http") ? (
                      <MenuItem
                        component={Link}
                        to={item.path}
                        onClick={handleClose}
                        disabled={item.disabled}
                        style={item.style}
                      >
                        {item.icon}
                        {item.text}
                      </MenuItem>
                    ) : item.path && item.path.startsWith("http") ? (
                      <MenuItem
                        component="a"
                        href={item.path}
                        target={item?.pageTarget}
                        onClick={handleClose}
                        disabled={item.disabled}
                        style={item.style}
                      >
                        {item.icon}
                        {item.text}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick();
                          }
                          handleClose();
                        }}
                        disabled={item.disabled}
                        style={item.style}
                      >
                        {item.icon}
                        {item.text}
                      </MenuItem>
                    )}
                    {index === 2 || index === 6 ? (
                      <Divider sx={{ my: 0.5 }} />
                    ) : null}
                  </React.Fragment>
                ))}
              </StyledMenu>

              <Box
                sx={{
                  display: { xs: "none", sm: "flex", alignItems: "center" },
                }}
              >
                <Search sx={{ p: 0, m: 0 }}>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    sx={{
                      color: "#000",
                      position: "relative",
                      top: -15,
                      left: -30,
                      width: "25vw",
                    }}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />

                  <SearchIcon
                    className="video-gallery-icons"
                    sx={{ cursor: "pointer", ml: 1 }}
                    onClick={() => {
                      if (searchQuery !== "") {
                        navigate("/searchPage", {
                          replace: true,
                          state: { searchQuery },
                        });
                      }
                    }}
                  />
                </Search>

                <IconButton
                  size="large"
                  aria-label="show new notifications"
                  color="inherit"
                  sx={{ px: 0.5, py: 0.5, m: 0, cursor: "pointer" }}
                  onClick={handleClicked}
                >
                  <NotificationsIcon className="video-gallery-hover" />
                </IconButton>

                <Menu
                  sx={{
                    marginTop: "48px",
                    borderRadius: "8px",
                  }}
                  anchorEl={anchorEl03}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id="notification-menu"
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={bell}
                  onClose={handleClosed}
                  PaperProps={{
                    sx: {
                      maxWidth: 300,
                      maxHeight: 400,
                      overflowY: "auto",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <h2
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#ffffff",
                      zIndex: 1,
                      borderBottom: "1px solid #dee2e6",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#343a40",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <NotificationsIcon />
                    Notifications
                  </h2>
                  {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <MenuItem
                        key={index}
                        onClick={handleClosed}
                        sx={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          padding: "12px 16px",
                          borderBottom: "1px solid #dee2e6",
                          "&:hover": {
                            backgroundColor: "#e9ecef",
                          },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >

                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            marginRight: "8px",
                            backgroundColor: "#19315c",
                            color: "#FFA903",
                            fontSize: "20px",
                          }}
                        >
                          <AccountCircleIcon />
                        </Avatar>

                        <span style={{ flex: 1 }}>
                          {notification.notification}
                        </span>
                        <MenuItem sx={{ padding: 0 }}>
                          <span style={{ fontSize: "10px", color: "#868e96" }}>
                            {moment(notification.created_at).fromNow()}
                          </span>
                        </MenuItem>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      sx={{
                        padding: "12px 16px",
                        color: "#868e96",
                      }}
                    >
                      No notifications
                    </MenuItem>
                  )}
                </Menu>

                <IconButton
                  size="small"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {/* <Avatar
                    alt="User"
                    src={jacoProfile}
                    className="user-profile-pic"
                    sx={{ width: 40, height: 40, border: "2px solid #ffa900" }}
                  /> */}

                  <Box display="flex" justifyContent="center" alignItems="center">
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        border: "2px solid #ffa900",
                        objectFit: "contain",
                        borderRadius: "50%", // Circular image
                      }}
                      src={
                        userData.profile_image_url
                          ? `data:image/png;base64,${userData.profile_image_url}`
                          : LocalImages.michaelJaco
                      }
                      alt="Profile"
                    />
                  </Box>
                </IconButton>
              </Box>

              <IconButton
                size="large"
                edge="end"
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{
                  px: 0.5,
                  py: 0.5,
                  ml: 0,
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <MenuIcon
                  className="video-gallery-hover"
                  onClick={handleClick}
                />
              </IconButton>
            </Stack>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              gap: 0.9,
            }}
          >
            <IconButton
              size="xl"
              aria-label="show new notifications"
              color="inherit"
              sx={{ px: 0.5, py: 0.5, m: 0, cursor: "pointer" }}
            >
              <CastIcon sx={{ color: "grey" }} />
            </IconButton>

            <SearchIcon
              className="video-gallery-hover"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (searchQuery !== "") {
                  navigate("/searchPage", {
                    replace: true,
                    state: { searchQuery },
                  });
                }
              }}
            />

            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
              sx={{ px: 0.5, py: 0.5, m: 0, cursor: "pointer" }}
              onClick={handleClicked}
            >
              <NotificationsIcon className="video-gallery-hover" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{
        py: 2.5, width: "full", backgroundColor: isStreaming ? "#FF0000" : "#FFA900",
      }}></Box>
      {renderMenu(userRole)}
    </Box>
  );
}
export default LoginHeader;
