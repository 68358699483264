import React, { useContext, useRef, useState, useEffect } from 'react';
import { Container, Button, Box, Typography, Modal } from '@mui/material';
import Webcam from 'react-webcam';
import { useStreamStatus } from '../../StreamStatusContext';
import Header from './Header';
import startMaster from './master';
import { stopMaster } from './master';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import CustomerList from './CustomerList';
import LiveChat from '../LiveChat';
import MainHeader from "../../Components/MainHeader";
import './LiveStream.css';
import io from 'socket.io-client';
import LoginHeader from '../../Components/LoginHeader';
import AddFormComponent from '../LiveSteam/add-video';
import FixedBottomNavigation from '../../Components/FixedBottomNavigation';


const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION, 
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});
const formatDateTime = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}-${month}-${year}_${hours}:${minutes}`;
};
const uploadToS3 = async (file) => {
  const currentDate = new Date();
  const formattedDateTime = formatDateTime(currentDate);
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: `Live Stream Recording ${formattedDateTime}.webm`,
    Body: file,
    ContentType: 'video/webm',
  };
  console.log("params", params);
  
  try {
    const command = new PutObjectCommand(params);
    const response = await s3Client.send(command);
    console.log('Successfully uploaded video to S3:', response);
    const s3Url = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.key}`;
    console.log('S3 URL:', s3Url); 
    return s3Url;
  } catch (error) {
    console.error('Error uploading video to S3:', error);
  }
};

const LiveStream = () => {

  const socket = io("wss://michaelkjaco-streaming-node-app.onrender.com:443", {
    transports: ["websocket", "polling"],
    secure: true
  });
  

  
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [username, setUsername] = useState('');
  const webcamRef = useRef(null);
  const localViewRef = useRef(null);
  const remoteViewRef = useRef(null);
  const { isStreaming, startStreaming, stopStreaming, isLiveEnded, endLive } = useStreamStatus();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0); // New state for recording time
  const [timerInterval, setTimerInterval] = useState(null); // State for storing timer interval
  const [isChatVisible, setIsChatVisible] = useState(false); // New state for chat visibility
console.log("isChatVisible", isChatVisible);

  // Check recording start or not after some time interval
  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
      setTimerInterval(interval);
      return () => clearInterval(interval);
    } else {
      clearInterval(timerInterval);
    }
  }, [isRecording]);

  useEffect(() => {
    // Retrieve username from localStorage
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      setUsername(userInfo.username); // Set the username correctly
    }
  }, []);

  
  const formValues = {
    channelName: 'jaco_live_stream', // Replace with your signaling channel name
    ingestMedia: true,
    sendAudio: true,
    sendVideo: true,
    widescreen: true,
    forceSTUN: false,
    forceTURN: false,
    openDataChannel: false,
    useTrickleICE: true,
    clientId: Math.random(),
    sendHostCandidates: true,
    acceptHostCandidates: true,
    sendRelayCandidates: true,
    acceptRelayCandidates: true,
    sendSrflxCandidates: true,
    acceptSrflxCandidates: true,
    sendPrflxCandidates: true,
    acceptPrflxCandidates: true,
    sendTcpCandidates: true,
    acceptTcpCandidates: true,
    sendUdpCandidates: true,
    acceptUdpCandidates: true,
  };

  // code for start recording
  const startRecording = () => {
    const stream = localViewRef.current.srcObject;
    const options = { mimeType: 'video/webm; codecs=vp9' };
    const recorder = new MediaRecorder(stream, options);
    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordedChunks((prev) => [...prev, event.data]);
      }
    };

    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
    setRecordingTime(0); // Reset recording time
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecording(false);
  };

  // The below snippet is used for download stream video and upload it onto S3 bucket
  
  const downloadRecording = async () => {
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    await uploadToS3(blob); // Upload to S3
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    URL.revokeObjectURL(url); // Correct usage of revokeObjectURL
    setRecordedChunks([]); // Reset the recorded chunks after downloading
  };

  const startStreamingHandler = async () => {
    try {
      await startMaster(localViewRef.current, remoteViewRef.current, formValues);
      socket.emit('startStreaming');
      startStreaming();
      startRecording();
      setIsChatVisible(true); 
    } catch (error) {
      console.error('Error starting streaming:', error);
    }
  };

  const anotherButtonRef = useRef(null);
  const stopStreamingHandler = async () => {
	console.log('Stoping Streaming');
    await stopMaster();
    stopStreaming();
    stopRecording();
    endLive();
    setTimeout(() => {
      if (anotherButtonRef.current) {
        anotherButtonRef.current.click();
        endLive();
      }
	  socket.emit('stopStreaming');
      setIsFormVisible(true);
    }, 1000);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  const FormComponent = () => {

    return (
      <Modal open={isFormVisible} onClose={() => setIsFormVisible(false)} className='livestream-modal'>
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Typography className="livestream-title" variant="h6" mb={2}>Add Live Stream Form</Typography>
          <AddFormComponent/>
          </Box>
          </Modal>
    )
  }
  return (
    <Box>
      {/* <LoginHeader showLive={!isStreaming} /> */}
      <MainHeader />
      <Container className="live-stream-main-cover">
      <div className="live-stream-cover">
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Box position="relative" width="100%">
          <Webcam audio={false} ref={webcamRef}
            style={{width: '100%',height: 'auto', borderRadius: 8,boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',}} />
          {isRecording && (
            <Box
              position="absolute"
              top={10}
              left={10}
              bgcolor="rgba(0, 0, 0, 0.5)"
              color="white"
              px={2}
              py={1}
              borderRadius={4}
              display="flex"
              alignItems="center"
            >
              <Box
                bgcolor="red"
                width={10}
                height={10}
                borderRadius="50%"
                mr={1}
                animation="blinker 1s linear infinite"
              />
              <Typography variant="body2">Recording</Typography>
              <Typography variant="body2" ml={2}>
                {formatTime(recordingTime)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box mt={2} display="flex" justifyContent="center" className={`button-cover-div`}>
          <Button variant="contained" className={`start-live-button ${isStreaming ? 'active-button' : ''}`} onClick={startStreamingHandler} disabled={isStreaming} >
            Start Live
          </Button>
          <Box ml={1}>
            <Button variant="contained" className={`end-live-button ${!isStreaming ? 'active-button' : ''}`} onClick={stopStreamingHandler} disabled={!isStreaming}>
              End Live
            </Button>
          </Box>

          <Box ml={1}>
            <Button ref={anotherButtonRef} variant="contained" onClick={downloadRecording} disabled={isStreaming || recordedChunks.length === 0} style={{ display: "none" }}>
              Download Recording
            </Button>
          </Box>
        </Box>
        <Box mt={4}>
          <video
            ref={localViewRef} hidden autoPlay playsInline muted
            style={{ maxWidth: '100%', borderRadius: 8, boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>
        <Box mt={4}>
          <video hidden ref={remoteViewRef} autoPlay playsInline
            style={{ maxWidth: '100%', borderRadius: 8, boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>
      </Box>
      </div>
      <div className="chat-cover">
      {isChatVisible && <LiveChat room={'room1'} userName={username} />}  
        {/* <LiveChat room={'room1'} userName={username} />  */}
      </div>
      </Container>
        <FormComponent/>
      <FixedBottomNavigation />
    </Box>
  );
};
export default LiveStream;
