

import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
  IconButton,
  Menu,
  Box
} from "@mui/material";
import { LocalImages } from "../../Utils/images";
import EditIcon from '@mui/icons-material/Edit';
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import { wildSearchVideos, videoDeleteById } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const LatestVideoAccount = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [videoDetails, setVideoDetails] = useState([]);
  const [message, setMessage] = useState("");
  const handleViewAll = () => {
    navigate("/latestVideos"); // Redirect to the latestVideos route
  };
  const {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  } = useVideoCategories();

  const {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  } = useVideoCollections();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFieldInitial = {
    collectionName: "",
    categoryName: "",
    newTitle: "",
  };
  const [searchField, setSearchField] = useState(searchFieldInitial);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    searchVideo();
  }, []);

  const searchVideo = () => {
    setSearching(true);
    wildSearchVideos(searchField)
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setRows([]);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const resetSearch = () => {
    setSearchField(searchFieldInitial);
    setRows([]);
  };

    const editVideo = (item) => {
      navigate("/editVideo", {
        state: { video_id: item.video_id },
      });
    };
  
    const exportVideo = async (item) => {
      if (!item.object_url) {
        console.error("Video URL not available");
        return;
      }
  
      try {
        // Fetch the video data as a Blob
        const response = await fetch(item.object_url);
        if (!response.ok) throw new Error("Failed to fetch video");
  
        const blob = await response.blob();
  
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = item.new_title || "downloaded_video";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error exporting video:", error);
      }
    };
  
    const removeVideo = async (video_id) => {
      console.log("Attempting to delete video with ID:", video_id);
      if (!video_id) return;
  
      try {
        setIsLoading(true);
        const response = await videoDeleteById(video_id);
        setVideoDetails((prevVideos) =>
          prevVideos.filter((item) => item.video_id !== video_id)
        );
        setMessage("Video successfully deleted");
        console.log(`Video with ID: ${video_id} successfully deleted.`);
  
      } catch (error) {
        console.error("Error deleting video:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const ActionMenu = ({ item }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
      
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        return (
          <>
            <IconButton onClick={handleClick}>
              <EditIcon style={{ color: "#fbac18" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  editVideo(item); // Using item here
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  exportVideo(item); // Using item here
                }}
              >
                Export Video
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (!isLoading) removeVideo(item.video_id); // Using item here
                }}
                style={{
                  color: isLoading ? "gray" : "red",
                  pointerEvents: isLoading ? "none" : "auto",
                }}
              >
                Remove
              </MenuItem>
            </Menu>
          </>
        );
      };
      
      const handlevideoID = (videoId) => {
        console.log("videoId", videoId);
        
        navigate(`/videoStreaming/${videoId}`);
      };

  return (
    <Container maxWidth="xl" sx={{ mt: "3%" }}>
      <div className="latest-video-header">
        <h1 className="latest-video-title">Latest Videos</h1>
        <button className="view-all-button" onClick={handleViewAll}>
          View All
        </button>
      </div>
      <Grid container spacing={2}>
        {rows.length > 0
          ? rows.slice(0, 3).map((item, index) => (
              <div className="video-listing" key={index}>
                <div className="custom-video-logo">
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={LocalImages.videoCoverImg}
                      style={{
                        width: "107px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                      alt="videoCoverImg"
                    />
                  </Box>
                </div>
                <div className="video-title">
                  <span className="video-heading" title={item?.new_title} onClick={() => handlevideoID(item.video_id)}>
                    {item?.new_title}
                  </span>
                  <span className="video-heading-view" title={item?.views}>
                    {item?.views} views
                  </span>
                </div>
                <div className="video-edit">
                  <Box
                    className="video-edit-icon"
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      editVideo(item); // Pass the item to the function
                    }}
                  >
                    <EditIcon style={{ color: '#fbac18',}} />
                  </Box>
                </div>
              </div>
            ))
          : null}
      </Grid>
    </Container>
  );
};

export default LatestVideoAccount;