import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { LocalImages } from "../../Utils/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SliderDots } from "./SliderDots";
import axios from "axios";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { useNavigate } from "react-router-dom";

// const eventsData = [
//   {
//     day: 'Monday',
//     date: '2023-06-05T00:00:00Z',
//     month: 'June',
//     startTime: '10:00 AM'
//   },
//   {
//     day: 'Tuesday',
//     date: '2023-06-06T00:00:00Z',
//     month: 'June',
//     startTime: '11:00 AM'
//   },
//   {
//     day: 'Wednesday',
//     date: '2023-06-07T00:00:00Z',
//     month: 'June',
//     startTime: '12:00 PM'
//   },
//   {
//     day: 'Thursday',
//     date: '2023-06-08T00:00:00Z',
//     month: 'June',
//     startTime: '1:00 PM'
//   },
//   {
//     day: 'Friday',
//     date: '2023-06-09T00:00:00Z',
//     month: 'June',
//     startTime: '2:00 PM'
//   },
//   {
//     day: 'Saturday',
//     date: '2023-06-10T00:00:00Z',
//     month: 'June',
//     startTime: '3:00 PM'
//   },
//   {
//     day: 'Sunday',
//     date: '2023-06-11T00:00:00Z',
//     month: 'June',
//     startTime: '4:00 PM'
//   },
//   {
//     day: 'Monday',
//     date: '2023-06-12T00:00:00Z',
//     month: 'June',
//     startTime: '5:00 PM'
//   },
//   {
//     day: 'Tuesday',
//     date: '2023-06-13T00:00:00Z',
//     month: 'June',
//     startTime: '6:00 PM'
//   }
// ];


const UpcomingStreaming = ({ sideTitle1, sideTitle2, sideDesc }) => {

  const [showSwiper, setShowSwiper] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const [eventsData, setEventsData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate()

  const swiperRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setShowSwiper(true);
    }, 100);
  }, []);

  const onBackClick = () => {
    const currentActiveIndex = swiperRef?.current?.swiper?.activeIndex;
    if (currentActiveIndex >= 1) {
      swiperRef?.current.swiper.slideTo(currentActiveIndex - 1);
    } else {
      swiperRef?.current.swiper.slideTo(0);
    }
  };

  const onForwardClick = () => {
    const currentActiveIndex = swiperRef?.current?.swiper?.activeIndex;
    swiperRef?.current.swiper.slideTo(currentActiveIndex + 1);
  };


  const fetchData = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}events`);
      setEventsData(result.data.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div style={{ color: "#fff" }}>

    <Grid container spacing={2} maxWidth="lg"
    justifyContent="space-between"
    alignItems="center">

      <Grid item
        xs={12}
        md={6}
        xl={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#000",
          // justifyContent: "center",
          padding: "15% 0",
          justifyContent: "center",
        }}
      >
        <Box
        // sx={{ p: 0, pt: 5, m: 0, }}
        sx={{ p: 0, pt: 30, m: 0, }}
        >
          <Typography
            component={"h3"}
            variant="h3"
            className="font-gloucester color-white text-left"
          >
            {sideTitle1}
            <br />
            {sideTitle2}
          </Typography>
          <Typography
            component={"body"}
            variant="subtitle1"
            className="font-notoSans color-white text-left"
            // sx={{ pr: { md: 10, sx: 5 }, pb: 3, lineHeight: 1 }}
              sx={{ pr: { md: 10, sx: 5 }, pb: 3, lineHeight: 1 }}
          >
            {sideDesc}
          </Typography>
          <span onClick={()=> navigate("/ourShows")} className="btn-style01" style={{cursor: "pointer"}}>VIEW SCHEDULE</span>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} xl={6} justifyContent="center" alignItems="center">

        {!showSwiper || loading ? (
          <Box sx={{ position: "relative", top: { xs: -150, md: 30 } }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",

            }}
          >
            <CircularProgress size={"48px"} />
          </Box>

        ) : (
          <Grid item
            xs={12}
            className="scheduleCardContent"
            sx={{ position: "relative", top: { xs: -150, md: 30 } }}
          >

            <Box sx={{ position: "relative", width: "93%", height: "100%" }}>

              <Swiper
                direction={'vertical'}
                pagination={{
                  clickable: true,
                }}

                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 100,
                  depth: 600,
                  modifier: 1,
                  slideShadows: true,
                }}
                spaceBetween={10}
                slidesPerView={"auto"}
                modules={[EffectCoverflow, Navigation]}

                navigation
                // className="mySwiper"
                ref={swiperRef}
                onSlideChange={(swiper) => {
                  setActiveSlide(swiper.activeIndex);
                  console.log(swiper.activeIndex);
                }}
              >

                {eventsData?.slice(0, 3).map((event, index) => (
                  <SwiperSlide key={index}>

                    {({ isActive }) => (
                      <div
                        style={{
                          height: '240px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ScheduleCard event={event} isActive={isActive} />

                      </div>
                    )}

                  </SwiperSlide>
                ))}
              </Swiper>

            </Box>

            <SliderNav
              onBackClick={onBackClick}
              onForwardClick={onForwardClick}
              currentActiveIndex={activeSlide}
            />

          </Grid>
        )}
      </Grid>

    </Grid>

    
  </div>

  );
};

export default UpcomingStreaming;


const SliderNav = ({ onBackClick, onForwardClick, currentActiveIndex }) => {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 8px"
      }}
    >
      <button
        onClick={onBackClick}
        // fill="default"
        style={{ background: "none", border: 0, margin: "10% 30%" }}
      >
        <PlayArrowIcon
          className="play-arrow-icon"
          style={{
            fontSize: "30px",
            transform: "rotate(-90deg)",
            padding: 0,
            borderRadius: 5,
            margin: 0,
            color: "#000",
            background: "#fff",
          }}
        />

      </button>

      <div
        style={{
          padding: "15px 5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {new Array(3).fill(0).map((item, index) => (
          <SliderDots
            // color={currentActiveIndex === index ? "#000" : "rgba(0,0,0,0.4)"}
            color={currentActiveIndex === index ? "red" : "white"}
            uniqueKey={index}
          />
        ))}
      </div>

      {/* <Box onClick={onForwardClick} fill="default"> */}

      <button
        onClick={onForwardClick}
        style={{ background: "none", border: 0, margin: "10% 20%" }}
      >
        <PlayArrowIcon
          className="play-arrow-icon"
          style={{
            fontSize: "30px",
            transform: "rotate(90deg)",
            borderRadius: 5,
            padding: 0,
            margin: 0,
            color: "#000",
            background: "#fff",
          }}
        />

      </button>


    </div>
  );
};


const ScheduleCard = ({ event, isActive }) => {

  const navigate = useNavigate();


  // console.log(isActive,'----')

  return (
    <div
      style={{
        border: "none",
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
        padding: "16px",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: "#1A315C",
        boxShadow: isActive ? "12px 0px 25px 12px rgba(200,0,0,0.7)" : "",
      }}
    >
      <Box
        style={{
          flex: 1,
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component={"h5"}
          sx={{ fontSize: { sm: "40px", sx: "25px" } }}
        >
          {event.day}
        </Typography>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            fontWeight: "400",
            fontSize: { sm: "10vh", sx: "7vh" }
          }}
        >
          {new Date(event.date).getDate().toString()}

        </Typography>
        <Typography
          variant="h5"
          component={"h5"}
          sx={{ fontSize: { sm: "40px", sx: "25px" } }}
        >
          {event.month}
        </Typography>
      </Box>

      {/* live info */}
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
        }}
      >
        <img src={LocalImages.liveTv} alt="live_tv" style={{ width: "25px", marginBottom: "5px" }} />



        <Tooltip title={event.summary}>

          <h1
            style={{
              fontWeight: "400",
              padding: "0px 0px 0px 0px",
              margin: "0px 0px 0px 0px",
              fontSize: "2.4vh",
            }}
          >
            {event.summary.slice(0, 17)}
          </h1>

        </Tooltip>


        <h1
          style={{
            fontWeight: "400",
            padding: "0px",
            margin: "2% 0px 6% 0px",
            fontSize: "2.4vh",
          }}
        >
          {event.startTime}
        </h1>

        <span
          onClick={()=> navigate("/ourShows")}
          style={{
            border: "1.5px solid #fff",
            borderRadius: "4px",
            padding: "4px 16px",
            color: "#fff",
            fontSize: "10px",
            alignSelf: "self-start",
            cursor: "pointer"
          }}
        >
          WATCH NOW
        </span>
      </Box>

      <Box
        className="schedule-icons"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <img src={LocalImages.iwcIcon01} alt=" " style={{ width: "30px" }} />
        <MarkunreadIcon className="markunreadIcon" />
        <img src={LocalImages.iwcIcon02} alt=" " style={{ width: "30px" }} />
      </Box>

      <div
        style={{
          position: "absolute",
          left: 0,
          top: "25%",
          height: "50%",
          width: "1%",
          backgroundColor: isActive ? "red" : "rgba(255,255,255,0.6)",
        }}
      />
    </div>
  );
};

