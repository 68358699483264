import { Box, Container, Grid, Link, TextareaAutosize, Typography } from '@mui/material';
import React, { useState, useRef } from 'react'
import LatestShowsHeader from '../../Components/LatestShowsHeader';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { LocalImages } from '../../Utils/images';
import { SideSocialLinks } from '../../Components/SideSocialLinks';
import ReuseableButton from "../../ui/ReuseableButton"
import EmailContact from './EmailContact';
import Footer from '../../Components/Footer';
import MainHeader from '../../Components/MainHeader';
import FixedBottomNavigation from '../../Components/FixedBottomNavigation';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomPhoneIcon() {
  return (
    <div className="iconContainer">
      <PhoneIcon className="phoneIcon" />
    </div>
  );
}
function CustomEmailIcon() {
  return (
    <div className="iconContainer">
      <EmailIcon className="phoneIcon" />
    </div>
  )
}


const faqData = [
  { id: "panel1", question: "When does Michael Jaco go Live?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel2", question: "What are the different subscription plans available?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel3", question: "How do I cancel my subscription service?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel4", question: "What are the different subscription plans available?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel5", question: "Are there any discounts or promotions available for subscriptions?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel11", question: "When does Michael Jaco go Live?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel12", question: "What are the different subscription plans available?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel13", question: "How do I cancel my subscription service?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel14", question: "What are the different subscription plans available?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { id: "panel15", question: "Are there any discounts or promotions available for subscriptions?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." }
];

const GetInTouch = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <>
      <MainHeader />


      <Box>

        <Box className="top-layout-header-bg" sx={{ minHeight: "200px", pt: "250px" }}>
          <Box className="top-layout-header-bg-curve" sx={{ minHeight: "200px" }}>
          </Box>
        </Box>

        <Container sx={{ position: "relative", justifyContent: "center", minHeight: "700px" }}>

          <Container sx={{ position: { xs: "static", md: "absolute" }, justifyContent: "center", top: "-250px" }}>

            <Grid container>

                <EmailContact />


              <Grid xs={12} md={4}>
                <Box className="border-box-style01 no-left-border-box-style01" 
                // sx={{ padding: "10px 10px 10px 0" }}
                >
                  <Box sx={{ backgroundColor: "#18315C", padding: "50px 5px 100px 0px" }}>
                    <Box sx={{ pt: 2 }}>
                      <img src={LocalImages.ourInfo01} alt="" /> <img src={LocalImages.ourInfo02} alt="" />
                    </Box>
                    <Box sx={{ background: "#fff" }}>
                      <Grid container>
                        
                        <Grid xs={5} sx={{ textAlign: "center" }}>
                          <img src={LocalImages.ourInfo03} alt="" />
                        </Grid>


                        <Grid item xs={12}>
                          <Box display="flex" flexDirection="column" sx={{ pt: 2, pb: 5, px: 2 }}>
                            <Typography component="h4" variant="h4" className="font-gloucester">
                              Our Information
                            </Typography>
                            <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 1 }}>
                              <CustomEmailIcon />
                              <Typography sx={{ pl: 1 }}>
                                <Link href="mailto:info@michaelkjaco.com" sx={{ textDecoration: "none", color: "#400070" }}>
                                  Info@michaelkjaco.com
                                </Link>
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <CustomPhoneIcon />
                              <Typography sx={{ textDecoration: "none", color: "#400070", pl: 1 }}>
                                1(800) 222-3222
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>



                      </Grid>
                    </Box>
                    <Box>
                      <Box className="side-social-link-container-getInTouch">
                        <SideSocialLinks />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>



        <Box sx={{ py: 5, px: 3 }}>
          <LatestShowsHeader
            title="Need More Help"
            desc={"Review our Frequently Asked Questions for Additional Help"}
          />
          <Container>
            <Grid container spacing={2}>
              {faqData.map((faq, index) => (
                <Grid item xs={12} sm={6} key={faq.id}>
                  <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Box className="faq-content" sx={{ p: 0, m: 0, mr: { sm: index % 2 === 0 ? 2 : 0 } }}>
                      <Accordion
                        expanded={expanded === faq.id}
                        onChange={handleChange(faq.id)}
                        className="faq-content-accordion"
                      >
                        <AccordionSummary
                          aria-controls={`${faq.id}-content`}
                          id={`${faq.id}-header`}
                          expandIcon={
                            expanded !== faq.id ? (
                              <img src={LocalImages.faqaddIcon} alt="" width="30" height="30" />
                            ) : (
                              <img src={LocalImages.faqremoveIcon} alt="" width="30" height="30" />
                            )
                          }
                        >
                          <Typography>{faq.question}</Typography>


                        </AccordionSummary>


                        <AccordionDetails sx={{ mb: 2 }}>
                          <Typography>
                            {faq.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

      </Box>

      <FixedBottomNavigation />
              <Footer />

      <SideSocialLinks />
    </>
  )
}

export default GetInTouch;
