import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import { LocalImages } from "../Utils/images";
import { AuthContext } from "../Context/AuthContext";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LoginIcon from "@mui/icons-material/Login";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMediaQuery } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  //   marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  border: "1px solid #ffa900",
  borderRadius: "22px",
  padding: 0,
  margin: 0,
  height: "44px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const pages = [
  { pageName: "Live Schedule", pageUrl: "/ourShows" },
  { pageName: "IWC MERCH", pageUrl: "/ourShows" },

  // { pageName: "Video Library", pageUrl: "/videoGallery" },

  {
    pageName: "IWC Gear",
    pageUrl: "https://michaelkjaco.com/shop",
    pageTarget: "_blank",
  },
  {
    pageName: "IWC Social",
    pageUrl: "https://michaelkjacosocial.com",
    pageTarget: "_blank",
  },
  // { pageName: "About", pageUrl: "/" },

  { pageName: "Events", pageUrl: "/" },

  { pageName: "Contact", pageUrl: "/getInTouch" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function NotLoginHeader({ isLiveStream }) {
  const menuItems = [
    { text: "Home", icon: <HomeIcon />, path: "/" },
    {
      text: "Video Library",
      icon: <CollectionsOutlinedIcon />,
      path: "/videoGallery",
    },
    { text: "Latest Videos", icon: <VideocamIcon />, path: "/latestVideos" },
    {
      text: "IWC Only Videos",
      icon: <PermMediaOutlinedIcon />,
      path: null,
      onClick: () => {},
    },
    {
      text: "IWC Social",
      icon: <ViewComfyOutlinedIcon />,
      path: "https://michaelkjacosocial.com/choice/",
      pageTarget: "_blank",
    },
    {
      text: "Recently Watched",
      icon: <HistoryOutlinedIcon />,
      path: "/recentlyWatched",
    },
    {
      text: "Saved Videos",
      icon: <CollectionsBookmarkOutlinedIcon />,
      path: "/savedVideos",
    },
    {
      text: "Live Stream",
      icon: <LiveTvOutlinedIcon />,
      path: "/admin/livestream",
      disabled: !isLiveStream,
      style: { color: isLiveStream ? "red" : "inherit" },
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [isAboutClick, setIsAboutClick] = useState(false);

  const {
    liveStreamStatus,
    toggleLiveStreamStatus,
    searchQuery,
    setSearchQuery,
    isLoggedIn,
    toggleLogin,
  } = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl01, setAnchorEl01] = useState(null);
  const [anchorEl02, setAnchorEl02] = useState(null);
  const [anchorEl03, setAnchorEl03] = useState(null);
  const [bell, setBell] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const isMenuOpen = Boolean(anchorEl02);

  const open = Boolean(anchorEl01);

  const handleClick = (event) => {
    setAnchorEl01(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl01(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl02(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenu2Close = () => {
    setAnchorEl02(null);
  };

  const menuId = "primary-search-account-menu";
  const handleAboutClick = (e) => {
    e.preventDefault();
    setIsAboutClick(true);
    navigate("/", { state: { isAboutClick: true } });
  };

  useEffect(() => {
    if (location.state && location.state.isAboutClick) {
      const element = document.getElementById("aboutSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const isLargeScreen = useMediaQuery("(min-width:1280px)");


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="header-content" position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: { xs: "none", md: "none", lg: "flex" },
              alignItems: "center",
              width: "45vw",
            }}
          >
            {pages.map((page, index) => (
              <Link
                to={page.pageUrl}
                target={page?.pageTarget}
                onClick={page.pageName === "About" ? handleAboutClick : null}
                className="MontserratFont"
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    mx: 0,
                    color: "white",
                    display: "block",
                    fontSize: "11px",
                  }}
                >
                  {page.pageName}
                </Button>
              </Link>
            ))}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: isLargeScreen ? "center" : "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              style={{
                width: "150px",
                position: "absolute",
                top: 0,
                zIndex: 99999,
                borderRadius: "50%",
                backgroundColor: "rgba(210, 210, 210, 0.9)",
                boxShadow: `0 0 15px 8px rgba(210, 210, 210, 0.9)`,
              }}
              src={LocalImages.iwclogo}
              alt="Logo"
            />
          </Box>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl01}
            open={open}
            onClose={handleClose}
            className="video-gallery-dropdown"
          >
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.path && !item.path.startsWith("http") ? (
                  <MenuItem
                    component={Link}
                    to={item.path}
                    onClick={handleClose}
                    disabled={item.disabled}
                    style={item.style}
                  >
                    {item.icon}
                    {item.text}
                  </MenuItem>
                ) : item.path && item.path.startsWith("http") ? (
                  <MenuItem
                    component="a"
                    href={item.path}
                    target={item?.pageTarget}
                    onClick={handleClose}
                    disabled={item.disabled}
                    style={item.style}
                  >
                    {item.icon}
                    {item.text}
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                      }
                      handleClose();
                    }}
                    disabled={item.disabled}
                    style={item.style}
                  >
                    {item.icon}
                    {item.text}
                  </MenuItem>
                )}
                {index === 2 || index === 6 ? (
                  <Divider sx={{ my: 0.5 }} />
                ) : null}
              </React.Fragment>
            ))}
          </StyledMenu>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "45vw",
              position: "relative",
            }}
          >
            <Stack
              spacing={1}
              direction="row"
              sx={{
                position: "absolute",
                top: "-23px",
                right: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <SearchIcon
                  className="video-gallery-hover"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (searchQuery !== "") {
                      navigate("/searchPage", {
                        replace: true,
                        state: { searchQuery },
                      });
                    }
                  }}
                />

                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IconButton
                    size="large"
                    aria-label="login"
                    color="inherit"
                    sx={{ px: 0.5, py: 0.5, m: 0, cursor: "pointer" }}
                  >
                    <PersonIcon className="video-gallery-hover" />
                  </IconButton>
                </Link>

                <Link
                  to="/register/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      background: "#FFA903",
                      color: "#fff",
                    }}
                    className="header-signup"
                  >
                    <span>Sign Up Now</span>
                  </Button>
                </Link>
                
              </Box>

              <IconButton
                size="large"
                edge="end"
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{
                  display: { xs: "flex", md: "flex", lg: "none" },
                  m: 0,
                  px: 0.5,
                  py: 0.5,
                }}
              >
                <MenuIcon
                  className="video-gallery-hover"
                  onClick={handleClick}
                />
              </IconButton>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default NotLoginHeader;
