import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Backdrop, CircularProgress } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { videoCountAdmin } from "../../Features/Services/Videos";
import LatestVideoAccount from "../Admin/latestVideoAccount";
import { customerFetch, customerCount } from "../../Features/Services/customer";
import { LocalImages } from "../../Utils/images";
import { profileUserData } from "../../Features/Services/profile";
import ClientAccountOverview from "./account-client";
import './account.css';

const AccountOverview = () => {
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [userID, setUserID] = useState('');
  const [totalVideos, setTotalVideos] = useState(null);
  const [totalCustomerCount, setTotalCustomerCount] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const storeuserID = localStorage.getItem('userInfo');
  //   const userId = JSON.parse(storeuserID);

  //   if (storeuserID) {
  //     setUserID(userId.id);
  //   }
  //   const fetchVideoCount = async () => {
  //     try {
  //       const data = await videoCount(userId);

  //       setTotalVideos(data[0].total_videos);
  //     } catch (error) {
  //       console.error('Failed to fetch video count:', error);
  //     }
  //   };

  //   fetchVideoCount(); // Invoke the function
  // }, []);

  useEffect(() => {
    const fetchVideoCountAdmin = async () => {
      try {
        const data = await videoCountAdmin(); 
        setTotalVideos(data.total_videos); 
      } catch (error) {
        console.error('Failed to fetch video count:', error); 
      }
    };

    fetchVideoCountAdmin(); 
  }, []);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const data = await customerFetch();
        setTotalCustomers(data);
      } catch (error) {
        console.error('Failed to fetch customers:', error);
      }
    };

    fetchCustomer();
  }, []);

  
  useEffect(() => {
    const fetchCustomerCount = async () => {
      try {
        const data = await customerCount(); 
        setTotalCustomerCount(data[0].total_customer); 
      } catch (error) {
        console.error('Failed to fetch video count:', error); 
      }
    };

    fetchCustomerCount(); 
  }, []);


  useEffect(() => {
    const successCallback = (data) => {
      setProfileData(data[0]);
      setLoading(false); 
    };

    const failureCallback = (error) => {
      console.error("Error fetching profile data:", error); 
      setLoading(false); 
    };

    const fetchProfileData = async () => {
      setLoading(true); 
      profileUserData(null, successCallback, failureCallback);
    };

    fetchProfileData();
  }, []);

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/admin/send-notification" },
    { label: "Videos", path: "/admin" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const rolemenuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Watch Later", path: "/watchlater" },
    { label: "My Collections", path: "/collection" },
    { label: "Profile Settings", path: "/profile" },
  ];
  return (
    <>

      <MainHeader />
      <Backdrop
        open={isLoading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
      <InnerHeader />
      <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
      <div className="page-url-wrapper">
          {profileData && (
            <ul className="ul-content">
              {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                <li key={item.label}>
                  <Link
                    to={item.path}
                    rel="noopener noreferrer"
                    className={`li-content ${location.pathname === item.path ? "active-link" : ""
                      }`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress size={"48px"} />
          </Backdrop>
        </div>
        {/* Show ClientAccountOverview only if Role is 5 */}
        {profileData &&
        (profileData.Role === 1 ||
          profileData.Role === 2 ||
          profileData.Role === 3 ||
          profileData.Role === 5 ||
          profileData.Role === 6 ||
          profileData.Role === null) ? (
            <ClientAccountOverview />
          ):(
          <div class="total-revenue-wrapper">
            <div className="video-total-wrapper">
              <div className="video-total">
                <div className="total-number-wrapper">
                  <span className="total-number">{totalVideos}</span>
                </div>
                <div className="total-number-text-wrapper">
                  <span className="total-number-text">Total Videos</span>
                </div>
              </div>
              <div className="video-total">
                <div className="total-number-wrapper">
                  <span className="total-number">{totalCustomerCount}</span>
                </div>
                <div className="total-number-text-wrapper">
                  <span className="total-number-text">Subscribers</span>
                </div>
              </div>
              <div className="video-total">
                <div className="total-number-wrapper">
                  <span className="total-number">0</span>
                </div>
                <div className="total-number-text-wrapper">
                  <span className="total-number-text">Monthly Revenue</span>
                </div>
              </div>
              <div className="video-total">
                <div className="total-number-wrapper">
                  <span className="total-number">0</span>
                </div>
                <div className="total-number-text-wrapper">
                  <span className="total-number-text">Total Revenue</span>
                </div>
              </div>
            </div>
            <div className="latest-video-wrapper">
              <div className="latest-video-main">
                <LatestVideoAccount />
              </div>
              <div className="customrt-main-list">
                <div className="customer-main-list-wrapper">
                  <h1 className="latest-video-title">Newest Members</h1>
                  <button className="view-all-button" >
                    View All
                  </button>
                </div>
                <div className="fetch-customer-list">
                  {totalCustomers && totalCustomers.length > 0 ? (
                    totalCustomers.slice(0, 9).map((customer) => (
                      <div
                        key={customer.CustID}
                        className="customer-list-item"
                      >
                        <Box sx={{ position: "relative", marginRight: "10px" }}>
                          <img
                            src={
                              customer.profile_image_url
                                ? `data:image/png;base64,${customer.profile_image_url}`
                                : LocalImages.customerDummyImg
                            }
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt={customer.UserName || "Customer Image"}
                          />
                        </Box>


                        <div>
                          <span className="customer-name"
                          >
                            {customer.UserName}
                          </span>

                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No customers found.</p>
                  )}
                </div>
              </div>
            </div>

          </div>
        )}
      </Container>

      <FixedBottomNavigation />

      <Footer />
    </>
  );
};

export default AccountOverview;
