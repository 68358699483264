import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
  IconButton,
  Menu
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import { wildSearchVideos, videoDeleteById } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const SuperadminVideo = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [videoDetails, setVideoDetails] = useState([]);
  const [message, setMessage] = useState("");

  const {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  } = useVideoCategories();

  const {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  } = useVideoCollections();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFieldInitial = {
    collectionName: "",
    categoryName: "",
    newTitle: "",
  };
  const [searchField, setSearchField] = useState(searchFieldInitial);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    searchVideo();
  }, []);

  const searchVideo = () => {
    setSearching(true);
    wildSearchVideos(searchField)
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setRows([]);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const resetSearch = () => {
    setSearchField(searchFieldInitial);
    setRows([]);
  };

    const editVideo = (item) => {
      navigate("/editVideo", {
        state: { video_id: item.video_id },
      });
    };
  
    const exportVideo = async (item) => {
      if (!item.object_url) {
        console.error("Video URL not available");
        return;
      }
  
      try {
        // Fetch the video data as a Blob
        const response = await fetch(item.object_url);
        if (!response.ok) throw new Error("Failed to fetch video");
  
        const blob = await response.blob();
  
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = item.new_title || "downloaded_video";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error exporting video:", error);
      }
    };
  
    const removeVideo = async (video_id) => {
      console.log("Attempting to delete video with ID:", video_id);
      if (!video_id) return;
  
      try {
        setIsLoading(true);
        const response = await videoDeleteById(video_id);
        setVideoDetails((prevVideos) =>
          prevVideos.filter((item) => item.video_id !== video_id)
        );
        setMessage("Video successfully deleted");
        console.log(`Video with ID: ${video_id} successfully deleted.`);
  
      } catch (error) {
        console.error("Error deleting video:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const ActionMenu = ({ item }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
      
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        return (
          <>
            <IconButton onClick={handleClick}>
              <EditIcon style={{ color: "#fbac18" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  editVideo(item); // Using item here
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  exportVideo(item); // Using item here
                }}
              >
                Export Video
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (!isLoading) removeVideo(item.video_id); // Using item here
                }}
                style={{
                  color: isLoading ? "gray" : "red",
                  pointerEvents: isLoading ? "none" : "auto",
                }}
              >
                Remove
              </MenuItem>
            </Menu>
          </>
        );
      };
      
      const handlevideoID = (videoId) => {
        console.log("videoId", videoId);
        
        navigate(`/videoStreaming/${videoId}`);
      };
  return (
<>
    
    <Container disableGutters maxWidth="lg" sx={{p: 1, mt: "5%"}}>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>

          <TableHead sx={{backgroundColor: "#19315c"}}>
            <TableRow>
              <TableCell sx={{color:"white"}}>S No</TableCell>
              <TableCell sx={{color:"white"}}>Title</TableCell>
              <TableCell sx={{color:"white"}}>Category</TableCell>
              <TableCell sx={{color:"white"}}>Collection</TableCell>
              <TableCell sx={{color:"white"}}>Views</TableCell>
              <TableCell sx={{color:"white"}}>Actions</TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {rows.length > 0
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.video_id}>
                      <TableCell>{index + 1}.</TableCell>
                      <TableCell onClick={() => handlevideoID(row.video_id)} className="video-text-title">{row?.new_title}</TableCell>
                      <TableCell>{row.category_name}</TableCell>
                      <TableCell>
                      {row.collection_name}
                      <br />
                      {new Date(row.release_date).getMonth() + 1}-
                      {new Date(row.release_date).getDate()}-
                      {new Date(row.release_date).getFullYear()}
                    </TableCell>
                      <TableCell>{row.views}</TableCell>
                      <TableCell>
                      <ActionMenu item={row} />
                    </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
        {rows.length === 0 && !isSearching ? (
          <Typography
            textAlign={"center"}
            sx={{
              padding: "16px",
              display: "block",
              width: "100%",
            }}
          >
            No Records Found
          </Typography>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        open={isSearching}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
    </Container>
    </>
  );
};

export default SuperadminVideo;
