import React, { useState } from "react";
import bcrypt from "bcryptjs";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { registerUser } from "../../Features/Services/resetPassword";
import "./Register.css";
import CustomInput from "../../Components/Form/CustomInput";
import { isValidString } from "../../Utils/validators";
import { LocalImages } from "../../Utils/images";

// Initial values for all form fields
const initialValues = {
  FirstName: "",
  LastName: "",
  username: "",
  email: "",
  password: "",
  confirmpassword: "",
};

// Validation schema using Yup
const signupSchema = Yup.object().shape({
  FirstName: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be less than 50 characters")
    .required("First name is required"),
    LastName: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be less than 50 characters")
    .required("Last name is required"),
  username: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be less than 50 characters")
    .required("First name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setLoading(false);
  };

  const handleOpen = () => {
    setLoading(true);
  };

  // Formik hook for form management
  const registerForm = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: async (values) => {
      const combinedName = `${values.username} ${values.lname}`; // Combine username and lname into name
      const salt = await bcrypt.genSalt(10); // Generate a salt
      const hashedPassword = await bcrypt.hash(values.password, salt); // Hash the password

      const payload = {
        FirstName: values.FirstName, 
        LastName: values.LastName,
        username: values.username,
        email: values.email,
        password: values.password, // Use the hashed password
      };


      registerUser(
        payload, // Send the combined name and hashed password in the payload
        (response) => {
          console.log("response", response);
          setMessage("User Registered Successfully!");
          handleClose();
          navigate("/");
        },
        (error) => {
          // alert(error);
          setMessage("User is already Registered!");
          handleClose();
        }
      );
    },
    validateOnMount: true,
    enableReinitialize: true,
  });


  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };


  const formContent = () => (
    <form onSubmit={registerForm.handleSubmit} style={{ minWidth: "100%" }}>
        <div className="register-field">
        <CustomInput
          value={registerForm.values.FirstName}
          onChangeText={(value) => registerForm.setFieldValue("FirstName", value)}
          handleBlur={registerForm.handleBlur("FirstName")}
          isError={
            isValidString(registerForm.errors.FirstName) && registerForm.touched.FirstName
          }
          errorMsg={registerForm.errors.FirstName}
          isRequired={true}
          label={"First Name"}
          type="text"
          placeholder={"First Name"}
          prefixIcon={
            <img
              src={LocalImages.user}
              style={{ width: "50%", height: "auto" }}
            />
          }
        />

        <CustomInput
          value={registerForm.values.LastName}
          onChangeText={(value) => registerForm.setFieldValue("LastName", value)}
          handleBlur={registerForm.handleBlur("LastName")}
          isError={
            isValidString(registerForm.errors.LastName) && registerForm.touched.LastName
          }
          errorMsg={registerForm.errors.LastName}
          isRequired={true}
          label={"Last Name"}
          type="text"
          placeholder={"Last Name"}
          prefixIcon={
            <img
              src={LocalImages.user}
              style={{ width: "50%", height: "auto" }}
            />
          }
        />
        </div>
        <CustomInput
          value={registerForm.values.username}
          onChangeText={(value) => registerForm.setFieldValue("username", value)}
          handleBlur={registerForm.handleBlur("username")}
          isError={
            isValidString(registerForm.errors.username) && registerForm.touched.username
          }
          errorMsg={registerForm.errors.username}
          isRequired={true}
          label={"User Name"}
          type="text"
          placeholder={"User Name"}
          prefixIcon={
            <img
              src={LocalImages.user}
              style={{ width: "60%", height: "auto" }}
            />
          }
        />

     

      <CustomInput
        value={registerForm.values.email}
        onChangeText={(value) => registerForm.setFieldValue("email", value)}
        handleBlur={registerForm.handleBlur("email")}
        isError={
          isValidString(registerForm.errors.email) && registerForm.touched.email
        }
        errorMsg={registerForm.errors.email}
        isRequired={true}
        label={"Email"}
        type="email"
        placeholder={"Email"}
        prefixIcon={
          <img
            src={LocalImages.user}
            style={{ width: "60%", height: "auto" }}
          />
        }
      />

      <CustomInput
        value={registerForm.values.password}
        onChangeText={(value) => registerForm.setFieldValue("password", value)}
        handleBlur={registerForm.handleBlur("password")}
        isError={
          isValidString(registerForm.errors.password) &&
          registerForm.touched.password
        }
        errorMsg={registerForm.errors.password}
        isRequired={true}
        label={"Password"}
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        prefixIcon={
          <img
            src={LocalImages.lock}
            alt="Lock Icon"
            style={{ width: "60%", height: "auto" }}
          />
        }
        suffixIcon={(toggleSuffixIcon) => (
          <img
            src={LocalImages.eyeClose}
            style={{ width: "50%", height: "auto", float: "right" }}
          />
        )}
      />

      <CustomInput
        value={registerForm.values.confirmpassword}
        onChangeText={(value) =>
          registerForm.setFieldValue("confirmpassword", value)
        }
        handleBlur={registerForm.handleBlur("confirmpassword")}
        isError={
          isValidString(registerForm.errors.confirmpassword) &&
          registerForm.touched.confirmpassword
        }
        errorMsg={registerForm.errors.confirmpassword}
        isRequired={true}
        label={"Confirm Password"}
        type={showConfirmPassword ? "text" : "password"}
        placeholder="Confirm Password"
        prefixIcon={
          <img
            src={LocalImages.lock}
            alt="Lock Icon"
            style={{ width: "60%", height: "auto" }}
          />
        }
        suffixIcon={(toggleSuffixIcon) => (
          <img
            src={LocalImages.eyeClose}
            style={{ width: "50%", height: "auto", float: "right" }}
          />
        )}
      />
      <button
        type="submit"
        className="login-btn-submit"
        disabled={!registerForm.isValid || registerForm.isSubmitting}
        style={{ cursor: "pointer" }}
      >
        {registerForm.isSubmitting ? <CircularProgress size={24} /> : "Submit"}
      </button>

      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "15%",
            height: "2px",
            borderTop: "1px solid #adb2bd",
          }}
        />
        <Typography
          variant="body1"
          component={"span"}
          style={{ margin: "0px 8px", color: "#adb2bd" }}
        >
          OR
        </Typography>
        <div
          style={{
            width: "15%",
            height: "2px",
            borderTop: "1px solid #adb2bd",
          }}
        />
      </Grid>

      <Link to="/login">
        <button
          type="button"
          className="login-btn-submit login-btn-create-ac"
          style={{ cursor: "pointer" }}
        >
          Sign In
        </button>
      </Link>
      {message && (
        <Typography color={message.includes("Thank you") ? "green" : "red"} style={{ marginTop: "1rem", textAlign: "center" }}>
          {message}
        </Typography>
      )}
    </form>
  );

  return (
    <Box maxWidth={true} style={{ height: "100vh" /*overflow: "hidden"*/ }}>
      <Card
        style={{
          padding: "4vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={LocalImages.iwclogo}
          className="header-logo"
          onClick={() => {
            navigate("/");
          }}
          style={{ width: "180px", position: "absolute", top: "-20px" }}
        />
      </Card>
      <Grid container
        style={{
          // height: "100%",
          background: `url(${LocalImages.bgURL}) 0 0/100% 100% no-repeat`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          // overflow: "scroll",
          alignItems: "center",
          justifyContent: "center",
        }}

      >
        <Grid item xs={10} sm={6} md={10} lg={4} xl={4} sx={{ mt: 15 }}>
          <Card className="login-card">
            <Typography
              variant="h3"
              component={"h3"}
              className="font-gloucester title-style01"
            >
              Sign Up to Your Account
            </Typography>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              {formContent()}
            </CardContent>
          </Card>
        </Grid>

        <Box
          style={{
            height: "100px",
            width: "100%",
            backgroundImage: `url( ${LocalImages.loginBottomBg} )`,
            backgroundSize: "cover",
            backgroundPosition: "cetner bottom",
            position: "relative",
            top: -80,
          }}
        >
          <p></p>
        </Box>
      </Grid>

      <Dialog
        open={isLoading}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Box>
  );
};

export default Register;
