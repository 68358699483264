import AWS from "aws-sdk";

import { LocalImages } from "./images";
import { SECRETS } from "./secrets";

AWS.config.update({
  region: SECRETS.AWS_REGION,
  accessKeyId: SECRETS.AWS_ACCESS_KEY_ID,
  secretAccessKey: SECRETS.AWS_SECRET_ACCESS_KEY,
});

const AWS_S3 = new AWS.S3();

const urlStartsWith = `s3://${SECRETS.AWS_S3_BUCKET_NAME}/`;

const generateVideoUrl = (s3_video_id) => {
  return new Promise((resolve, reject) => {
    const key = s3_video_id.startsWith(urlStartsWith)
      ? s3_video_id?.replace(urlStartsWith, "")
      : key;

    var signedUrlParams = {
      Bucket: SECRETS.AWS_S3_BUCKET_NAME,
      Key: key,
      Expires: 600,
    };

    AWS_S3.getSignedUrl("getObject", signedUrlParams, function (err, url) {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};

const randomImage = () => {
  const random = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
  const image = {
    1: LocalImages.slider1,
    2: LocalImages.discoverImg02,
    3: LocalImages.discoverImg05,
    4: LocalImages.discoverImg04,
  };
  return image[[random]];
};

function videoIDGenerate(str) {
  // Extract the numeric part from the string
  let numPart = str.match(/\d+$/)[0];

  // Convert the numeric part to a number, increment by 1, and pad with zeroes
  let incrementedNum = (+numPart + 1).toString().padStart(numPart.length, "0");

  // Replace the numeric part in the original string with the incremented value
  return str.replace(/\d+$/, incrementedNum);
}

export { randomImage, AWS_S3, generateVideoUrl, videoIDGenerate };
