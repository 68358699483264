import React, { useContext } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { useNavigate } from "react-router-dom";

import { isValidString } from "../../Utils/validators";
import { generateVideoUrl, randomImage } from "../../Utils/videoSupport";
import { AuthContext } from "../../Context/AuthContext";
import { LocalImages } from "../../Utils/images";

export const VideoCard = ({
  timeStamp,
  title,
  description,
  imageUrl,
  imgStyle = {},
  s3_video_id,
  video_id,
  release_date,
  videoContainer = {},
}) => {
  const navigate = useNavigate();

  const { isLoggedIn } = useContext(AuthContext);

  const playVideo = () => {
    
    if (isLoggedIn) {
      if (isValidString(s3_video_id)) {
        generateVideoUrl(s3_video_id)
          .then((url) => {
            navigate(`/videoStreaming/${video_id}`
              , {
              replace: true,
              state: { id: video_id, url, title, release_date, description },
            });
          })
          .catch((error) => {
            console.log("error => ", error);
          });
      } else {
        alert("Not a valid video");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      onClick={playVideo}
      style={{
        // width: "90%",
        // height: "300px",
        // position: "relative",
        // marginBottom: "12px",

        width: "100%",
        gap: "50px",
        height: 0,
        position: "relative",
        marginBottom: "12px",
        paddingBottom: '56.25%', /* 16:9 ratio (9/16 = 0.5625 or 56.25%) */
        overflow: 'hidden',
        borderRadius: 6,
        ...videoContainer,
      }}
    >
      {imageUrl === "" ? (
        // <div
        //   style={{
        //     backgroundColor: "#3E4952",
        //     width: "100%",
        //     height: "100%",
        //     borderRadius: "8px",
        //   }}
        // />

        <img
          src={LocalImages.coverImg}
          alt="Cover Preview"
          style={{ 
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            ...imgStyle,
           }}
        />

      ) : (

        <img
        src={LocalImages.coverImg}
          alt="Cover Preview"
          style={{ 
            // width: "100%", height: 320, ...imgStyle 
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            ...imgStyle,
          }}
        />

      )}
      <div
        style={{
          position: "absolute",
          backgroundColor:
            imageUrl !== "" ? "#3E4952" : "rgba(92, 79, 92, 0.8)",
          color: "#fff",
          padding: "0px 8px",
          borderRadius: "4px",
          alignSelf: "start",
          top: "10px",
          right: "10px",
        }}
      >
        <span style={{ fontSize: "16px" }}>{timeStamp}</span>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "8px",
          margin: "0px 16px 8px",
          display: "flex",
          alignItems: "center",
          width: "95%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ padding: "8px" }}>
          <h4
            style={{
              color: "#fff",
              fontSize: "12px",
              padding: "0px",
              margin: "0px",
            }}
          >
            {title}
          </h4>
          <span
            style={{
              fontSize: "12px",
              color: "#fff",
              maxLines: 1,
            }}
          >
            {description?.substring(0, 75)}
          </span>
        </div>

        <PlayCircleFilledIcon
          fontSize="large"
          sx={{ fontSize: "64px", color: "#fff" }}
        />
      </div>
    </div>
  );
};
