import axios from "axios";
import { Auth } from "../../Config/url";
import { SECRETS } from "../../Utils/secrets";

const BASE_URL = SECRETS.BASE_URL;
console.log("BASE_URL", BASE_URL);

/* Forgot Pass Link */
export const forgotPassLink = (email, successCB, failureCB) => {
  console.log("payload", email);

  const data = JSON.stringify({
    email: email,
  });

  
  const config = {
    method: 'post',
    url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/forgot-password',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

 
  axios.request(config)
    .then((response) => {
      console.log("Response data:", response.data);
   
      if (response.data?.error) {
        return failureCB(response.data.message);
      }
      return successCB(response.data);
    })
    .catch((error) => {
      console.error("Error during login:", error);
      const errorMessage = error.response?.data?.error || "An error occurred during login.";
      return failureCB(errorMessage);
    });
};

/* ResetPass */
export const resetPassword = (token, newPassword, successCB, failureCB) => {
  const data = JSON.stringify({ newPassword: newPassword });
  console.log("data being sent to API:", data);

  const config = {
      method: 'post',
      url: `https://michaelkjaco-streaming-node-app.onrender.com/api/reset-password/${token}`,
      headers: { 'Content-Type': 'application/json' },
      data: data,
  };

  axios.request(config)
      .then((response) => {
          
          if (response.data) {
              localStorage.setItem("authToken", response.data);  
              successCB(response.data);
          } else {
              failureCB(response.data.message);
          }
      })
      .catch((error) => {
          const errorMessage = error.response?.data?.error || "An error occurred during the password reset.";
          console.error("Error during password reset:", errorMessage);
          failureCB(errorMessage);
      });
};

/* Register User */
export const registerUser = (payload, successCB, failureCB) => {

  const data = JSON.stringify({
    FirstName: payload.FirstName,
    LastName: payload.LastName,
    username: payload.username,
    email: payload.email,
    password: payload.password
  });

  
  const config = {
    method: 'POST',
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/register`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

 
  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (successCB) successCB(response.data);
    })
    .catch((error) => {
      console.error("Registration error:", error);
      if (failureCB) failureCB(error);
    });
};

