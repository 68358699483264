import React, { useState, useEffect, useContext } from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { randomImage } from "../../Utils/videoSupport";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import { LocalImages } from "../../Utils/images";
import { Strings } from "../../Utils/strings";
import { AuthContext } from "../../Context/AuthContext";
import LatestShowsHeader from "../../Components/LatestShowsHeader";
import ReuseableButton from "../../ui/ReuseableButton"
import { SwiperVideoCard } from "../../Components/VideoCard/SwiperVideoCard";

const WatchOurLatestShows = () => {

  const {
    getLatestVideos,
    latestVideos,
    latestVideoFetching,
    latestVideoFetchError,
  } = useVideoHooks();

  const navigate = useNavigate();

  const { isLoggedIn } = useContext(AuthContext);

  const [showSwiper, setShowSwiper] = useState(false);

  useEffect(() => {

    getLatestVideos(1, 5);
    setTimeout(() => {
      setShowSwiper(true);

    }, 50);

  }, []);


  return (

    <Box display="flex" sx={{pt:2, height: { xs: "100%", sm: "70vh", md: "80vh", } }}>

        <Box
          sx={{
            width: { xs: "70%", md: "90%" },
            backgroundColor: "#fff",
            borderRadius: {
              xs: "450% / 100% 100% 0 0",
              md: "300% / 100% 100% 0 0",
            },
            position: "relative",
            marginTop: { xs: "-8%", md: "-10%" },
            padding: { xs: "3% 10%", md: "3%" },
            flex: 1,
            textAlign: "center",
          }}
        >
          <LatestShowsHeader
            title={Strings.watchOurLatestShows}
            desc={Strings.startSavingYourTimerWithFramerWireframeWebuiKit}
          />


{latestVideoFetching ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "90vh", md: "80vh" },
        width: "100%",
      }}
    >
      <CircularProgress size={"48px"} />
    </Box>
  )
  : 
  
  (
    latestVideos.list.length > 0 && (
      <Container>
        <Box
          className="swiper-01"
          sx={{
            mx: { xs: "-10%", md: 0, xl: "-10%" },
          }}
        >
          {showSwiper && (
            <Swiper
              navigation={true}
              modules={[Navigation, EffectCoverflow, Pagination]}
              initialSlide={1}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 100,
                depth: 600,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              spaceBetween={2}
              breakpoints={{
                800: {
                  slidesPerView: 2,
                },
                799: {
                  slidesPerView: 1,
                },
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              loop={true}
              sx={{
                overflow: "hidden",
                justifyContent: "center",
                padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
              }}
            >
              {latestVideos?.list?.map((item) => (
                <SwiperSlide
                  key={item?.id}
                  style={{ borderRadius: 10 }}

                >
                  <SwiperVideoCard
                  videoContainer={{height: "300px"}}
                    description={item?.synopsis}
                    imageUrl={item?.cover_image}
                    timeStamp={item?.duration}
                    title={item?.new_title}
                    imgStyle={{ width: "115%" }}
                    s3_video_id={item?.s3_video_id}
                    video_id={item?.video_id}
                    release_date={item?.release_date}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>

        <Box sx={{ justifyContent: "center", textAlign: "center", mt: 3 }}>
          {isLoggedIn ? (
            <ReuseableButton
              onClick={() => {
                navigate("/latestVideos");
              }}
              text="Watch More"
            />
          ) : (
            <ReuseableButton
              onClick={() => {
                navigate("/login");
              }}
              text="Login"
            />
          )}
        </Box>
      </Container>
    )
  )
}


        </Box>
      
    </Box>
  );
};

export default WatchOurLatestShows;