import axios from "axios";

const getUserIDFromLocalStorage = () => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
        const userInfo = JSON.parse(storedUserInfo);
        return userInfo.id; // Return user ID
    }
    return null;
};
const userID = getUserIDFromLocalStorage();

export const videolike = async (videolikePayload, successCB, failureCB) => {
  
    const data = JSON.stringify({
      video_id: videolikePayload.video_id,
      user_id: videolikePayload.user_id,
      like_status: videolikePayload.like_status,
      save_video: videolikePayload.save_video
  
    });
  
    const config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: `https://michaelkjaco-streaming-node-app.onrender.com/api/video-likes`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data,
    };
  
    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      if (successCB) successCB(response.data);
    } catch (error) {
      console.error("Error uploading video:", error);
      if (failureCB) failureCB(error);
    }
};


export const watchVideoFetch = async (data, successCB, failureCB) => {
  const userID = getUserIDFromLocalStorage();
  console.log("userID like", userID);

  let config = {
    method: 'GET',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/save-video-user/${userID}`,
    headers: {},
    data: data
  };

  axios
    .request(config)
    .then((response) => {
      console.log('API Response fetch:', response);
      if (response?.data) {
        successCB(response.data); 
      } else {
        console.warn('No data received in the response.');
        successCB([]); 
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      failureCB(error); 
    });
};