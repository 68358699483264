import { Box, Container, Grid } from '@mui/material'
import { LocalImages } from '../Utils/images'
import React from 'react'

const Footer = () => {
  return (
    <Box
    sx={{
      width: "100%",
      justifyContent: "center",
      minHeight: "250px",
      boxSizing: "borderBox",
      paddingTop: "150px",
      pb: "50px",
    }}
    className="faq-bg transform-style-bg"
  >
    <Container
    className="transform-style-content"
    >
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
        <img src={LocalImages.footerLogo} alt="Logo" />
      </Box>

      <Box sx={{ borderTop: "1px solid #fff", pt: 2 }}>
        <Grid container>
          <Grid xs={6} className="footer-links">
            <ul>
              <li>
                <a href="#">Contact</a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">Acceptable Use Policy</a>
              </li>
            </ul>
          </Grid>
          <Grid
            xs={6}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              color: "#fff",
            }}
          >
            Copyright © 2024 Michael Jaco. All Rights Reserved
          </Grid>
        </Grid>
      </Box>
    </Container>       


  </Box>
  )
}

export default Footer