import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CircularProgress,
  Backdrop,
  Typography,
  CardMedia,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { LocalImages } from "../../Utils/images";
import { formatDistanceToNow, parseISO } from "date-fns";
import { videoDeleteById } from "../../Features/Services/Videos";

const CollectionView = ({ collection }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [message, setMessage] = useState("");
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    document.body.classList.add('collection-view-body');
    return () => {
      document.body.classList.remove('collection-view-body');
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video-list/${collection.id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setVideos(response.data.videos);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [collection]);

  const handlePopupOpen = (event, video) => {
    setPopupAnchor(event.currentTarget);
    setSelectedVideo(video);
  };

  const handlePopupClose = () => {
    setPopupAnchor(null);
  };

  const handleDownload = (video) => {
    if (!video || !video.object_url) return;
    const link = document.createElement("a");
    link.href = video.object_url;
    link.download = video.new_title || "video.mp4";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleOptionSelect = (option) => {
    switch (option) {
      case "watchLater":
        console.log("Save to Watch Later: ", selectedVideo);
        break;
      case "saveToCollection":
        console.log("Save to Collection: ", selectedVideo);
        break;
      case "remove":
        if (!isLoading && selectedVideo) {
          removeVideo(selectedVideo.video_id);
        }
        break;
      case "download":
        handleDownload(selectedVideo);
        break;
      case "share":
        handleShare(selectedVideo.video_id);
        break;
      default:
        console.log("Unknown option selected");
    }
    handlePopupClose();
  };

  const removeVideo = (videoId) => {
    setIsLoading(true);

    axios
      .delete(`https://michaelkjaco-streaming-node-app.onrender.com/api/collection/${collection.id}/video/${videoId}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {

        setVideos((prevVideos) => prevVideos.filter((video) => video.video_id !== videoId));
        setIsLoading(false);
        setMessage("Video removed successfully.");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setMessage("Error occurred while removing the video.");
      });
  };

  const handlevideoID = (videoId) => {
    navigate(`/videoStreaming/${videoId}`);
  };

  const videoURL = process.env.REACT_APP_BASE_URL;
  const handleShare = (videoId) => {
    if (!videoId) return;

    const videoUrl = `${videoURL}/videoStreaming/${videoId}`;

    navigator.clipboard.writeText(videoUrl).then(() => {
      alert("Video link copied to clipboard!");
    }).catch((error) => {
      console.error("Failed to copy link: ", error);
    });


  };


  return (
    <Container disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
      <div className="collection-view-main-title">{collection.title}</div>
      <div className="collection-view-main-wrapper">
        {/* Collection Image */}
        <div className="collection-image-view">
          <CardMedia
            component="img"
            src={
              collection.image
                ? `data:image/png;base64,${collection.image}`
                : LocalImages.iwcCollection
            }
            alt={collection.title}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>

        {/* Collection Videos */}
        <div className="collection-video-view">
        {videos.length > 0 ? (
            videos.map((video) => {
              const releaseDate = parseISO(video.release_date); 
              const isToday = new Date().toDateString() === releaseDate.toDateString(); 
              return (
                <div className="video-listing" key={video.video_id}>
                  <div className="video-cover-image">
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={LocalImages.videoCoverImg}
                        style={{
                          width: "170px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        alt="videoCoverImg"
                      />
                    </Box>
                  </div>
                  <div className="video-heading-wrapper">
                    <div
                      className="video-heading"
                      onClick={() => handlevideoID(video.video_id)}
                    >
                      {video.new_title}
                    </div>
                    <div className="video-speaker-name">
                    {video.speaker_name} •{" "}
                      {video.release_date
                        ? isToday
                          ? "Today"
                          : formatDistanceToNow(releaseDate, { addSuffix: true }).replace("over ", "")
                        : "Unknown"}
                      </div>
                  </div>
                  <div className="group-icon">
                    <Box sx={{ position: "relative", marginRight: "10px" }}>
                      <img
                        src={LocalImages.groupIcon}
                        style={{
                          width: "auto",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        alt="Upload Icon"
                        onClick={(event) => handlePopupOpen(event, video)}
                      />
                    </Box>
                  </div>
                </div>
              );
            })
          ) : (
            <Typography variant="h6" align="center" sx={{ width: "100%" }}>
              No Videos available for this collection
            </Typography>
          )}
        </div>
      </div>

      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>

      {/* Popup Menu */}
      <Menu
        anchorEl={popupAnchor}
        open={Boolean(popupAnchor)}
        onClose={handlePopupClose}
      >
        <MenuItem onClick={() => handleOptionSelect("watchLater")}>
          Save to Watch Later
        </MenuItem>
        <MenuItem onClick={() => handleOptionSelect("saveToCollection")}>
          Save to Collection
        </MenuItem>
        <MenuItem
          onClick={() => handleOptionSelect("remove")}
          style={{
            color: isLoading ? "gray" : "red",
            pointerEvents: isLoading ? "none" : "auto",
          }}
        >
          Remove from IWC Exclusives
        </MenuItem>
        <MenuItem onClick={() => handleOptionSelect("download")}>
          Download
        </MenuItem>
        <MenuItem onClick={() => handleOptionSelect("share")}>
          Share
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default CollectionView;
