//Homepage for IWC-TV - Intuitive Warrior Club
// This file defines the main Dashboard component for the IWC-TV platform.
// It contains the main content for the dashboard, including the header, subscription plans, and other sections.
// It also includes the About, PartnerProducts, FAQ, DiscoverWorld, and WatchOurLatestShows components.
// --------------------------------------------------------

// Importing required libraries and components
import React, { useState, useEffect } from "react";
import { 
  Box, Button, Container, Divider, Grid, List, ListItem, 
  ListItemIcon, ListItemText, Typography, useMediaQuery 
} from "@mui/material";

import { About } from "./About"; // Custom component for About section
import Header from "../../Components/LoginHeader"; // Header for logged-in users
import PartnerProducts from "../../Components/PartnerProducts"; // Component showcasing partner products
import FAQ from "../../Components/faq"; // Frequently Asked Questions component
import { LocalImages } from "../../Utils/strings";
import { Strings } from "../../Utils/strings"; // Utility file for text strings

import { Swiper, SwiperSlide } from "swiper/react"; // Swiper for carousel/slider

// Importing Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";

import CheckIcon from '@mui/icons-material/Check'; // Material UI check icon

// Swiper modules for effects and navigation
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { SubscriptionCard } from "../../Components/SubscriptionCard"; // Component for subscription card display
import { SideSocialLinks } from "../../Components/SideSocialLinks"; // Social media links component
import DiscoverWorld from "./DiscoverWorld"; // Component for exploring content
import { Parallax } from "react-parallax"; // Parallax scrolling effect library
import WatchOurLatestShows from "./WatchOurLatestShows"; // Component to display latest shows
import { useLocation } from 'react-router-dom'; // Hook for accessing the current route location

// Importing media assets
import michaelBg from "../../Assets/video/michael-bg.mp4"; // Background video
import iwcLogo from "../../Assets/Images/iwe-logo.png"; // IWC logo image
import { Stack } from "@mui/system"; // Material UI Stack layout
import ReusableButton from "../../ui/ReuseableButton"; // Reusable button component
import { animateScroll as scroll } from 'react-scroll'; // Library for smooth scrolling
import Footer from "../../Components/Footer"; // Footer component
import NotLoginHeader from "../../Components/NotLoginHeader"; // Header for not logged-in users
import MainHeader from "../../Components/MainHeader"; // Main header component
import FixedBottomNavigation from "../../Components/FixedBottomNavigation"; // Bottom navigation component

// Data for subscription plans
const subscriptionData = [
  "Access to IWC Social Platform",
  "IWC Private Livestreams",
  "IWC Member-Exclusive Videos",
  "Save 5% off Michael Jaco`s Master-Level Courses",
  "Save 5% off Michael Jac`s Gear",
];

const subscriptionData1 = [
  "Access to IWC Social Platform",
  "IWC Private Livestreams",
  "IWC Member-Exclusive Videos",
  "Save 10% off Michael Jaco`s Master-Level Courses",
  "Save 10% off Michael Jac`s Gear",
];

function Dashboard() {
  const location = useLocation();

  const [showSwiper, setShowSwiper] = useState(false);



  useEffect(() => {
    setTimeout(() => {
      setShowSwiper(true);
    }, 50);
  }, []);

  return (
    <>

      <MainHeader />

      <Box sx={{ width: "100%" }} className="header-bg">
        
        <div className="parallax-container">
          <Parallax className="bgImage" strength={-100}>

            <div style={{ height: "90vh" }}>
              <div className="rectangle left" />
              <div className="rectangle right" />

              <Box className="text" sx={{fontSize: {sm: '50px', xs: "40px"}}}>
                <Box className="font-gloucester">
                  Embrace the Path of the <span>Intuitive Warrior</span>
                  <br />
                  Welcome to the IWC Family!
                </Box>
              </Box>

              <Parallax speed={-300}>
                <div className="top-mountain" />
              </Parallax>
            </div>

          </Parallax>
        </div>
      </Box>
      <WatchOurLatestShows />



      <Box className="about-content-top">
        <About />
      </Box>

      

      {/* <Container sx={{ mt: -20, mb: "4%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pb: 5,
          }}
        >
          <Typography
            component={"h4"}
            variant="h4"
            fontSize={"48px"}
            className="font-gloucester"
            align="center"
          >
            {Strings.membershipAndPlansForAll}
          </Typography>
          <Typography
            component={"body"}
            variant="subtitle1"
            className="font-notoSans color-gray"
          >
            {Strings.diversePricingForEveryone}
          </Typography>
          <img src={LocalImages.leaveWings} style={{ width: "120px" }} />
        </Box>

        <Container>
          <Box
            className="swiper-02"
            sx={{
              //  boxSizing: "border-box"
              ml: { xs: "-10%", md: 0, xl: "-10%" },
              mr: { xs: "-5%", md: 0, xl: "-10%" },
            }}
          >
            {showSwiper && (
              <Swiper
                navigation={true}
                modules={[Navigation, EffectCoverflow, Pagination]}
                initialSlide={1}
                className="mySwiper memberSwiper"
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 100,
                  depth: 600,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                spaceBetween={2}
                // slidesPerView={1}
                breakpoints={{
                  // when window width is >= 8000px
                  800: {
                    slidesPerView: 2,
                  },
                  // when window width is <= 700px
                  799: {
                    slidesPerView: 1,
                  },
                }}
                autoplay
                loop
                style={{
                  // width: "80%",
                  overflow: "hidden",
                  justifyContent: "center",
                  // padding: "0 10%"
                  padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
                }}
              >
                <SwiperSlide sx={{ maxWidth: "100%", height: 550 }}>

                  <SubscriptionCard
                    header={"IWC Silver Membership"}
                    body={subscriptionData}
                    footerInfo1={"$145.00"}
                    footerInfo2={"Per Video"}
                    // headerColor="#00000029"
                    headerColor="linear-gradient(to right, rgba(225, 225, 225, 1), rgba(57, 57, 57, 1))"
                  />
                </SwiperSlide>

                <SwiperSlide style={{ width: 500, height: 550 }}>
                  <SubscriptionCard
                    header={"IWC Pay-Per-Video"}
                    body={subscriptionData1}
                    footerInfo1={"$5.00"}
                    footerInfo2={"Per Video"}
                    headerColor=""
                  />
                </SwiperSlide>

                <SwiperSlide style={{ width: 500, height: 550 }}>
                  <SubscriptionCard
                    header={"IWC Gold Membership"}
                    body={subscriptionData}
                    footerInfo1={"$287.50"}
                    footerInfo2={"Per Video"}
                    // headerColor="rgba(255,215,0, 0.5)"
                    headerColor="linear-gradient(to right, rgba(253, 207, 113, 1), rgba(127, 104, 57, 1))"
                  />
                </SwiperSlide>
                <SwiperSlide style={{ width: 500, height: 550 }}>
                  <SubscriptionCard
                    header={"IWC Pay-Per-Video"}
                    body={subscriptionData1}
                    footerInfo1={"$5.00"}
                    footerInfo2={"Per Video"}
                    headerColor=""
                  />
                </SwiperSlide>
              </Swiper>
            )}
          </Box>
        </Container>
      </Container> */}



      <Box id="planSection" sx={{

        width: '100%',
        height: 'auto',
        position: 'relative',
        mt: { xs: -40, md: -37.5, lg: -38 },
      }}
        className="plan-card-top"
      >

        <Box className="plan-video-overlay"></Box>

        <video style={{
          width: '100%',
          maxHeight: '100vh',
          minHeight: '800px',
          objectFit: 'cover',
        }} src={michaelBg} autoPlay loop muted />

        <Box className="" sx={{

          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          // py: { xs: '20px', sm: '30px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}>

          <Typography variant="h3" className="font-gloucester" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }}>Join My Exclusive IWC Club</Typography>

          <Typography variant="p" sx={{ mx: { xs: 2, sm: 3 }, mt: 2, textAlign: "center" }}>
            Become a member of our Intuitive Warrior Club{" "}
            <Typography variant="span" sx={{ color: "#fbac18" }}>
            Enjoy ALL the IWC has to offer with IWC All Access Membership
            </Typography>
          </Typography>

          <img src={iwcLogo} alt="iwc logo" style={{ marginTop: "15px", marginBottom: "15px", width: "10%" }} />

          <Grid container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ py: 1, px: { xs: 2, sm: 4, md: 6 } }}
          >

            <Grid item sx={{ alignItems: "center" }}>

              <Box sx={{ backgroundColor: "white", color: "black", p: 3, borderRadius: 2, textAlign: "center" }}>

                <Typography variant="span" sx={{

                  fontSize: { xs: '15px', sm: '20px' },
                  background: 'linear-gradient(0deg, rgba(228,186,17,1) 0%, rgba(218,155,20,1) 100%)',
                  px: "15px",
                  py: "8px",
                  fontWeight: "bolder",
                  color: "white",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  mb: 2
                }}>
                  IWC All Access Membership
                </Typography>


                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <List sx={{ listStyleType: 'none', padding: 0, mt: 2, textAlign: "center" }}>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', color: 'green', mr: 1 }}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="Access to IWC Social Platform" />
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', color: 'green', mr: 1 }}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="IWC Private Livestreams" />
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', color: 'green', mr: 1 }}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="IWC Member-Exclusive Videos" />
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', color: 'green', mr: 1 }}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="Save 5% off Michael Jaco’s Master-Level Courses" />
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', color: 'green', mr: 1 }}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="Save 5% off Michael Jaco’s Gear" />
                    </ListItem>
                  </List>
                </Box>


                <Divider variant="middle" sx={{ my: 2, height: '4px' }} />
                <Typography variant="body1">
                  <strong style={{ color: "#fbac18" }}>$14.95</strong> Per Month
                </Typography>

                <div><strong>OR</strong></div>
                <Typography variant="body1">
                  <strong style={{ color: "#fbac18" }}>$145.00</strong> Annually
                </Typography>
                <div>and enjoy 2 months free</div>
                {/* Button that redirects the user to the registration page when clicked */}
                  <Box sx={{ justifyContent: "center", textAlign: "center", mt: 2 }}>
                      <ReusableButton 
                        text="Register Now" 
                        style={{ minHeight: "50px", minWidth: "100%" }}
                        onClick={() => window.location.href = "https://michaelkjacosocial.com/register/"}
                      />
                  </Box>
              </Box>

            </Grid>

          </Grid>


        </Box>
      </Box>


      <DiscoverWorld />

      <PartnerProducts />
      <FAQ />
      <SideSocialLinks />
      <FixedBottomNavigation />
      <Footer />
    </>
  );
}

export default Dashboard;