import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
  IconButton,
  Menu
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import { wildSearchVideos, videoDeleteById } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { fetchCollectionVideo } from "../../Features/Services/collectionVideo";
import { profileUserData } from "../../Features/Services/profile";
import SuperadminVideo from "./adminVideo";

const Admin = () => {
  const [userID, setUserID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [videos, setVideos] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);

  const {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  } = useVideoCategories();

  const {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  } = useVideoCollections();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [videoDetails, setVideoDetails] = useState([]);  // Store video details here

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  console.log("videoDetails", videoDetails);

  ;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFieldInitial = {
    collectionName: "",
    categoryName: "",
    newTitle: "",
  };
  const [searchField, setSearchField] = useState(searchFieldInitial);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    searchVideo();
  }, []);

  const searchVideo = () => {
    setSearching(true);
    wildSearchVideos(searchField)
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setRows([]);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const resetSearch = () => {
    setSearchField(searchFieldInitial);
    setRows([]);
  };

  useEffect(() => {
    const successCallback = (data) => {
      setProfileData(data[0]);
      setIsLoading(false);
    };

    const failureCallback = (error) => {
      console.error("Error fetching profile data:", error);
      setIsLoading(false);
    };

    const fetchProfileData = async () => {
      setIsLoading(true);
      profileUserData(null, successCallback, failureCallback);
    };

    fetchProfileData();
  }, []);

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/admin/send-notification" },
    { label: "Videos", path: "/admin" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const rolemenuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Watch Later", path: "/watchlater" },
    { label: "My Collections", path: "/collection" },
    { label: "Profile Settings", path: "/profile" },
  ];

  useEffect(() => {
    const storeuserID = localStorage.getItem('userInfo');
    const userId = JSON.parse(storeuserID);

    if (storeuserID) {
      setUserID(userId.id);
    }
    fetchCollectionVideo(userId,
      (data) => {

        setVideoDetails(data);  // Set video details from the response
        console.log("videoDetails", videoDetails);

      },
      (error) => {
        console.error("Error fetching video details:", error);
      }
    );
  }, []);

  const editVideo = (video) => {
    navigate("/editVideo", {
      state: { video_id: video.video_id },
    });
  };

  const exportVideo = async (video) => {
    if (!video.object_url) {
      console.error("Video URL not available");
      return;
    }

    try {
      // Fetch the video data as a Blob
      const response = await fetch(video.object_url);
      if (!response.ok) throw new Error("Failed to fetch video");

      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = video.new_title || "downloaded_video";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error exporting video:", error);
    }
  };

  const removeVideo = async (video_id) => {
    console.log("Attempting to delete video with ID:", video_id);
    if (!video_id) return;

    try {
      setIsLoading(true);
      const response = await videoDeleteById(video_id);
      setVideoDetails((prevVideos) =>
        prevVideos.filter((video) => video.video_id !== video_id)
      );
      setMessage("Video successfully deleted");
      console.log(`Video with ID: ${video_id} successfully deleted.`);

    } catch (error) {
      console.error("Error deleting video:", error);
    } finally {
      setIsLoading(false);
    }
  };





  const ActionMenu = ({ video }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    console.log("profileData", profileData);

    return (
      <>
        <IconButton onClick={handleClick}>
          <EditIcon style={{ color: "#fbac18" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              editVideo(video);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              exportVideo(video);
            }}
          >
            Export Video
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              if (!isLoading) removeVideo(video.video_id); // Disable click if loading
            }}
            style={{ color: isLoading ? "gray" : "red", pointerEvents: isLoading ? "none" : "auto" }}
          >
            Remove
          </MenuItem>

        </Menu>
      </>
    );
  };

  const handlevideoID = (videoId) => {
    navigate(`/videoStreaming/${videoId}`);
  };
  return (
    <>
      <MainHeader />
      <Backdrop
        open={isLoading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
      <InnerHeader />

      <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "5%" }}>

        <div className="page-url-wrapper">
          {profileData && (
            <ul className="ul-content">
              {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                <li key={item.label}>
                  <Link
                    to={item.path}
                    rel="noopener noreferrer"
                    className={`li-content ${location.pathname === item.path ? "active-link" : ""
                      }`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress size={"48px"} />
          </Backdrop>
        </div>


        {profileData && profileData.Role === 4 ? (
          <SuperadminVideo />
        ) : (
          <>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table>
                <TableHead sx={{ backgroundColor: "#19315c" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>S No</TableCell>
                    <TableCell sx={{ color: "white" }}>Video Title</TableCell>
                    <TableCell sx={{ color: "white" }}>Category</TableCell>
                    <TableCell sx={{ color: "white" }}>Collection</TableCell>
                    <TableCell sx={{ color: "white" }}>Views</TableCell>
                    <TableCell sx={{ color: "white" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {videoDetails.length > 0 ? (
                    videoDetails
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((video, index) => (
                        <TableRow key={video.video_id}>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell onClick={() => handlevideoID(video.video_id)}>{video?.new_title}</TableCell>
                          <TableCell>{video.category_id}</TableCell>
                          <TableCell>{video.collection_id}</TableCell>
                          <TableCell>{video.views}</TableCell>
                          <TableCell>
                            <ActionMenu video={video} />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="h6" color="textSecondary">
                          Video not found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>

            </TableContainer>

            {videoDetails.length > 5 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={videoDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            {message && (
              <Typography
                sx={{ mt: 2, textAlign: "center", color: "green" }}> {message}</Typography>

            )}
          </>
        )}


      </Container>

      <FixedBottomNavigation />
      <Footer />
    </>
  );
};

export default Admin;
