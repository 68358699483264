import React, { useEffect, useState } from "react";
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Avatar,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    CircularProgress,
    Backdrop
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CustomInput from "../../Components/Form/CustomInput";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { profileUser, profileUserData, updatePassword, fetchCountry, fetchStates } from "../../Features/Services/profile";
import { isValidString } from "../../Utils/validators";
import { LocalImages } from "../../Utils/images";
import './profile.css';



const Profile = () => {
    const [profileData, setProfileData] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [fileBase64, setFileBase64] = useState("");
    const [message, setMessage] = useState("");
    const [useremail, setUseremail] = useState('');
    const [username, setUsername] = useState('');
    const [userpassword, setUserPassword] = useState('');
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            setUseremail(userInfo.email);
            setUsername(userInfo.username);
        }
    }, []);



    const [preview, setPreview] = useState("");

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setFileBase64(reader.result.split(",")[1]); // Save Base64 content
                setPreview(reader.result); // Set preview for image
            };
            reader.readAsDataURL(selectedFile); // Convert file to Base64
            setPhoto(selectedFile); // Save the file for future use
        }
    };

    const handlePhotoRemove = () => {
        setPreview(""); // Clear preview
        setFileBase64(""); // Clear Base64 content
        setPhoto(null); // Clear file object
    };

    const handleClose = () => {
        setLoading(false);
    };

    const initialValues = {
        UserName: `${username}`,
        FirstName: "",
        LastName: "",
        Email: `${useremail}`,
        PhoneNumber: "",
        photo: null,
        Address: "",
        City: "",
        State: "",
        ZipCode: "",
        Country: "",
        profile_image_url: "",
        oldPassword: "",
        newPassword: "",
        verifyNewPassword: "",
    };

    // Validation schema using Yup
    const profileSchema = Yup.object().shape({
        FirstName: Yup.string().required("First Name is required"),
        LastName: Yup.string().required("Last Name is required"),
        PhoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(
            /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/,
            "Phone Number must be a valid US number (e.g., 123-456-7890 or +1 123-456-7890)"
        ),
        Address: Yup.string().required("Address is required"),
        City: Yup.string().required("City is required"),
        State: Yup.string().required("State is required"),
        Country: Yup.string().required("Country is required"),
        ZipCode: Yup.string()
        .required("Postal/ZIP Code is required")
        .matches(
          /^\d{5}(-\d{4})?$/,
          "ZIP Code must be 5 digits or 5+4 format (e.g., 12345 or 12345-6789)"
        ),
        newPassword: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/\d/, "Password must contain at least one number"),
        verifyNewPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    });
    useEffect(() => {
        const successCallback = (data) => {
          setProfileData(data[0]);
          setLoading(false); 
        };
    
        const failureCallback = (error) => {
          console.error("Error fetching profile data:", error); 
          setLoading(false); 
        };
    
        const fetchProfileData = async () => {
          setLoading(true); 
          profileUserData(null, successCallback, failureCallback);
        };
    
        fetchProfileData();
      }, []);

      
      const menuItems = [
        { label: "Account Overview", path: "/account-overview" },
        { label: "Notifications", path: "/admin/send-notification" },
        { label: "Videos", path: "/admin" },
        { label: "Collections", path: "/collection" },
        { label: "Schedule", path: "/" },
        { label: "Profile Settings", path: "/profile" },
      ];
    
      const rolemenuItems = [
        { label: "Account Overview", path: "/account-overview" },
        { label: "Watch Later", path: "/watchlater" },
        { label: "My Collections", path: "/collection" },
        { label: "Profile Settings", path: "/profile" },
      ];
   


    const profileItems = [
        { label: "Basic Information", id: "basic-info" },
        { label: "Address Information", id: "address-info" },
        { label: "Subscription Details", id: "subscription-detail" },
        { label: "Security Information", id: "security-info" },
    ];

    const subscriptionDetails = {
        currentPlan: "All Access Membership - Monthly",
        memberSince: "01/01/2022",
        expires: "11/26/2024",
        nextPayment: "$14.95",
        nextPaymentDate: "11/26/2024",
        lastPaymentDate: "11/26/2024",
    };

    const handleScroll = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const profileForm = useFormik({
        initialValues,
        validationSchema: profileSchema,
        onSubmit: async (values) => {

            try {
                const profilePayload = {
                    UserName: `${username}`,
                    FirstName: values.FirstName,
                    LastName: values.LastName,
                    Email: `${useremail}`,
                    PhoneNumber: values.PhoneNumber,
                    State: values.State,
                    Country: values.Country,
                    Address: values.Address,
                    City: values.City,
                    ZipCode: values.ZipCode,
                    profile_image_url: fileBase64,
                };

                await profileUser(profilePayload);
                const passwordPayload = {
                    email: `${useremail}`,
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                    verifyNewPassword: values.verifyNewPassword,
                };

                await updatePassword(passwordPayload);
                setMessage("Profile updated successfully!");
                handleClose();
                navigate("/");
            } catch (error) {
                console.error(error);
                setMessage("Error updating profile or password!");
            }
        },
        // validateOnMount: true,
        enableReinitialize: true,
    });

    useEffect(() => {
        const successCallback = (data) => {
            console.log('Successfully fetched user data:', data);

            if (data && data.length > 0) {
                const user = data[0];
                console.log('User data:', user);

                // Set values in the form
                profileForm.setFieldValue('UserName', user.UserName || '');
                profileForm.setFieldValue('FirstName', user.FirstName || '');
                profileForm.setFieldValue('LastName', user.LastName || '');
                profileForm.setFieldValue('Email', user.Email || '');
                profileForm.setFieldValue('PhoneNumber', user.PhoneNumber || '');
                profileForm.setFieldValue('Address', user.Address || '');
                profileForm.setFieldValue('City', user.City || '');
                profileForm.setFieldValue('State', user.State || '');
                profileForm.setFieldValue('ZipCode', user.ZipCode || '');
                profileForm.setFieldValue('Country', user.Country || '');
                setPreview(user.profile_image_url ? `data:image/png;base64,${user.profile_image_url}` : "");

            } else {
                console.error('No user data found');
            }
        };

        const failureCallback = (err) => {
            console.error('Error fetching user data:', err);
            setError(err);
        };

        // Assuming profileUserData fetches user data
        profileUserData(null, successCallback, failureCallback);
    }, []);

    useEffect(() => {
        const successCallback = (data) => {
          setCountries(data); 
          setLoading(false); 
        };
        
        const failureCallback = (error) => {
          console.error("Error fetching countries:", error); 
          setLoading(false); 
        };
    
        fetchCountry(null, successCallback, failureCallback); 
      }, [])

      useEffect(() => {
        const successCallback = (data) => { 
          setStates(data);
          setLoading(false); 
        };
        
        const failureCallback = (error) => {
          console.error("Error fetching countries:", error); 
          setLoading(false); 
        };
        fetchStates(null, successCallback, failureCallback); 
      }, [])
    return (
        <>

            <MainHeader />
            <Backdrop
                open={isLoading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress size={"48px"} />
            </Backdrop>
            <InnerHeader />
            <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "5%" }}>
            <div className="page-url-wrapper">
          {profileData && (
            <ul className="ul-content">
              {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                <li key={item.label}>
                  <Link
                    to={item.path}
                    rel="noopener noreferrer"
                    className={`li-content ${location.pathname === item.path ? "active-link" : ""
                      }`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress size={"48px"} />
          </Backdrop>
        </div>

                <div className="profile-wrapper">
                    <div className="profile-content-wrapper">
                        <Typography variant="h4" sx={{ mb: 3 }} className="profile-main-heading">
                            Profile Settings
                        </Typography>
                        <div className="profile-content">
                            {profileItems.map((item) => (
                                <div className="profile-item" key={item.label}>
                                    <Button
                                        onClick={() => handleScroll(item.id)}
                                        sx={{ textTransform: "none" }}
                                    >
                                        <div className="profile-item-label">{item.label}</div>
                                    </Button>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="profile-wrapper-form" maxWidth="md">

                        <form onSubmit={profileForm.handleSubmit} className="form basic-form">
                            <section id="basic-info" style={{ marginBottom: "50px" }}>
                                <div className="basic-heading">Basic Information</div>
                                <div className="basic-info">
                                    {/* Username */}
                                    <div className="user-email-field" style={{ pointerEvents: "none", userSelect: "none" }}>
                                        <label className="label-field">UserName</label>
                                        <CustomInput
                                            fullWidth
                                            margin="normal"
                                            label="Username"
                                            id="username"
                                            name="username"
                                            className="form-field"
                                            value={profileForm.values.UserName}
                                            InputProps={{ readOnly: true }}

                                        />
                                    </div>
                                    {/* First Name */}
                                    <div className="profile-name-wrapper">
                                        <div className="fname-label">
                                            <label className="label-field">First Name</label>
                                            <CustomInput
                                                fullWidth
                                                margin="normal"
                                                label="First Name"
                                                id="firstName"
                                                name="firstName"
                                                className="form-field"
                                                value={profileForm.values.FirstName}
                                                onChangeText={(value) => profileForm.setFieldValue("FirstName", value)}
                                                handleBlur={profileForm.handleBlur("FirstName")}
                                                isError={
                                                    isValidString(profileForm.errors.FirstName) && profileForm.touched.FirstName
                                                }
                                                errorMsg={profileForm.errors.FirstName}
                                                isRequired={true}
                                            />
                                        </div>

                                        {/* Last Name */}
                                        <div className="fname-label">
                                            <label className="label-field">Last Name</label>
                                            <CustomInput
                                                fullWidth
                                                margin="normal"
                                                className="form-field"
                                                label="Last Name"
                                                id="lastName"
                                                name="lastName"
                                                value={profileForm.values.LastName}
                                                onChangeText={(value) => profileForm.setFieldValue("LastName", value)}
                                                handleBlur={profileForm.handleBlur("LastName")}
                                                isError={
                                                    isValidString(profileForm.errors.LastName) && profileForm.touched.LastName
                                                }
                                                errorMsg={profileForm.errors.LastName}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                    {/* Email */}
                                    <div className="user-email-field" style={{ pointerEvents: "none", userSelect: "none" }}>
                                        <label className="label-field">Email</label>
                                        <CustomInput
                                            fullWidth
                                            margin="normal"
                                            label="Email"
                                            id="email"
                                            className="form-field"
                                            name="email"
                                            value={profileForm.values.Email}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    {/* Phone Number */}
                                    <div className="profile-name-wrapper">
                                        <div className="fname-label">
                                            <label className="label-field">Phone Number</label>
                                            <CustomInput
                                                fullWidth
                                                margin="normal"
                                                label="Phone Number"
                                                className="form-field"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                value={profileForm.values.PhoneNumber}
                                                onChangeText={(value) => profileForm.setFieldValue("PhoneNumber", value)}
                                                handleBlur={profileForm.handleBlur("PhoneNumber")}
                                                isError={
                                                    isValidString(profileForm.errors.PhoneNumber) && profileForm.touched.PhoneNumber
                                                }
                                                errorMsg={profileForm.errors.PhoneNumber}
                                                isRequired={true}
                                            />
                                        </div>
                                        {/* Photo Upload */}
                                        <div className="photo-wrapper">
                                            <Box sx={{ mt: 2, mb: 3 }}>
                                                <Button variant="outlined" component="label">
                                                    {preview ? "Update" : "Choose Photo"}
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="cover-image"
                                                        style={{ display: "none" }}
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>

                                                {preview && (
                                                    <Box sx={{ mt: 2, position: "relative", display: "inline-block" }}>
                                                        <img
                                                            src={preview}
                                                            alt="Preview"
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                borderRadius: "60px",
                                                            }}
                                                        />
                                                        <IconButton
                                                            color="error"
                                                            onClick={handlePhotoRemove}
                                                            sx={{
                                                                position: "static",
                                                                top: 5,
                                                                right: 5,
                                                                backgroundColor: "white",
                                                                ":hover": { backgroundColor: "#f5f5f5" },
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </Box>
                                        </div>

                                    </div>


                                </div>
                            </section>

                            <section id="address-info" style={{ marginBottom: "50px" }}>
                                <div className="basic-heading">Address Information</div>

                                <div className="basic-info">

                                    {/* address */}
                                    <label className="label-field">Address</label>
                                    <CustomInput
                                        fullWidth
                                        margin="normal"
                                        label="Street Address"
                                        id="address"
                                        name="address"
                                        className="form-field"
                                        value={profileForm.values.Address}
                                        onChangeText={(value) => profileForm.setFieldValue("Address", value)}
                                        handleBlur={profileForm.handleBlur("Address")}
                                        isError={
                                            isValidString(profileForm.errors.Address) && profileForm.touched.Address
                                        }
                                        errorMsg={profileForm.errors.Address}
                                        isRequired={true}
                                    />

                                    {/* City */}
                                    <div className="profile-name-wrapper">
                                        <div className="fname-label">
                                            <label className="label-field">City</label>
                                            <CustomInput
                                                fullWidth
                                                margin="normal"
                                                label="City"
                                                id="city"
                                                name="city"
                                                className="form-field"
                                                value={profileForm.values.City}
                                                onChangeText={(value) => profileForm.setFieldValue("City", value)}
                                                handleBlur={profileForm.handleBlur("City")}
                                                isError={
                                                    isValidString(profileForm.errors.City) && profileForm.touched.City
                                                }
                                                errorMsg={profileForm.errors.City}
                                                isRequired={true}
                                            />
                                        </div>

                                        {/* State */}
                                        <div className="fname-label">
                                            <label className="label-field">State</label>
                                            <FormControl fullWidth margin="normal" className="form-field">
                                                {/* <InputLabel id="state-label">State</InputLabel> */}
                                                <Select
                                                    labelId="state-label"
                                                    id="state"
                                                    name="State"
                                                    value={profileForm.values.State || ""}
                                                    onChange={profileForm.handleChange}
                                                    onBlur={profileForm.handleBlur}
                                                    error={profileForm.touched.State && Boolean(profileForm.errors.State)}
                                                >
                                                  {states.map((state) => (
                                                    <MenuItem key={state.StateID} value={state.Name}> 
                                                        {state.Name} 
                                                    </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </div>

                                        <div className="fname-label">
                                            <label className="label-field">Zipcode/Postal Code</label>
                                            <CustomInput
                                                fullWidth
                                                margin="normal"
                                                label="Zipcode"
                                                id="zipcode"
                                                className="form-field"
                                                name="zipcode"
                                                value={profileForm.values.ZipCode}
                                                onChangeText={(value) => profileForm.setFieldValue("ZipCode", value)}
                                                handleBlur={profileForm.handleBlur("ZipCode")}
                                                isError={
                                                    isValidString(profileForm.errors.ZipCode) && profileForm.touched.ZipCode
                                                }
                                                errorMsg={profileForm.errors.ZipCode}
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>

                                    {/* Country */}
                                    <label className="label-field">Country</label>
                                    <FormControl fullWidth margin="normal" className="form-field">
                                        <InputLabel id="country-label">Country</InputLabel>
                                        <Select
                                            labelId="country-label"
                                            label="Country"
                                            id="country"
                                            name="Country"
                                            value={profileForm.values.Country || ""}
                                            onChange={profileForm.handleChange}
                                            onBlur={profileForm.handleBlur}
                                            error={profileForm.touched.Country && Boolean(profileForm.errors.Country)}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.CountryID} value={country.Name}> 
                                                    {country.Name} 
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </div>
                            </section>

                            <section id="subscription-detail">
                                <div className="basic-heading">Subscription Details</div>
                                <div className="basic-info subscription-info-wrapper">
                                    <div className="subscription-info-image">
                                        <Box className="sub-img">
                                            <img
                                                src={LocalImages.subscription}
                                                style={{
                                                    width: "250px",
                                                    height: "250px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </Box>
                                    </div>
                                    <div className="subscription-info">
                                        <div className="subscription-inner">
                                            <div className="sub-inner-detail">
                                                <div className="subscription-label">
                                                    Current Plan
                                                </div>
                                                <div className="sub-heading">
                                                    {subscriptionDetails.currentPlan}
                                                </div>
                                            </div>
                                            <div className="sub-inner-button">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Change Plan
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="sub-member">
                                            <div className="sub-member-inner">
                                                <div className="subscription-label">
                                                    Member Since
                                                </div>
                                                <div className="sub-member-date">
                                                    {subscriptionDetails.memberSince}
                                                </div>
                                            </div>
                                            <div className="sub-member-inner">
                                                <div className="subscription-label">
                                                    Expires
                                                </div>
                                                <div className="sub-member-date">
                                                    {subscriptionDetails.expires}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="subscription-inner">
                                            <div className="sub-inner-detail">
                                                <div className="subscription-label">
                                                    Next Payment on 11/26/2024
                                                </div>
                                                <div className="sub-heading-date">
                                                    {subscriptionDetails.nextPayment}
                                                </div>
                                            </div>
                                            <div className="sub-inner-button">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    View Charges
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="sub-member">
                                            <div className="sub-member-inner">
                                                <div className="subscription-label">
                                                    Last Payment Date
                                                </div>
                                                <div className="sub-member-date">
                                                    {subscriptionDetails.lastPaymentDate}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="subscription-inner">
                                            <div className="sub-inner-detail">
                                                <div className="subscription-label">
                                                    Cancel Subscription
                                                </div>
                                            </div>
                                            <div className="sub-inner-button">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                            <section id="security-info" style={{ marginBottom: "50px" }}>
                                <div className="basic-heading">Security Information</div>

                                {/* Security */}
                                <div className="basic-info">
                                    {/* Old Password */}
                                    <label className="label-field">Old Password</label>
                                    <CustomInput
                                        fullWidth
                                        margin="normal"
                                        label="Old Password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        type="password"
                                        className="form-field"
                                        value={profileForm.values.oldPassword}
                                        onChangeText={(value) => profileForm.setFieldValue("oldPassword", value)}
                                        handleBlur={profileForm.handleBlur("oldPassword")}
                                        isError={
                                            isValidString(profileForm.errors.oldPassword) && profileForm.touched.oldPassword
                                        }
                                        errorMsg={profileForm.errors.oldPassword}
                                    />

                                    {/* New Password */}
                                    <div className="user-email-field">
                                        <label className="label-field">New Password</label>
                                        <CustomInput
                                            fullWidth
                                            margin="normal"
                                            label="New Password"
                                            id="newPassword"
                                            name="newPassword"
                                            type="password"
                                            className="form-field"
                                            value={profileForm.values.newPassword}
                                            onChangeText={(value) => profileForm.setFieldValue("newPassword", value)}
                                            handleBlur={profileForm.handleBlur("newPassword")}
                                            isError={
                                                isValidString(profileForm.errors.newPassword) && profileForm.touched.newPassword
                                            }
                                            errorMsg={profileForm.errors.newPassword}
                                        />
                                    </div>
                                    {/* Confirm New Password */}
                                    <div className="user-email-field">
                                        <label className="label-field">Confirm New Password</label>
                                        <CustomInput
                                            fullWidth
                                            margin="normal"
                                            label="Confirm New Password"
                                            id="verifyNewPassword"
                                            name="verifyNewPassword"
                                            type="password"
                                            className="form-field"
                                            value={profileForm.values.verifyNewPassword}
                                            onChangeText={(value) => profileForm.setFieldValue("verifyNewPassword", value)}
                                            handleBlur={profileForm.handleBlur("verifyNewPassword")}
                                            isError={
                                                isValidString(profileForm.errors.verifyNewPassword) && profileForm.touched.verifyNewPassword
                                            }
                                            errorMsg={profileForm.errors.verifyNewPassword}
                                        />
                                    </div>


                                    {/* Submit Button */}
                                    <div className="submit-button">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                            disabled={!profileForm.isValid || profileForm.isSubmitting}
                                        >
                                            {profileForm.isSubmitting ? <CircularProgress size={24} /> : "Save"}
                                        </Button>
                                    </div>
                                </div>
                                {message && (
                                    <Typography color={message.includes("Thank you") ? "green" : "red"} style={{ marginTop: "1rem", textAlign: "center" }}>
                                        {message}
                                    </Typography>
                                )}
                            </section>

                        </form>

                    </div>
                </div>
            </Container>
            <FixedBottomNavigation />
            <Footer />
        </>
    );
};

export default Profile;
