import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LocalImages } from "../Utils/images";
import { profileUserData } from "../Features/Services/profile";
import "../Assets/CSS/InnerHeader.css";

const ProfileHeader = () => {
  const roleMapping = {
    1: "Gold",
    2: "Silver",
    3: "Bronze",
    4: "Super-Admin",
    5: "All Access Membership - Monthly",
    6: "All Access Membership - Annual",
  };
  const [userData, setUserData] = useState({
    UserName: "MKJaco",
    FirstName: "Michael",
    LastName: "K Jaco",
    Email: "info@michaelkjaco.com",
    Role: "Admin",
    profile_image_url: null,
  });

  
  useEffect(() => {
    const successCallback = (data) => {
      if (data && data.length > 0) {
        const user = data[0];
        setUserData({
          UserName: user.UserName || "MKJaco",
          FirstName: user.FirstName || "Michael",
          LastName: user.LastName || "K Jaco",
          Email: user.Email || "info@michaelkjaco.com",
          profile_image_url: user.profile_image_url || null,
          Role: roleMapping[user.Role] || "Admin", 
        });
      }
    };

    const failureCallback = (err) => {
      console.error("Error fetching user data:", err);
    };

    profileUserData(null, successCallback, failureCallback);
  }, []);

  return (
    <Box>
      <div className="bg">
        <div className="profile-main-wrapper">
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              style={{
                width: "245px",
                height: "245px",
                objectFit: "cover",
                borderRadius: "100%", 
              }}
              src={
                userData.profile_image_url
                  ? `data:image/png;base64,${userData.profile_image_url}`
                  : LocalImages.michaelJaco
              }
              alt={userData.UserName}
            />
          </Box>
          <Box textAlign="left" color="white" paddingRight="50px" className="profile-detail">
            <Typography variant="h5" fontWeight="bold" className="profile-heading">
              {userData.UserName}
            </Typography>
            <Typography variant="body2" className="profile-admin">
            {userData.Role} 
            </Typography>
            <Typography variant="body2" className="profile-subheading">
              {`${userData.FirstName} ${userData.LastName}`}
            </Typography>
            <Typography variant="body2" className="profile-email">
              {userData.Email}
            </Typography>
          </Box>
        </div>
      </div>
    </Box>
  );
};

const InnerHeader = () => {
  return (
    <Box>
      <ProfileHeader />
    </Box>
  );
};

export default InnerHeader;
