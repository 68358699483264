import React, { useEffect, useState } from "react";
import { Container, Box, Grid } from "@mui/material";
import LatestVideoClientAccount from "../Admin/latestVideoClientAccount";
import { LocalImages } from "../../Utils/images";
import './account.css';

const ClientAccountOverview = () => {

    return (
        <>
            <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
                <div class="save-upcoming-event">
                    <div class="latest-video-header">
                        <h1 class="latest-video-title">Saved Upcoming Shows</h1>
                        <button class="view-all-button">View All</button>
                    </div>

                    <div class="upcoming-events-list">

                        <div class="event-item live-now">
                            <div class="event-date">
                                <Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.playIcon}
                                        style={{
                                            width: "33px",
                                            height: "22px",
                                        }}
                                    />
                                </Box>
                                <span class="event-status">LIVE NOW</span>
                            </div>
                            <div class="event-details">
                                <span className="live-stream">Live Stream</span>
                                <h3 class="event-title">Miracle Monday</h3>
                                <p class="event-time"><span className="yellowVector"><Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.redVector}
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                        }}
                                    />
                                </Box>12:00 pm - 01:00 pm</span></p>
                            </div>
                            <div className="event-guest-details">
                                <p class="event-guests">Guests</p>
                                <p className="guest-name">Patty Greer</p>
                            </div>
                            <div class="event-actions">
                                <button class="watch-now-button">Watch Now</button>
                                <button class="share-button"><Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.sharealternate}
                                        style={{
                                            width: "25px",
                                            height: "29px",
                                        }}
                                    />
                                </Box></button>
                            </div>
                        </div>

                        <div class="event-item">
                            <div class="event-date">
                                <Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.calender}
                                        style={{
                                            width: "27px",
                                            height: "31px",
                                        }}
                                    />
                                </Box>
                                <span class="event-day">Sept 17</span>
                            </div>
                            <div class="event-details">
                                <span className="live-stream">Live Stream</span>
                                <h3 class="event-title">Miracle Monday</h3>
                                <p class="event-time"><span className="yellowVector">
                                    <Box sx={{ position: "relative", marginRight: "10px" }}>
                                        <img
                                            src={LocalImages.yellowVector}
                                            style={{
                                                width: "12px",
                                                height: "12px",
                                            }}
                                        />
                                    </Box>12:00 pm - 01:00 pm</span></p>
                            </div>
                            <div className="event-guest-details">
                                <p class="event-guests">Guests</p>
                                <p className="guest-name">Patty Greer</p>
                            </div>
                        </div>

                        <div class="event-item">
                            <div class="event-date">
                                <Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.calender}
                                        style={{
                                            width: "27px",
                                            height: "31px",
                                        }}
                                    />
                                </Box>
                                <span class="event-day">Sept 17</span>
                            </div>
                            <div class="event-details">
                                <span className="live-stream">Live Stream</span>
                                <h3 class="event-title">Miracle Monday</h3>
                                <p class="event-time"><span className="yellowVector">
                                    <Box sx={{ position: "relative", marginRight: "10px" }}>
                                        <img
                                            src={LocalImages.yellowVector}
                                            style={{
                                                width: "12px",
                                                height: "12px",
                                            }}
                                        />
                                    </Box>12:00 pm - 01:00 pm</span></p>
                            </div>
                            <div className="event-guest-details">
                                <p class="event-guests">Guests</p>
                                <p className="guest-name">Patty Greer</p>
                            </div>
                        </div>

                        <div class="event-item">
                            <div class="event-date">
                                <Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <img
                                        src={LocalImages.calender}
                                        style={{
                                            width: "27px",
                                            height: "31px",
                                        }}
                                    />
                                </Box>
                                <span class="event-day">Sept 17</span>
                            </div>
                            <div class="event-details">
                                <span className="live-stream">Live Stream</span>
                                <h3 class="event-title">Miracle Monday</h3>
                                <p class="event-time"><span className="yellowVector">
                                    <Box sx={{ position: "relative", marginRight: "10px" }}>
                                        <img
                                            src={LocalImages.yellowVector}
                                            style={{
                                                width: "12px",
                                                height: "12px",
                                            }}
                                        />
                                    </Box>12:00 pm - 01:00 pm</span></p>
                            </div>
                            <div className="event-guest-details">
                                <p class="event-guests">Guests</p>
                                <p className="guest-name">Patty Greer</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="watch-video-wrapper">
                    <div className="watch-video-main">
                        <LatestVideoClientAccount />
                    </div>

                </div>

            </Container>
        </>
    );
};

export default ClientAccountOverview;
