import React from "react";
import { Container, Typography, Box, Button, styled, Grid } from "@mui/material";
import { purple } from "@mui/material/colors";

import { LocalImages } from "../Utils/images";
import { Strings } from "../Utils/strings";
import ReusableButton from "../ui/ReuseableButton";


const listData = [
  {
    imageUrl: LocalImages.lifeWave,
    label: "Life Wave",
  },
  {
    imageUrl: LocalImages.intuitiveWellness,
    label: "Intuitive Wellness",
  },
  {
    imageUrl: LocalImages.rootWellness,
    label: "Root Wellness",
  },
  {
    imageUrl: LocalImages.eMFIntuitive,
    label: "EMF Harmonizer",
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

const PartnerProducts = () => {

  return (
    <Box sx={{ textAlign: "center", pt: 8 }}>
      <Typography
        variant="h4"
        gutterBottom
        className="font-gloucester"
        sx={{ p: 0, m: 0 }}
        fontSize={'50px'}
      >
        {Strings.shopMjPartnersAndSave}
      </Typography>
      <Typography
        variant="subtitle1"
        className="color-gray"
        fontSize={'16px'}
        sx={{ my: 2, padding: { sm: "0 20%", md: "0 35%" }, pb: 2, lineHeight: 1, fontWeight: "normal" }}
      >
        {Strings.homeIsBehind}
      </Typography>
      <Box>
        <img src={LocalImages.leaveWings} alt="" width={'120px'} />
      </Box>

      <Grid
      container
      justifyContent="center"
      sx={{
        textAlign: "center",
        px: { xs: 1, sm: 2, md: 6, lg: 8, xl: 8 },
        py: 3,
      }}
    >
      {listData.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Box mx={2} my={2}>


            <img
              src={item.imageUrl}
              alt={item.label}
              className="product-img"
              style={{ maxWidth: '100%', minHeight: '250px' }}
            />

            <Typography
              variant="subtitle1"
              className="font-gloucester"
              sx={{ my: 2, fontSize: "25px", fontWeight: "bold" }}
            >
              {item.label}
            </Typography>
            <Box sx={{ justifyContent: "center", textAlign: "center" }}>
              <ReusableButton text="Shop Now" style={{ minHeight: "40px", p: "20px" }} />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  



    </Box>
  );
};

export default PartnerProducts;
