import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Box, Grid, Modal, Button, TextField, MenuItem, CircularProgress,Backdrop } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { LocalImages } from "../../Utils/images";
import CustomInput from "../../Components/Form/CustomInput";
import { isValidString } from "../../Utils/validators";
import { collectionVideo, collectionlistVideo } from "../../Features/Services/collectionVideo";
import LatestVideoShow from "./latestVideoShow";
import CollectionList from "./collectionlist";
import { profileUserData } from "../../Features/Services/profile";
import './collection.css';

const Collection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [message, setMessage] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [videos, setVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [isLoading, setLoading] = useState(false);


   useEffect(() => {
          const storeuserID = localStorage.getItem('userInfo');
          const userId = JSON.parse(storeuserID);
          if (storeuserID) {
              setUserID(userId.id);
          }
      },);
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenVideoModal = () => setOpenVideoModal(true);
  const handleCloseVideoModal = () => setOpenVideoModal(false);

  const [preview, setPreview] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileBase64(reader.result.split(",")[1]);
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSave = (selectedVideos) => {
    setVideos(selectedVideos);
    setOpenModal(false);
  };

  const initialValues = {
    title: "",
    des: "",
    privacy: "",
    image: "",
    CustID: `${userID}`,
    favorite: profileData && profileData.Role === 4 ? 1 : 0,
  };

  const collectionSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    // des: Yup.string().required("Description Name is required"),
    privacy: Yup.string().required("privacy is required")
  });

  // const collectionForm = useFormik({
  //   initialValues,
  //   validationSchema: collectionSchema,
  //   onSubmit: async (values) => {
  //     try {
  //       const collectionPayload = {
  //         title: values.title,
  //         des: values.des,
  //         privacy: values.privacy,
  //         image: fileBase64,
  //         CustID: `${userID}`,
  //       };

  //       await collectionVideo(collectionPayload);
        
  //       setMessage("Collection added successfully!");
  //       handleClose();
  //     } catch (error) {
  //       console.error("Error adding collection:", error);
  //       setMessage("Error adding collection!");
  //     }
  //   },
  //   enableReinitialize: true,
  // });

  const collectionForm = useFormik({
    initialValues,
    validationSchema: collectionSchema,
    onSubmit: async (values) => {
      try {
        if (profileData.Role === 4) {
          const fetchVideos = async () => {
            try {
              const data = await collectionlistVideo(userID);
              return data;
            } catch (error) {
              console.error("Error fetching video data:", error);
              return [];
            }
          };
  
          const existingCollections = await fetchVideos();
          const existingFavorite = existingCollections.find(
            (collection) => collection.favorite === 1
          );
          values.favorite = existingFavorite ? 0 : 1;
        } else {
          values.favorite = 0;
        }
        const collectionPayload = {
          title: values.title,
          des: values.des,
          privacy: values.privacy,
          image: fileBase64,
          CustID: `${userID}`,
          favorite: values.favorite,
        };
        await collectionVideo(collectionPayload);
        setMessage("Collection added successfully!");
        handleClose();
      } catch (error) {
        console.error("Error adding collection:", error);
        setMessage("Error adding collection!");
      }
    },
    enableReinitialize: true,
  });

 useEffect(() => {
      const successCallback = (data) => {
        setProfileData(data[0]);
        setLoading(false); 
      };
  
      const failureCallback = (error) => {
        console.error("Error fetching profile data:", error); 
        setLoading(false); 
      };
  
      const fetchProfileData = async () => {
        setLoading(true); 
        profileUserData(null, successCallback, failureCallback);
      };
  
      fetchProfileData();
    }, []);

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/admin/send-notification" },
    { label: "Videos", path: "/admin" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const rolemenuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Watch Later", path: "/watchlater" },
    { label: "My Collections", path: "/collection" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const collectionmenuItem = [
    { label: "Private - Only me", value: "Private" },
    { label: "Members", value: "Members Only" },
    { label: "Public", value: "Public" },
];

  const getPrivacyOptions = () => {
    if (profileData?.Role === 4) {
      return collectionmenuItem.filter(
        option => option.value === "Members Only" || option.value === "Public"
      );
    }
    return collectionmenuItem.filter(option => option.value === "Private");
  };

  return (
    <>

      <MainHeader />
      <Backdrop
                    open={isLoading}
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                  >
                    <CircularProgress size={"48px"} />
                  </Backdrop>
      <InnerHeader />
      <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
      <div className="page-url-wrapper">
                {profileData && (
                  <ul className="ul-content">
                    {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                      <li key={item.label}>
                        <Link
                          to={item.path}
                          rel="noopener noreferrer"
                          className={`li-content ${location.pathname === item.path ? "active-link" : ""
                            }`}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                <Backdrop
                  open={isLoading}
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <CircularProgress size={"48px"} />
                </Backdrop>
              </div>
        <div class="collection-main-wrapper">
          <div className="collection-handle">
            <div className="collection-main-heading">My Collections</div>
            <div className="collection-add-button">
              <Button onClick={handleOpen}>Add New</Button>
            </div>
          </div>
          <CollectionList />
        </div>

      </Container>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 974,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <form onSubmit={collectionForm.handleSubmit} className="form collection-form">

            <div className="collection-heading">
              <span>Add Collection</span>
              <div className="collection-close" onClick={handleClose}>
                <Box sx={{ position: "relative", marginRight: "10px" }}>
                  <img
                    src={LocalImages.closeIcon} 
                    alt="Close Icon"
                    style={{
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </div>
            </div>


            <div className="collection-info">
              <div className="collection-image">
                <input
                  type="file"
                  accept="image/*"
                  id="cover-image"
                  style={{ display: "none" }} 
                  onChange={handleFileChange}
                />

                {preview && <img src={preview} alt="Preview" style={{ width: "271px", height: "auto" }} />}
                <div className="image-label" onClick={() => document.getElementById("cover-image").click()}>
                  <Box sx={{ position: "relative", marginRight: "10px" }}>
                    <img
                      src={LocalImages.vector}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                      alt="Upload Icon"
                    />
                  </Box>
                  <label className="label-field">Upload Image</label>
                </div>


              </div>
              <div className="collection-info-main">
                {/* Title */}
                <label className="label-field">Collection Title</label>
                <CustomInput
                  fullWidth
                  margin="normal"
                  label="collection title"
                  id="collection-title"
                  name="title"
                  className="form-field"
                  value={collectionForm.values.title}
                  onChangeText={(value) => collectionForm.setFieldValue("title", value)}
                  handleBlur={collectionForm.handleBlur("title")}
                  isError={
                    isValidString(collectionForm.errors.title) && collectionForm.touched.title
                  }
                  errorMsg={collectionForm.errors.title}
                  isRequired={true}
                />

                {/* Description */}
                <label className="label-field">Description</label>
                <CustomInput
                  fullWidth
                  margin="normal"
                  label="collection des"
                  id="collection-des"
                  name="description"
                  className="form-field"
                  value={collectionForm.values.des}
                  onChangeText={(value) => collectionForm.setFieldValue("des", value)}
                  handleBlur={collectionForm.handleBlur("des")}
                  isError={
                    isValidString(collectionForm.errors.des) && collectionForm.touched.des
                  }
                  errorMsg={collectionForm.errors.des}
                />

                {/* Privacy */}
                <label className="label-field">Privacy</label>
                <TextField
                  select
                  fullWidth
                  margin="normal"
                  label="Privacy"
                  id="privacy"
                  name="privacy"
                  value={collectionForm.values.privacy}
                  onChange={collectionForm.handleChange}
                  onBlur={collectionForm.handleBlur}
                  error={collectionForm.touched.privacy && Boolean(collectionForm.errors.privacy)}
                  helperText={collectionForm.touched.privacy && collectionForm.errors.privacy}
                >
                   {getPrivacyOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <div className="submit-button">
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenVideoModal}
                  >
                    Add Video
                  </Button> */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ cursor: "pointer" }}
                    disabled={!collectionForm.isValid || collectionForm.isSubmitting}
                  >
                    {collectionForm.isSubmitting ? <CircularProgress size={24} /> : "Save"}
                  </Button>
                </div>
              </div>

            </div>

          </form>
        </Box>
      </Modal>
      <Modal open={openVideoModal} onClose={handleCloseVideoModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "1200px",
            maxWidth: "1200px",
            bgcolor: "background.paper",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            p: 3,
          }}
        >
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={handleCloseVideoModal}
              style={{ cursor: "pointer", marginBottom: "10px" }}
            >
              Close
            </Button>
          </div>
          <LatestVideoShow onSave={handleSave} onClose={handleCloseVideoModal} />
        </Box>
      </Modal>
      <FixedBottomNavigation />

      <Footer />
    </>
  );
};

export default Collection;
