import Dashboard from "../Screens/Dashboard";
import Login from "../Screens/Login";
import Register from "../Screens/Register";
import VideoGallery from "../Screens/VideoGallery";
import VideoStreaming from "../Screens/VideoStreaming";
import LatestVideos from "../Screens/LatestVideos";
import IWCVideos from "../Screens/IWCVideos";
import SearchPage from "../Screens/SearchPage";
import IWCSocial from "../Screens/IWCSocial";
import EditVideo from "../Screens/Admin/EditVideo";
import Admin from "../Screens/Admin/";
import Notification from "../Screens/Notification";
import LiveSteam from "../Screens/LiveSteam";
import ReciverLiveStreaming from "../Screens/ReciverLiveStreaming";
import TemplateScreen from "../Screens/TemplateScreen";
import OurShows from "../Screens/OurShows";
import GetInTouch from "../Screens/GetInTouch";
import LiveChat from "../Screens/LiveChat";
import Discount from "../Screens/Discount";
import DiscountListing from "../Screens/DiscountListing";
import SavedVideos from "../Screens/SavedVideos";
import RecentlyWatched from "../Screens/RecentlyWatched";
import ForgotPassword from "../Screens/ForgotPassword";
import ResetPassword from "../Screens/ResetPassword";
import AddVideoAdmin from "../Screens/Admin/AddVideoAdmin";
import Profile from "../Screens/Profile";
import AccountOverview from "../Screens/AccountOverview";
import Collection from "../Screens/Collection";
import Watchlater from "../Screens/Watchlater"

export const Screens = {
  Dashboard,
  Login,
  Register,
  VideoGallery,
  VideoStreaming,
  LatestVideos,
  SavedVideos,
  RecentlyWatched,
  IWCVideos,
  LiveSteam,
  LiveChat,
  ReciverLiveStreaming,
  SearchPage,
  IWCSocial,
  EditVideo,
  Admin,
  Notification,
  TemplateScreen,
  OurShows,
  GetInTouch,
  Discount,
  DiscountListing,
  ForgotPassword,
  ResetPassword,
  AddVideoAdmin,
  Profile,
  AccountOverview,
  Collection,
  Watchlater
};
