import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import { videoDeleteById } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import { LocalImages } from "../../Utils/images";
import { watchVideoFetch } from "../../Features/Services/videolike";

const LatestVideoClientAccount = () => {
  const [userID, setUserID] = useState('');
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [videoDetails, setVideoDetails] = useState([]);

  const handleViewAll = () => {
    navigate("/watchlater"); 
  };

  useEffect(() => {
    const successCallback = (data) => {
      console.log("Videos fetched successfully:", data);
      setVideoDetails(data); 
      setLoading(false); 
    };

    const failureCallback = (error) => {
      console.error("Error fetching video data:", error);
      setLoading(false);
    };

    const fetchVideos = async () => {
      setLoading(true);
      await watchVideoFetch(null, successCallback, failureCallback); 
    };

    fetchVideos();
  }, []);

  const removeVideo = async (video_id) => {
    console.log("Attempting to delete video with ID:", video_id);
    if (!video_id) return;

    try {
      setLoading(true);
      const response = await videoDeleteById(video_id);
      setVideoDetails((prevVideos) =>
        prevVideos.filter((video) => video.video_id !== video_id)
      );
      setMessage("Video successfully deleted");
      console.log(`Video with ID: ${video_id} successfully deleted.`);

    } catch (error) {
      console.error("Error deleting video:", error);
    } finally {
      setLoading(false);
    }
  };

  const ActionMenu = ({ video }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick}>
        <img
                                        src={LocalImages.groupIcon}
                                        style={{
                                            width: "auto",
                                            height: "100%",
                                        }}
                                    />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              if (!isLoading) removeVideo(video.video_id); // Disable click if loading
            }}
            style={{ color: isLoading ? "gray" : "red", pointerEvents: isLoading ? "none" : "auto" }}
          >
            Remove
          </MenuItem>

        </Menu>
      </>
    );
  };
  
  const handlevideoID = (videoId) => {
    navigate(`/videoStreaming/${videoId}`);
  };
    return (
      <Container maxWidth="xl" className="watch-later-wrapper">
        <div className="latest-video-header">
          <h1 className="latest-video-title">Watch Later</h1>
          <button className="view-all-button" onClick={handleViewAll}>
            View All
          </button>
        </div>
        <Grid container spacing={2}>
          {videoDetails.length > 0 ? (
            videoDetails.slice(0, 6).map((item, index) => (
              <div className="video-listing" key={index}>
                <div className="custom-video-logo">
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={LocalImages.videoCoverImg}
                      style={{
                        width: "107px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                      alt="videoCoverImg"
                    />
                  </Box>
                </div>
                <div className="video-title">
                  <span className="video-heading" title={item?.new_title} onClick={() => handlevideoID(item.video_id)}>
                    {item?.new_title}
                  </span>
                  <span className="video-heading-view" title={item?.speaker_name}>
                    {item?.speaker_name}
                  </span>
                  <span className="video-heading-view" title={item?.views}>
                    {item?.views} views
                  </span>
                </div>
                <div className="video-action">
                <ActionMenu item={item} />
                </div>
              </div>
            ))
          ) : (
            <Typography variant="h6" align="center" sx={{ width: "100%" }}>
              No videos available
            </Typography>
          )}
        </Grid>
      </Container>
    );
    
};

export default LatestVideoClientAccount;
