import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Link,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import ReusableButton from '../../ui/ReuseableButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MainHeader from '../../Components/MainHeader';
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from '../../Components/FixedBottomNavigation';
import { profileUserData } from "../../Features/Services/profile";

const Notification = () => {
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const successCallback = (data) => {
      setProfileData(data[0]);
      setLoading(false);
    };

    const failureCallback = (error) => {
      console.error("Error fetching profile data:", error);
      setLoading(false);
    };

    const fetchProfileData = async () => {
      setLoading(true);
      profileUserData(null, successCallback, failureCallback);
    };

    fetchProfileData();
  }, []);
  const [editingNotification, setEditingNotification] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://michaelkjaco-streaming-node-app.onrender.com/api/notifications");
        setNotifications(Array.isArray(response.data) ? response.data : []);
        console.log("response show", response);
        
      } catch (error) {
        console.error('Error fetching notifications:', error);
        toast.error('Error fetching notifications: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleAddNotification = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        notification: values.notification,
        start_date: values.startDate,
        end_date: values.endDate,
        status: values.active ? 1 : 0,
      };

      const response = await axios.post("https://michaelkjaco-streaming-node-app.onrender.com/api/notifications", payload);
      console.log('Success:', response.data);
      setNotifications([...notifications, payload]);
      resetForm();
      setShowForm(false);
    } catch (error) {
      console.error('Error adding notification:', error);
      toast.error('Error adding notification: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateNotification = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        notification: values.notification,
        start_date: values.startDate,
        end_date: values.endDate,
        status: values.active ? 1 : 0,
      };

      await axios.put(
        `https://michaelkjaco-streaming-node-app.onrender.com/api/notifications/${editingNotification.id}`,
        payload
      );

      setNotifications((prev) =>
        prev.map((notification) =>
          notification.id === editingNotification.id ? { ...notification, ...payload } : notification
        )
      );
      toast.success('Notification updated successfully');
      resetForm();
      setShowForm(false);
      setEditingNotification(null);
    } catch (error) {
      console.error('Error updating notification:', error);
      toast.error('Error updating notification: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const notificationDeleteById = async (notification_id) => {
    try {
      await axios.delete(`https://michaelkjaco-streaming-node-app.onrender.com/api/notification/${notification_id}`);
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== notification_id)
      );
      toast.success('Notification deleted successfully');
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error('Error deleting notification: ' + error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const validationSchema = Yup.object().shape({
    notification: Yup.string().required('Notification is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date().required('End Date is required'),
    active: Yup.boolean().required('Active status is required'),
  });

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/admin/send-notification" },
    { label: "Videos", path: "/admin" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const rolemenuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Watch Later", path: "/watchlater" },
    { label: "My Collections", path: "/collection" },
    { label: "Profile Settings", path: "/profile" },
  ];

  return (
    <>
      <MainHeader />
      <ToastContainer />
      <Backdrop
        open={isLoading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
      <InnerHeader />
      
      <Container>
      <div className="page-url-wrapper" style={{ marginTop: "-17px" }}>
                {profileData && (
                  <ul className="ul-content">
                    {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                      <li key={item.label}>
                        <Link
                          to={item.path}
                          rel="noopener noreferrer"
                          className={`li-content ${location.pathname === item.path ? "active-link" : ""
                            }`}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                <Backdrop
                  open={isLoading}
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <CircularProgress size={"48px"} />
                </Backdrop>
      </div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <h2>Manage Notifications</h2>
          <ReusableButton
            variant="contained"
            onClick={() => {
              setEditingNotification(null);
              setShowForm(!showForm);
            }}
            text={showForm ? 'Close Form' : 'Add New'}
          />
        </Box>
        {showForm && (
          <Formik
            initialValues={{
              notification: editingNotification ? editingNotification.notification : '',
              startDate: editingNotification ? formatDate(editingNotification.start_date) : '',
              endDate: editingNotification ? formatDate(editingNotification.end_date) : '',
              active: editingNotification ? editingNotification.status === "1" : true
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              if (editingNotification) {
                handleUpdateNotification(values, actions);
              } else {
                handleAddNotification(values, actions);
              }
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form>
                <Box display="flex" alignItems="center" mb={2}>
                  <TextField
                    label="Notification"
                    name="notification"
                    value={values.notification}
                    onChange={handleChange}
                    error={touched.notification && Boolean(errors.notification)}
                    helperText={touched.notification && errors.notification}
                    sx={{ marginRight: 2, width: "400px" }}
                  />
                  <TextField
                    label="Start Date"
                    type="date"
                    name="startDate"
                    value={values.startDate}
                    onChange={handleChange}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    sx={{ marginRight: 2, width: "250px" }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    name="endDate"
                    value={values.endDate}
                    onChange={handleChange}
                    error={touched.endDate && Boolean(errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    sx={{ marginRight: 2, width: "250px" }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="active"
                        checked={values.active}
                        onChange={handleChange}
                      />
                    }
                    label="Active"
                  />
                  <ReusableButton
                    text={editingNotification ? "Update" : "Add New"}
                    type="submit"
                  />
                </Box>
              </Form>
            )}
          </Formik>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#19315c" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Notification</TableCell>
                <TableCell sx={{ color: "white" }}>Start Date</TableCell>
                <TableCell sx={{ color: "white" }}>End Date</TableCell>
                <TableCell sx={{ color: "white" }}>Active</TableCell>
                <TableCell sx={{ color: "white", textAlign: 'right', paddingRight: '35px' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{row.notification}</TableCell>
                  <TableCell>{formatDate(row.start_date)}</TableCell>
                  <TableCell>{formatDate(row.end_date)}</TableCell>
                  <TableCell>
                  {row.status === "1" ? "ACTIVE" : row.status === "0" ? "INACTIVE" : "UNKNOWN"}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        aria-label="edit"
                        sx={{ marginRight: 1 }}
                        onClick={() => {
                          setEditingNotification(row);
                          setShowForm(true);
                        }}
                      >
                        <EditIcon sx={{ color: '#FFA903' }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => notificationDeleteById(row.id)}
                      >
                        <DeleteIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <FixedBottomNavigation />
    </>
  );
};

export default Notification;
