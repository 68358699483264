import LeaveWings from "../Assets/Images/leaves_wings.png";
import MichaelJaco from "../Assets/Images/michael_jaco_about_test.png";
import Mountain from "../Assets/Images/mountain.jpg";
import logo from "../Assets/Images/logo.png";
import topHeaderImg from "../Assets/Images/home_bg.png";

// images for products

import lifeWave from "../Assets/Images/1_life_wave.png";
import intuitiveWellness from "../Assets/Images/2-intuitive-wellness.jpg";
import rootWellness from "../Assets/Images/3_root_wellness.png";
import eMFIntuitive from "../Assets/Images/4_EMF-Intuitive.jpg";

// import BgURL from "../Assets/Images/himalayas.avif";
import BgURL from "../Assets/Images/bgs/login-bg-main.png";
import EyeClose from "../Assets/Images/eyeclose.png";
import Lock from "../Assets/Images/lock.png";
import User from "../Assets/Images/user.png";
import loginBotMountain from "./../Assets/Images/bgs/login-bg-bottom.png";
import YellowBg from "../Assets/Images/bgs/yellow-bg.png";

import YoutubeLogo from "../Assets/Images/youtube_logo.png";
import RumbleImg from "./../Assets/Images/rumbleImg.png";
import CabLogo from "../Assets/Images/cab_logo.png";
import FbLogo from "../Assets/Images/fb_logo.png";
import InstaLogo from "../Assets/Images/insta_logo.png";
import TwitterLogo from "../Assets/Images/twitter_logo.png";
import TelegramLogo from "../Assets/Images/telegram_logo.png";

import discoverImg01 from "./../Assets/Images/world1.png";
import discoverImg02 from "./../Assets/Images/world2.png";
import discoverImg03 from "./../Assets/Images/world3.png";
import discoverImg04 from "./../Assets/Images/world4.png";
import discoverImg05 from "./../Assets/Images/slider_4.jpg";

import Slider1 from "./../Assets/Images/slider_1.jpg";

import tvPlus from "./../Assets/Images/tv_plus.png";
import liveTv from "./../Assets/Images/live_tv.png";
import playBtnTop from "./../Assets/Images/play_button_top.png";
import videoGalImg from "./../Assets/Images/video-img.png";
import VideoGalImg01 from "./../Assets/Images/video-collection-frame.png";
// import filterIcon from "./../Assets/Images/icon-feather-filter.svg";
import iwcIcon01 from "./../Assets/Images/iwc-streaming-icon01.png";
import iwcIcon02 from "./../Assets/Images/iwc-streaming-icon02.png";
import faqaddIcon from "./../Assets/Images/faq-plus-circle.png";
import faqremoveIcon from "./../Assets/Images/faq-remove-circle.png";
import footerLogo from "./../Assets/Images/mj-footer-logo.png";
import ourInfo01 from "./../Assets/Images/our_info_01.png";
import ourInfo02 from "./../Assets/Images/our_info_02.png";
import ourInfo03 from "./../Assets/Images/our_info_03.png";
import coverImg from "./../Assets/Images/coverImg.png";
import videoCoverImg from "./../Assets/Images/video-dummy-image.png";
import customerDummyImg from "./../Assets/Images/customer-dummy.png";
import vector from "./../Assets/Images/Vector.png"
import closeIcon from "./../Assets/Images/close_icon.png"
import groupIcon from "./../Assets/Images/group_icon.png"
import videoIconfilm from "./../Assets/Images/video_icon_film.png"
import crossVector from "./../Assets/Images/cross-vector.png"
import plusVector from "./../Assets/Images/plus-vector.png"
import plusSVG from "./../Assets/Images/plus-svg.png"
import subscription from "./../Assets/Images/subscription.png"
import playIcon from "./../Assets/Images/🦆 icon _Video_.png"
import redVector from "./../Assets/Images/red-Vector.png"
import sharealternate from "./../Assets/Images/🦆 icon _Alternate Share_.png"
import calender from "./../Assets/Images/calender.png"
import yellowVector from "./../Assets/Images/yellow-Vector.png"
import iwclogo from "./../Assets/Images/iwc-logo.svg"
import filterIcon from "./../Assets/Images/Icon feather-filter.png";
import saveVideo from "./../Assets/Images/Icon material-collections-bookmark.svg";
import collection from "./../Assets/Images/Icon awesome-photo-video.svg";
import recently from "./../Assets/Images/Icon awesome-history.svg";
import setting from "./../Assets/Images/Icon ionic-md-settings.svg";
import iconUndo from "./../Assets/Images/🦆 icon _Undo_.svg";
import iwcCollection from "./../Assets/Images/IWC_Collection.jpg";

export const LocalImages = {
  leaveWings: LeaveWings,
  michaelJaco: MichaelJaco,
  mountain: Mountain,
  logo: logo,
  iwcCollection: iwcCollection,
  vector: vector,
  closeIcon: closeIcon,
  groupIcon: groupIcon,
  crossVector: crossVector,
  topHeader: topHeaderImg,
  // filterIcon: filterIcon,
  plusVector: plusVector,
  plusSVG: plusSVG,
  iwclogo: iwclogo,
  playIcon: playIcon,
  redVector: redVector,
  sharealternate: sharealternate,
  calender: calender,
  saveVideo: saveVideo,
  collection: collection,
  iconUndo: iconUndo,
  recently: recently,
  setting: setting,
  yellowVector: yellowVector,
  subscription: subscription,
  videoCoverImg: videoCoverImg,
  videoIconfilm: videoIconfilm,
  customerDummyImg: customerDummyImg,
  lifeWave: lifeWave,
  intuitiveWellness: intuitiveWellness,
  rootWellness: rootWellness,
  eMFIntuitive: eMFIntuitive,

  bgURL: BgURL,
  eyeClose: EyeClose,
  lock: Lock,
  user: User,
  loginBottomBg: loginBotMountain,
  yellowBg: YellowBg,
  youtubeLogo: YoutubeLogo,
  rumbleImg: RumbleImg,
  cabLogo: CabLogo,
  fbLogo: FbLogo,
  instaLogo: InstaLogo,
  twitterLogo: TwitterLogo,
  telegramLogo: TelegramLogo,
  discoverImg01: discoverImg01,
  discoverImg02: discoverImg02,
  discoverImg03: discoverImg03,
  discoverImg04: discoverImg04,
  discoverImg05: discoverImg05,
  coverImg: coverImg,
  slider1: Slider1,
  tvPlus: tvPlus,
  liveTv: liveTv,
  playBtnTop: playBtnTop,
  videoGalImg: videoGalImg,
  videoGalImg01: VideoGalImg01,  
  filterIcon: filterIcon,
  iwcIcon01: iwcIcon01,
  iwcIcon02: iwcIcon02,
  faqaddIcon: faqaddIcon,
  faqremoveIcon: faqremoveIcon,
  footerLogo: footerLogo,
  ourInfo01: ourInfo01,
  ourInfo02: ourInfo02,
  ourInfo03: ourInfo03,
};
