import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  TextField,
  Dialog,
  CircularProgress,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import {
  getVideoDetailsById,
  fetchVideoByID,
  updateVideoById,
} from "../../Features/Services/Videos";
import { useVideoSpeakers } from "../../Hooks/useVideoSpeakers";
import { videoIDGenerate } from "../../Utils/videoSupport";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";



const EditVideo = () => {

  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');
  

  const {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  } = useVideoCategories();

  const {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  } = useVideoCollections();

  const {
    getSpeakersList,
    speakersList,
    isSpeakerFetching,
    speakerFetchErrorMsg,
  } = useVideoSpeakers();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getCategories();
    getCollections();
    getSpeakersList();
  }, []);

  const handleClose = () => {
    setLoading(false);
  };
  const initialValues = {
    new_title: "",
    synopsis: "",
    tags: "",
    release_date: "",
    s3_video_id: "",
    views: 0,
    cover_image: "",
    duration: "",
    category: "",
    collection: "",
    availability: "Public",
    object_url: "",
    entity_tags: "",
    is_active: 1,
    is_deleted: 0,
    title: "",
    speaker_name: [],
    created_at: "",
    updated_at: "",
    CustID: `${userID}`,
  };
  const videoForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => { },
  });

  useEffect(() => {
    if (state?.video_id !== undefined) {
      getVideoInfo(state?.video_id);
    }
  }, [state]);

   useEffect(() => {
          const storeuserID = localStorage.getItem('userInfo');
          const userId = JSON.parse(storeuserID);
          if (storeuserID) {
              setUserID(userId.id);
          }
      },);

      
  const getVideoInfo = (video_id) => {
    console.log("OPEN", video_id)
    getVideoDetailsById(video_id)
      .then((response) => {
        console.log("RESPONSE video open", response)
        const availability =
          response?.data[0]?.availability === "0"
            ? "Public"
            : response?.data[0]?.availability === "1"
              ? "Private"
              : "Subscriber";
        const speaker_name =
          response.data[0]?.speaker_name
            ?.split(",")
            ?.map((item) => item.trim()) || [];
        videoForm.setValues({
          ...response?.data[0],
          speaker_name,
          new_title: response.data[0]?.new_title || "",
          collection: response.data[0]?.collection_name || "",
          category: response.data[0]?.category_name || "",
          availability,
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = () => {
    
    const values = videoForm.values;

    // const category_id =
    //   categoryList.filter((item) => item.category_name)[0]?.category_id || "";
    // const collection_id =
    //   collectionList.filter((item) => item.collection_name)[0]?.collection_id ||
    //   "";
    // let speaker_id = [];
    // for (let sName of values.speaker_name) {
    //   speaker_id.push(
    //     speakersList.filter((item) => item.speaker_name == sName)[0]?.speaker_id
    //   );
    // }

    const category_id = categoryList.find(
      (item) => item.category_name === values.category
    )?.category_id;

    const collection_id = collectionList.find(
      (item) => item.collection_name === values.collection
    )?.collection_id;

    const speaker_ids = values.speaker_name.map((speaker) => {
      return speakersList.find((item) => item.speaker_name === speaker)
        ?.speaker_id;
    });
    const availability =
      values.availability === "Public"
        ? "0"
        : values.availability === "Private"
          ? "1"
          : "2";

    const payload = {
      CustID: `${userID}`,
      video_id: state?.video_id,
      new_title: values.new_title,
      synopsis: values.synopsis,
      tags: values.tags,
      release_date: values.release_date,
      s3_video_id: values.s3_video_id,
      views: parseInt(values.views) || 0,
      cover_image: values.cover_image,
      duration: values.duration,
      category_id,
      collection_id,
      availability,
      object_url: values.object_url,
      entity_tags: values.entity_tags,
      is_active: 1,
      is_deleted: 0,
      title: values.title,
      speaker_id: speaker_ids,
    };

    console.log(payload);

    updateVideoById(payload)
      .then((response) => {
        console.log("response => ", response);
        // alert("Updated Successfully!");
        navigate("/admin");
      })
      .catch((error) => {
        alert(error?.response?.data?.message || "Something went wrong");
        console.log("error => ", error);
      });
  };

  const handleCoverImageChange = (e) => {

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        console.log("Base64 string:", base64String);
        videoForm.setFieldValue("cover_image", base64String);

      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (state?.video_id) {
      fetchVideoByID(state.video_id)
        .then((response) => {
          console.log("Fetched video details:", response.data);
          
          // Populate the form with the fetched data
          const videoData = response.data; // Assuming the API returns an array
          console.log("videoData---", videoData);
          
          const availability =
            videoData.availability === "0"
              ? "Public"
              : videoData.availability === "1"
              ? "Private"
              : "Subscriber";
  
          const speaker_name =
            videoData.speaker_name?.split(",")?.map((item) => item.trim()) || [];
  
          videoForm.setValues({
            ...videoData,
            speaker_name,
            new_title: videoData.new_title || "",
            collection: videoData.collection_name || "",
            // category: videoData.collection_name || "",
            availability,
          });
        })
        .catch((error) => {
          console.error("Error fetching video details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state]);
  
  return (


    <>
    
    <MainHeader />

    <Container maxWidth="xl" sx={{mt: "5%"}}>


      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", my: 4, alignItems: "center", justifyContent: "center" }}>

        <Grid container alignItems="center" justifyContent="center" spacing={2}>

          <Grid item xs={12}>
            <TextField
              id="video-title"
              label="Video Title"
              variant="outlined"
              fullWidth
              value={videoForm.values.new_title}
              onChange={(e) => videoForm.setFieldValue("new_title", e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="synopsis"
              label="Synopsis"
              variant="outlined"
              fullWidth
              value={videoForm.values.synopsis}
              onChange={(e) => videoForm.setFieldValue("synopsis", e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="tags"
              label="Tags"
              variant="outlined"
              fullWidth
              value={videoForm.values.tags}
              onChange={(e) => videoForm.setFieldValue("tags", e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="object-url"
              label="Object URL"
              variant="outlined"
              fullWidth
              value={videoForm.values.object_url}
              onChange={(e) => videoForm.setFieldValue("object_url", e.target.value)}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="speaker-name-label">Speaker Name</InputLabel>
              <Select
                labelId="speaker-name-label"
                id="speaker-name"
                value={videoForm.values.speaker_name}
                multiple
                label="Speaker Name"
                onChange={(e) => videoForm.setFieldValue("speaker_name", e.target.value)}
              >
                {speakersList?.map((item) => (
                  <MenuItem key={item?.speaker_id} value={item?.speaker_name}>
                    {item?.speaker_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="availability-label">Availability</InputLabel>
              <Select
                labelId="availability-label"
                id="availability"
                value={videoForm.values.availability}
                label="Availability"
                onChange={(e) => videoForm.setFieldValue("availability", e.target.value)}
              >
                <MenuItem value={"Public"}>Public</MenuItem>
                <MenuItem value={"Private"}>Private</MenuItem>
                <MenuItem value={"Subscriber"}>Subscriber</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={videoForm.values.category}
                label="Category"
                onChange={(e) => videoForm.setFieldValue("category", e.target.value)}
              >
                {categoryList?.map((item) => (
                  <MenuItem key={item?.category_id} value={item?.category_name}>
                    {item?.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="release-date"
              label="Release Date"
              variant="outlined"
              fullWidth
              value={videoForm.values.release_date}
              onChange={(e) => videoForm.setFieldValue("release_date", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="views"
              label="Views"
              variant="outlined"
              fullWidth
              value={videoForm.values.views}
              onChange={(e) => videoForm.setFieldValue("views", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="entity-tags"
              label="Entity Tags"
              variant="outlined"
              fullWidth
              value={videoForm.values.entity_tags}
              onChange={(e) => videoForm.setFieldValue("entity_tags", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="title"
              label="Title"
              variant="outlined"
              fullWidth
              value={videoForm.values.title}
              onChange={(e) => videoForm.setFieldValue("title", e.target.value)}
            />
          </Grid>


          {/* <Grid item xs={12}>
            <TextField
              type="file"
              id="cover-image"
              label="Cover Image"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={videoForm.values.cover_image}
              onChange={(e) => videoForm.setFieldValue("cover_image", e.target.value)}
            />
          </Grid> */}


            <Grid item md={6} xs={12}>
              <TextField
                type="file"
                accept="image/*"
                id="cover-image"
                label="Cover Image"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleCoverImageChange(e)}
              />
              {/* {videoForm.values.cover_image && (
      <Typography variant="body2">
        {videoForm.values.cover_image}
      </Typography>
    )} */}
            </Grid>


            <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {videoForm.values.cover_image && (
                <img
                  src={videoForm.values.cover_image}
                  alt="Cover Preview"
                  style={{ width: '100px', height: '100px' }}
                />
                )}
              </Grid>
          
        </Grid>
        <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", mt: "32px", gap: 2 }}>
          <ReusableButton text="Go Back" variant="outlined" onClick={() => navigate("/admin")} />
          <ReusableButton text="Submit" onClick={submitForm} />
            
        </Grid>
      </Box>

      <Dialog
        open={
          isLoading ||
          isCategoryFetching ||
          isCollectionFetching ||
          isSpeakerFetching
        }
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Container>

    <FixedBottomNavigation />

    </>
  );
};

export default EditVideo;
