import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
  Checkbox,
  Alert,
} from "@mui/material";
import ReusableButton from "../../ui/ReuseableButton";
import { useNavigate } from "react-router-dom";
import { wildSearchVideos } from "../../Features/Services/Videos";
import { LocalImages } from "../../Utils/images";
import { collectionVideoUpdate } from "../../Features/Services/collectionVideo";
// import { fetchCollectionVideo } from "../../Features/Services/collectionVideo";


const AddSelectedVideo = ({ onSave, onClose, collection }) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [open, setOpen] = useState(true);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [showError, setShowError] = useState(false);
   const [userID, setUserID] = useState('');
    const [videoDetails, setVideoDetails] = useState([]);


  const searchFieldInitial = {
    collectionName: "",
    categoryName: "",
    newTitle: "",
  };
  const [searchField, setSearchField] = useState(searchFieldInitial);

  useEffect(() => {
    searchVideo();
  }, []);

  const searchVideo = () => {
    setSearching(true);
    wildSearchVideos(searchField)
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setRows([]);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const resetSearch = () => {
    setSearchField(searchFieldInitial);
    setRows([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleVideoSelect = (video) => {
    setSelectedVideos((prev) =>
      prev.includes(video)
        ? prev.filter((v) => v !== video)
        : [...prev, video]
    );
  };

  const handleSave = () => {
    if (selectedVideos.length === 0) {
      setShowError(true);
      return;
    }

    const videoIds = selectedVideos
      .map((video) => video.video_id)
      .filter((id) => id !== null); // Remove null values

    const collectionPayload = {
      id: collection.id,
      video_id: JSON.stringify(videoIds), // Prepare payload for saving
    };
    console.log("collectionPayload", collectionPayload);
    
    collectionVideoUpdate(
      collectionPayload,
      (response) => {
        onSave(selectedVideos);
        setTimeout(() => {
          onClose();
        }, 2000);
      },
      (error) => {
        console.error("Error saving video data:", error);
      }
    );
  };

  // useEffect(() => {
  //   const successCallback = (data) => {
  //     console.log("API Data:", data);
  //     if (data && Array.isArray(data)) {
  //       const user = data[0];

  //       if (user.video_id && Array.isArray(user.video_id)) {
  //         setSelectedVideos(user.video_id.map((id) => ({ video_id: id })));
  //       } else {
  //         console.warn("No video IDs found in API response.");
  //       }
  //     } else {
  //       console.warn("API response is not in the expected format.");
  //     }
  //   };

  //   const failureCallback = (err) => {
  //     console.error("Error fetching user data:", err);
  //     setError(err);
  //   };

  //   collectionVideoData({}, successCallback, failureCallback);
  // }, []);

  const handleCheckboxChange = (video) => {
    setSelectedVideos((prevSelectedVideos) => {
      if (prevSelectedVideos.some((v) => v.video_id === video.video_id)) {
        return prevSelectedVideos.filter((v) => v.video_id !== video.video_id);
      } else {
        return [...prevSelectedVideos, video];
      }
    });
  };

  const handleAddButtonClick = (row) => {
    if (selectedVideos.some((v) => v.video_id === row.video_id)) {
      setSelectedVideos(selectedVideos.filter((v) => v.video_id !== row.video_id));
    } else {
      setSelectedVideos([...selectedVideos, row]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handlevideoID = (videoId) => {
    navigate(`/videoStreaming/${videoId}`);
  };

  //  useEffect(() => {
  //       const storeuserID = localStorage.getItem('userInfo');
  //       const userId = JSON.parse(storeuserID);
        
  //       if (storeuserID) {
  //         setUserID(userId.id);
  //       }
  //       fetchCollectionVideo(userId, 
  //         (data) => {
    
  //           setVideoDetails(data);  // Set video details from the response
  //           console.log("videoDetails", videoDetails);
            
  //         },
  //         (error) => {
  //           console.error("Error fetching video details:", error);
  //         }
  //       );
  //     }, []);
  return (
    <Container maxWidth="lg">
      <div className="edit-view-wrapper">
        <div className="search-collection-wrapper">
          <span>Add Collection</span>
        </div>
        <div className="collection-close" onClick={handleClose}>
          <Box sx={{ position: "relative", marginRight: "10px" }}>
            <img
              src={LocalImages.closeIcon}
              alt="Close Icon"
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
            />
          </Box>
        </div>
      </div>


      {/* Video Table */}
      <div className="collection-list-container">
        {rows.length > 0
          ? rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <div className="collection-list-item" key={row.video_id}>
                <div className="collection-list-select-wrapper">
                  {selectedVideos.some((v) => v.video_id === row.video_id) ? (
                    <Checkbox
                      checked={selectedVideos.some((v) => v.video_id === row.video_id)}
                      onChange={() => handleCheckboxChange(row)}
                    />
                  ) : (
                    <span
                      className="add-button"
                      onClick={() => handleAddButtonClick(row)}
                    >
                      <span className="sr-no">{index + 1}</span>
                    </span>
                  )}
                </div>
                <div className="video-cover-image-main">
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={LocalImages.videoCoverImg}
                      style={{
                        width: "107px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                      alt="videoCoverImg"
                    />
                  </Box>
                </div>
                <div className="row-video-content-wrapper">
                  <span className="row-video-title" onClick={() => handlevideoID(row.video_id)}>{row.new_title}</span>
                  <span className="row-category-name">{row.category_name}</span>
                </div>
                <div className="video-views">{row.views}</div>
                <div className="video-status">

                  {selectedVideos.some((v) => v.video_id === row.video_id) ? (

                    <>
                      <span
                        className="add-button"
                        onClick={() => handleAddButtonClick(row)}
                      >
                        <Box sx={{ position: "relative", marginRight: "10px" }}>
                          <img
                            src={LocalImages.crossVector}
                            alt="cross Icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </span>
                      <span className="added-status">Added!</span>
                    </>
                  ) : (
                    <span className="add-button" onClick={() => handleAddButtonClick(row)}>
                      <Box sx={{ position: "relative", marginRight: "10px" }}>
                        <img
                          src={LocalImages.plusVector}
                          alt="plus Icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </span>
                  )}
                </div>
              </div>
            ))
          : null}
      </div>



      {/* Pagination */}
       
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      

      {/* Save Button */}
      
        
        <div className="collection-submit-button">
          <span className="selected-video-length">{selectedVideos.length} Videos Selected</span>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
     

      {/* Error Alert */}
      {showError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          At least one video must be selected to save.
        </Alert>
      )}

      {/* Loading Indicator */}
      <Backdrop
        open={isSearching}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
    </Container>
  );
};

export default AddSelectedVideo;
