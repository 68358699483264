import React, { useContext, useState, useRef, useEffect } from 'react';
import { Container, Button, Box, Typography } from '@mui/material';
import { useStreamStatus } from '../../StreamStatusContext';
import io from 'socket.io-client';
import Webcam from 'react-webcam';
import { LocalImages } from "../../Utils/images";
import { livechatdata } from '../../Features/Services/liveChat';
import { profileUserData } from '../../Features/Services/profile';
import './LiveChat.css';


const socket = io("wss://michaelkjaco-streaming-node-app.onrender.com:443", {
    transports: ["websocket", "polling"],
    secure: true
  });
  

const LiveChat = ({ room, userName }) => {
    const { isLiveEnded } = useStreamStatus();
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [username, setUsername] = useState('');
    const messagesEndRef = useRef(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const webcamRef = useRef(null);


    useEffect(() => {
        const storedUsername = localStorage.getItem('userInfo');
        const userName = JSON.parse(storedUsername);
        if (storedUsername) {
            setUsername(userName.username);
        }


        const storedMessages = localStorage.getItem('messages');
        if (storedMessages) {
            setMessages(JSON.parse(storedMessages));
        }

        socket.emit('joinRoom', room);


        const handleMessage = (message) => {
            setMessages((prevMessages) => {
                if (prevMessages.some(msg => msg.text === message.text && msg.user === message.user)) {
                    localStorage.setItem('messages', JSON.stringify(prevMessages));
                    return prevMessages;
                }
                const updatedMessages = [...prevMessages, message];
                localStorage.setItem('messages', JSON.stringify(updatedMessages));
                return updatedMessages;
            });
        };

        socket.on('message', handleMessage);

        return () => {
            socket.off('message', handleMessage);
        };
    }, [room]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const sendMessage = () => {
        if (input.trim() && username.trim()) {
            const message = { text: input, 
                user: username, 
                start_date: new Date().toLocaleString('en-US', { 
                    weekday: 'short',  
                    hour: 'numeric', 
                    minute: 'numeric',
                    hour12: true      
                  })  };
            socket.emit('message', message);
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, message];
                localStorage.setItem('messages', JSON.stringify(updatedMessages)); // Save updated messages to local storage
                return updatedMessages;
            });
            setInput('');
        }
    };

    const toggleWebcam = () => {
        setShowWebcam(!showWebcam);
    };

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
          const date = new Date(message.start_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
      
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(message);
          return acc;
        }, {});
      };
    return (
        <div className="chat-parent-container">
            <div className="chat-container">
                <div className="chat-header">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none">
                        <path d="M16.2653 2.20801V7.28701H3.56531V9.82701H16.2653V14.908L23.8843 8.48301L16.2653 2.20801Z" fill="white" />
                        <path d="M3.81732 0.114014H0.325317V16.7H3.81732V0.114014Z" fill="white" />
                    </svg>
                    <Typography variant="h5" gutterBottom className='livechat-heading'>
                        Live Chat
                    </Typography>
                </div>
                <div className="chat-body">
                    <div className="messages">
                        {messages.map((msg, index) => (
                            <div key={index} className="message">
                                <Box className="message-img" display="flex" justifyContent="center" alignItems="center">
                                    <img
                                        src={LocalImages.michaelJaco}
                                        style={{
                                            width: "26px",
                                            height: "26px",
                                            objectFit: "contain",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Box>
                                <div className="message-wrapper">
                                <div className='message-username'><strong>{msg.user}</strong></div>
                                <div className="message-date">{msg.start_date}</div>
                                </div>
                                <span className='user-message'>{msg.text}</span>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    {/* {showWebcam && (
                        <div className="webcam-container">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                className="webcam-view"
                            />
                        </div>
                    )} */}
                    <div className="chat-input">
                        {/* <div className="chat-camera" onClick={toggleWebcam}>
                            <Box sx={{ position: "relative", marginRight: "10px" }}>
                                <img
                                    src={LocalImages.vector}
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    alt="Upload Icon"
                                />
                            </Box>
                        </div> */}
                        <div className='chat-input-wrapper'>
                            <input
                                type="text"
                                placeholder="Enter your message"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                className="input-field"
                            />
                        </div>
                        
                        <div onClick={sendMessage} className="send-button">

                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                <path d="M20.0023 41C31.048 41 40.0023 31.8218 40.0023 20.5C40.0023 9.17816 31.048 0 20.0023 0C8.95662 0 0.00231934 9.17816 0.00231934 20.5C0.00231934 31.8218 8.95662 41 20.0023 41Z" fill="#18315C" />
                                <path d="M18.0735 33.4261L32.1109 14.6556L8.83697 17.428L12.4203 23.6545L26.7779 17.7346L14.4728 27.2096L18.0735 33.4261Z" fill="#FBFBFB" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveChat;