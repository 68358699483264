import axios from "axios";
import { Auth } from "../../Config/url";
import { SECRETS } from "../../Utils/secrets";

const BASE_URL = SECRETS.BASE_URL;
console.log("BASE_URL", BASE_URL);
const apiBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;

/* LiveChat API */
export const livechatdata = async (messagePayload, successCB, failureCB) => {
    console.log("messagePayload create", messagePayload);

    const data = JSON.stringify({
        video_id: messagePayload.video_id,
        CustID: messagePayload.CustID,
        message: messagePayload.message,
        status: messagePayload.status
    });
    console.log("data", data);
    
    const config = {
        method: 'post',
        url: `https://michaelkjaco-streaming-node-app.onrender.com/api/livechat`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    };

    axios.request(config)
        .then((response) => {
            console.log("Response data:", response);

            if (response.data?.error) {
                return failureCB(response.data.message);
            }
            return successCB(response.data);
        })
        .catch((error) => {
            console.error("Error during livechat:", error);
            const errorMessage = error.response?.data?.error || "An error occurred during livechat.";
            return failureCB(errorMessage);
        });
};


// export const livechatdata = async (messagePayload, successCB, failureCB) => {
//     try {
//         console.log("messagePayload create", messagePayload);

//         const data = JSON.stringify({
//             video_id: messagePayload.video_id,
//             CustID: messagePayload.CustID,
//             message: messagePayload.message,
//             status: messagePayload.status
//         });
//         console.log("data", data);

//         const config = {
//             method: 'post',

//             url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/livechat',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             data: data,
//         };

//         // Using await to wait for the response from the API
//         const response = await axios.request(config);

//         console.log("Response data:", response);

//         if (response.data?.error) {
//             return failureCB(response.data.message);
//         }

//         // If the response is successful, call successCB
//         return successCB(response.data);
//     } catch (error) {
//         console.error("Error during livechat:", error);

//         // Handle errors
//         const errorMessage = error.response?.data?.error || "An error occurred during livechat.";
//         return failureCB(errorMessage);
//     }
// };

