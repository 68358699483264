import axios from "axios";
import { Auth } from "../../Config/url";
import { SECRETS } from "../../Utils/secrets";

const BASE_URL = SECRETS.BASE_URL;
console.log("BASE_URL", BASE_URL);


export const loginUser = (payload, successCB, failureCB) => {
  console.log("payload", payload);

  const data = JSON.stringify({
    email: payload.emailOrUsername, 
    password: payload.password,
  });

console.log("data", data);

  
  const config = {
    method: 'post',
    url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/login', 
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

 
  axios.request(config)
    .then((response) => {
   
      if (response.data?.error) {
        return failureCB(response.data.message);
      }
      return successCB(response.data);
    })
    .catch((error) => {
      console.error("Error during login:", error);
      const errorMessage = error.response?.data?.error || "An error occurred during login.";
      return failureCB(errorMessage);
    });
};