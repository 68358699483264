import axios from "axios";
import { Auth } from "../../Config/url";
import { SECRETS } from "../../Utils/secrets";
import { use } from "react";


const getCollectionIDFromLocalStorage = () => {
  const storeCollectionInfo = localStorage.getItem("collectionVideoId");
  if (storeCollectionInfo) {
    const collectionInfo = JSON.parse(storeCollectionInfo);
    return collectionInfo.id;
  }

  return null; // Return null if no collection info is found
};

const getUserIDFromLocalStorage = () => {
  const storeUserInfo = localStorage.getItem("userInfo");
  if (storeUserInfo) {
    const userInfo = JSON.parse(storeUserInfo);
    console.log("userInfo", userInfo);
    
    return userInfo.id;
  }

  return null; // Return null if no user info is found
};

const userID = getUserIDFromLocalStorage();
console.log("userID", userID);



/* Collection Video API */
export const collectionVideo = async (collectionPayload, successCB, failureCB) => {
  console.log("collectionPayload create", collectionPayload);

  const data = JSON.stringify({
    title: collectionPayload.title,
    des: collectionPayload.des,
    privacy: collectionPayload.privacy,
    image: collectionPayload.image,
    video_id: collectionPayload.video_id,
    CustID: collectionPayload.CustID,
    favorite: collectionPayload.favorite,

  });
console.log("data", data);

  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data,
  };

  try {
    const response = await axios.request(config);

    console.log("RRRRR", response.data.collection_video);
    console.log(JSON.stringify(response.data));

    const videoId = response.data.collection_video;
    localStorage.setItem('collectionVideoId', JSON.stringify(videoId));
    if (successCB) successCB(response.data);
  } catch (error) {
    console.error("Error uploading collection video:", error);
    if (failureCB) failureCB(error);
  }
};


/* Save Video API */
export const saveVideo = async (payload, successCB, failureCB) => {
  const data = JSON.stringify({
    video_id: payload.video_id,
  });
  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/save-video`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };


  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (successCB) successCB(response.data);
    })
    .catch((error) => {
      console.error("upload error:", error);
      if (failureCB) failureCB(error);
    });
};


/* Collection List API */
export const collectionlistVideo = async () => {
  const userID = getUserIDFromLocalStorage();
  const config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-customer-id/${userID}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Fetched data:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching video data:", error);
    return [];
  }
};


/* Collection Data Show API */

export const collectionVideoData = async (collection, successCB, failureCB) => {
  console.log("collectionID---", collection);
  let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video/${collection}`,
      headers: {},
      data: collection
  };

 
  
  axios
      .request(config)
      .then((response) => {
          console.log('API Response create:', response.data); 
          if (response.data) {
              successCB(response.data);
          } else {
              failureCB('No data returned from the API');
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error); 
          failureCB(error); 
      });
};

/* Collection Video Update API */
export const collectionVideoUpdate = async (collectionPayload, successCB, failureCB, id) => {
  
  const data = JSON.stringify({
    id: collectionPayload.id,
    title: collectionPayload.title,
    des: collectionPayload.des,
    privacy: collectionPayload.privacy,
    image: collectionPayload.image,
    video_id: collectionPayload.video_id,
  });

  const config = {
    method: 'PUT',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video/${collectionPayload.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    if (successCB) successCB(response.data);
  } catch (error) {
    console.error("Error uploading collection video:", error);
    if (failureCB) failureCB(error);
  }
};

/* Delete Collection Video API */

// export const collectionDelete = async (collectionID) => {
//   let data = '';

// let config = {
//   method: 'DELETE',
//   maxBodyLength: Infinity,
//   url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collections/${collectionID}`,
//   headers: { },
//   data : data
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });
// }

export const collectionDelete = async (collectionID) => {
  try {
    const response = await fetch(`https://michaelkjaco-streaming-node-app.onrender.com/api/collections/${collectionID}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });

    const data = await response.json();
    return { status: response.status, ...data }; 
  } catch (error) {
    console.error("Error deleting collection:", error);
    throw error;
  }
};

/* Fetch video By Customer ID */
export const fetchCollectionVideo = async (userId, successCB, failureCB) => {
  console.log("TTTR user", userId.id);
  
  const config = {
    method: "GET",
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video-customer/${userId.id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("response open", response.data)
    if (successCB) successCB(response.data);
  } catch (error) {
    console.error("Error fetching collection video:", error);
    if (failureCB) failureCB(error);
  }
};
