import React, { useEffect, useState } from "react";
import {
    Container,
    Box,
    TextField,
    Dialog,
    CircularProgress,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Button,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import { addVideo } from "../../Features/Services/Videos";
import { livechatdata } from "../../Features/Services/liveChat";
import { useVideoSpeakers } from "../../Hooks/useVideoSpeakers";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours(); // No padding for hours
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}.${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const currentDate = new Date();
const formattedDateTime = formatDateTime(currentDate);
const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Key: `Live Stream Recording ${formattedDateTime}.webm`,
    ContentType: 'video/webm',
  };
  

const encodedKey = encodeURIComponent(params.Key).replace(/%20/g, '+');
  
const s3Url = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${encodedKey}`;
const s3_video_id = `s3://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/Live Stream Recording ${formattedDateTime}.webm`;


const AddFormComponent = () => {
    const { state, pathname } = useLocation();
    const [userid, setUserid] = useState('');
    const [videoid, setVideoid] = useState('');
    const [messagejson, setMessageJSON] = useState([]);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    
    const {
        getCategories,
        categoryList,
        isCategoryFetching,
        categoryFetchErrorMsg,
    } = useVideoCategories();

    const {
        getCollections,
        collectionList,
        isCollectionFetching,
        collectionFetchErrorMsg,
    } = useVideoCollections();

    const {
        getSpeakersList,
        speakersList,
        isSpeakerFetching,
        speakerFetchErrorMsg,
    } = useVideoSpeakers();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
    const storedUsername = localStorage.getItem('userInfo');
    const liveMessage = localStorage.getItem('messages');
    const livemessagejson = JSON.parse(liveMessage);
    setMessageJSON(livemessagejson);
    const userID = JSON.parse(storedUsername);
    if (storedUsername) {
        setUserid(userID.id);
    }
    const videoid = `s3://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/Live Stream Recording ${formattedDateTime}.webm`;
    setVideoid(videoid);
    }, [])
    useEffect(() => {
        getCategories();
        getCollections();
        getSpeakersList();
    }, []);

    const handleClose = () => {
        setLoading(false);
    };
   
    const initialValues = {
        new_title: "",
        synopsis: "",
        tags: "",
        release_date: `${formattedDateTime}`,
        s3_video_id: `${s3_video_id}`,
        views: "",
        cover_image: "",
        duration: "",
        category: "",
        collection: "",
        availability: "Public",
        object_url: `${s3Url}`,
        entity_tags: "",
        is_active: 1,
        is_deleted: 0,
        title: `Live Stream Recording ${formattedDateTime}`,
        speaker_name: [],
        CustID: `${userid}`,
    };
    const submitForm = async () => {
        const values = videoForm.values;

        const category_id =
        categoryList.filter((item) => item.category_name === values.category)[0]?.category_id || "";
        const collection_id =
            collectionList.filter((item) => item.collection_name)[0]?.collection_id ||
            "";
        
        let speaker_id = [];
        for (let sName of values.speaker_name) {
            speaker_id.push(
                speakersList.filter((item) => item.speaker_name == sName)[0]?.speaker_id
            );
        }

        const availability = values.availability === "Public" ? "0" :
            values.availability === "Private" ? "1" : "2";

            const messagePayload = {
                video_id: videoid,
                CustID: userid,
                message: messagejson,
                status: true
            }
            console.log("messagePayload", messagePayload);
    
        
        const payload = {
            CustID: `${userid}`,
            new_title: values.new_title,
            synopsis: values.synopsis,
            tags: values.tags,
            release_date: `${formattedDateTime}`,
            object_url: values.object_url || `${s3Url}`,
            s3_video_id: `${s3_video_id}`,
            views: parseInt(values.views) || 0,
            cover_image: values.cover_image,
            duration: values.duration || "00:00",
            speaker_name: values.speaker_name,
            category_id,
            collection_id,
            availability,
            entity_tags: values.entity_tags,
            is_active: 1,
            is_deleted: 0,
            title: values.title || `Live Stream Recording ${formattedDateTime}`,
            speaker_id,
        };
       
       console.log("payload", payload);
       
        try {
            const response = await addVideo(payload); 
            console.log("Video added successfully:", response);
            const messageResponse = await livechatdata(messagePayload);
            localStorage.removeItem('messages');
            console.log("messageResponse", messageResponse);
            
            setMessage("Add Video Successfully!");
            videoForm.resetForm();
            navigate("/admin"); // Navigate after a successful response
          } catch (error) {
            setMessage("Something went wrong!");
            console.error(error.message); // Log the error message
          }
    };

    const videoForm = useFormik({
        initialValues,
        onSubmit: submitForm,
    });
    const handleCoverImageChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                console.log("Base64 string:", base64String);
                videoForm.setFieldValue("cover_image", base64String);

            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: "5%" }}>
                <Box sx={{ display: "flex", flex: 1, flexDirection: "column", my: 4, alignItems: "center", justifyContent: "center" }}>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="video-title"
                                label="Video Title"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.new_title}
                                onChange={(e) => videoForm.setFieldValue("new_title", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="synopsis"
                                label="Synopsis"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.synopsis}
                                onChange={(e) => videoForm.setFieldValue("synopsis", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="tags"
                                label="Tags"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.tags}
                                onChange={(e) => videoForm.setFieldValue("tags", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="object-url"
                                label="Object URL"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.object_url}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="speaker-name-label">Speaker Name</InputLabel>
                                <Select
                                    labelId="speaker-name-label"
                                    id="speaker-name"
                                    value={videoForm.values.speaker_name}
                                    multiple
                                    label="Speaker Name"
                                    onChange={(e) => videoForm.setFieldValue("speaker_name", e.target.value)}
                                >
                                    {speakersList?.map((item) => (
                                        <MenuItem key={item?.speaker_id} value={item?.speaker_name}>
                                            {item?.speaker_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="availability-label">Availability</InputLabel>
                                <Select
                                    labelId="availability-label"
                                    id="availability"
                                    value={videoForm.values.availability}
                                    label="Availability"
                                    onChange={(e) => videoForm.setFieldValue("availability", e.target.value)}
                                >
                                    <MenuItem value={"Public"}>Public</MenuItem>
                                    <MenuItem value={"Private"}>Private</MenuItem>
                                    <MenuItem value={"Subscriber"}>Subscriber</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category"
                                    value={videoForm.values.category}
                                    label="Category"
                                    onChange={(e) => videoForm.setFieldValue("category", e.target.value)}
                                >
                                    {categoryList?.map((item) => (
                                        <MenuItem key={item?.category_id} value={item?.category_name}>
                                            {item?.category_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="release-date"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.release_date}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="views"
                                label="Views"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.views}
                                onChange={(e) => videoForm.setFieldValue("views", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="entity-tags"
                                label="Entity Tags"
                                variant="outlined"
                                fullWidth
                                value={videoForm.values.entity_tags}
                                onChange={(e) => videoForm.setFieldValue("entity_tags", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="title"
                                label="Title"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                                fullWidth
                                value={videoForm.values.title}
                            />
                        </Grid>


                        <Grid item md={6} xs={12}>
                            <TextField
                                type="file"
                                accept="image/*"
                                id="cover-image"
                                label="Cover Image"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => handleCoverImageChange(e)}
                            />

                        </Grid>


                        <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {videoForm.values.cover_image && (
                                <img
                                    src={videoForm.values.cover_image}
                                    alt="Cover Preview"
                                    style={{ width: '100px', height: '100px' }}
                                />
                            )}
                        </Grid>


                        <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", mt: "32px", gap: 2 }}>
                            <ReusableButton text="Submit" onClick={videoForm.handleSubmit} disabled={isLoading} />
                        </Grid>

                    </Grid>
                    {message && (
                        <Typography color={message.includes("Successfully") ? "green" : "red"} style={{ marginTop: "1rem", textAlign: "center" }}>
                            {message}
                        </Typography>
                    )}
                </Box>
            </Container>
            <FixedBottomNavigation />
        </>
    );
};

export default AddFormComponent;
