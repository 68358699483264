// import React, { useContext, useState, useEffect } from "react";
// import { Container, Box, Grid, Dialog, CircularProgress } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Manipulation, Pagination } from "swiper/modules";

// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "./VideoGallery.css";

// import { LocalImages } from "../../Utils/images";
// import { VideoCard } from "../../Components/VideoCard/VideoCard";
// import { LiveVideo } from "../../Components/LiveVideo";
// import { AuthContext } from "../../Context/AuthContext";
// import { useVideoHooks } from "../../Hooks/useVideoHooks";
// import CustomVideoPlayer from "../../Components/CustomVideoPlayer";
// import { randomImage } from "../../Utils/videoSupport";
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
// import { SwiperVideoCard } from "../../Components/VideoCard/SwiperVideoCard";

// const VideoGallery = () => {
//   const {
//     isVideoListLoading,
//     listAllVideos,
//     videoList,
//     videoListErrorMsg,
//     getIWCVideos,
//     iwcVideoFetching,
//     getAllVideos,
//     allVideoFetching,
//   } = useVideoHooks();

//   const navigate = useNavigate();

//   const { liveStreamStatus } = useContext(AuthContext);

//   const [showSwiper, setShowSwiper] = useState(false);
//   const [videoUrl, setVideoUrl] = useState({ url: "", id: "", title: "" });
//   const [isLoading, setLoading] = useState(false);
//   const [videoOpen, setVideoOpen] = useState(false);

//   const handleClose = () => {
//     setLoading(false);
//   };

//   const handleVideoClose = () => {
//     setVideoOpen(false);
//     setVideoUrl({ id: "", url: "", title: "" });
//   };

//   useEffect(() => {
//     listAllVideos();
//   }, []);

//   const navigateToStreaming = (key) => {
//     navigate(`/videoStreaming`);
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       setShowSwiper(true);
//     }, 50);
//   }, []);

//   const onClickExclusiveWatchMore = () => {
//     const pageNo = videoList.iwc.pageNo + 1;
//     getIWCVideos(pageNo, 3);
//   };

//   const onClickAllVideosWatchMore = () => {
//     const pageNo = videoList.allVideos.pageNo + 1;
//     getAllVideos(pageNo, 3);
//   };

//   return (

//     <> 

//     <Header />

//     <Box>
//       <Box className="video-page-top">

//         <Box className="fixed-filter-content">
//           <img src={LocalImages.filterIcon} alt="" />
//         </Box>

//         {liveStreamStatus ? (
//           <LiveVideo
//             clickHandler={() => {
//               navigateToStreaming();
//             }}
//             currentTimeStamp={"01:34:45"}
//             title={"Admin Panel"}
//             description={
//               "Start saving your time with Framer Wireframe Web UI Kit."
//             }
//             img={"https://ak2.rmbl.ws/s8/6/o/z/j/2/ozj2f.qNqd.1.jpg"}
//           />
//         ) : null}

//         <Container maxWidth="lg">

//           <Grid container justifyContent="left">
//             <h1 className="font-gloucester color-black video-title01">
//               Recent Live Stream Videos
//             </h1>
//           </Grid>


//           <Grid container spacing={2}>
//       {videoList.top3.length > 0
//         ? videoList.top3.map((item, index) => (
//             <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} key={item?.id}>
//               <VideoCard
//                 description={item?.synopsis}
//                 imageUrl={item.cover_image}
//                 timeStamp={item?.duration}
//                 title={item?.new_title}
//                 s3_video_id={item?.s3_video_id}
//                 video_id={item?.video_id}
//                 release_date={item?.release_date}
//               />
//             </Grid>
//           ))
//         : null}
//     </Grid>


//         </Container>
//       </Box>

//       <Container maxWidth="lg">

//         <Grid container justifyContent="left" sx={{ pt: 5 }}>
//           <h1 className="font-gloucester color-gray video-title01">
//             IWC Exclusives
//           </h1>
//         </Grid>
//         <Grid container spacing={2} justifyContent="center" display="flex">
//           {videoList?.iwc?.list?.length > 0
//             ? videoList?.iwc?.list?.map((item, index) => (
//                 <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
//                   <VideoCard
//                     description={item?.synopsis}
//                     imageUrl={item.cover_image}
//                     timeStamp={item?.duration}
//                     title={item?.new_title}
//                     key={item?.id}
//                     s3_video_id={item?.s3_video_id}
//                     video_id={item?.video_id}
//                     release_date={item?.release_date}
//                   />
//                 </Grid>
//               ))
//             : null}
//         </Grid>
//         {videoList?.iwc?.list?.length > 0 ? (
//           <Grid>
//             <Box sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}>
//               <div className="loadmore-btn-container">
//                 <button
//                   className="loadmore-btn-inner-container"
//                   onClick={onClickExclusiveWatchMore}
//                   disabled={videoList?.iwc?.list?.length == 0}
//                 >
//                   LOAD MORE
//                 </button>
//               </div>
//             </Box>
//           </Grid>
//         ) : null}
//       </Container>


//       <Box className="video-page-center-collections">
//   <Box sx={{ textAlign: "center", pb: 5 }}>
//     <h2
//       sx={{ py: 5, my: 5 }}
//       className="font-gloucester color-gray video-title01"
//     >
//       Video Collections
//     </h2>
//   </Box>
//   <Container sx={{ pt: 5, mt: 5 }}>

//     <Box className="swiper-01">
//       {showSwiper && (
//         <Swiper
//           navigation={true}
//           modules={[Navigation, Manipulation, Pagination]}
//           initialSlide={1}
//           grabCursor={true}
//           pagination={false}
//           spaceBetween={20} 
//           breakpoints={{
//             320: {
//               slidesPerView: 1,
//             },
//             480: {
//               slidesPerView: 1,
//             },
//             640: {
//               slidesPerView: 1,
//             },
//             800: {
//               slidesPerView: 2,
//             },
//             1000: {
//               slidesPerView: 4,
//             },
//           }}
//           autoplay
//           loop
//           sx={{
//             overflow: "hidden",
//             justifyContent: "center",
//             padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
//             alignItems: "center"
//           }}
//         >
//           {videoList?.videoCollections?.length > 0
//             ? videoList?.videoCollections?.map((item, index) => (

//             <SwiperSlide
//               key={index}
//               style={{ borderRadius: 10, height: "180px", display: 'flex', alignItems: 'center' }}
//             >
//               <VideoCard
//                 videoContainer={{ height: "100%" }}
//                 description={item?.synopsis}
//                 imageUrl={item.cover_image}
//                 timeStamp={item?.duration}
//                 title={item?.new_title}
//                 imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
//                 s3_video_id={item?.s3_video_id}
//                 video_id={item?.video_id}
//                 release_date={item?.release_date}
//               />
//             </SwiperSlide>

//               ))
//             : null}
//         </Swiper>
//       )}
//     </Box>

//   </Container>
// </Box>


//       <Box className="video-page-bottom">
//         <Container maxWidth="lg">

//           <Grid container justifyContent="left" sx={{ pt: 5 }}>
//             <h1 className="font-gloucester color-gray video-title01">
//               Watch All Videos
//             </h1>
//           </Grid>

//           <Grid container spacing={2} justifyContent="center" display="flex">
//             {videoList.allVideos?.list?.length > 0
//               ? videoList.allVideos?.list?.map((item, index) => (
//                   <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
//                     <VideoCard
//                       description={item?.synopsis}
//                       imageUrl={item.cover_image}
//                       timeStamp={item?.duration}
//                       title={item?.new_title}
//                       key={item?.id}
//                       s3_video_id={item?.s3_video_id}
//                       video_id={item?.video_id}
//                       release_date={item?.release_date}
//                     />
//                   </Grid>
//                 ))
//               : null}
//           </Grid>
//           {videoList?.allVideos?.list?.length > 0 ? (
//             <Grid>
//               <Box
//                 sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}
//               >
//                 <div className="loadmore-btn-container">
//                   <button
//                     className="loadmore-btn-inner-container"
//                     onClick={onClickAllVideosWatchMore}
//                     disabled={videoList?.allVideos?.list?.length === 0}
//                   >
//                     LOAD MORE
//                   </button>
//                 </div>
//               </Box>
//             </Grid>
//           ) : null}
//         </Container>

//         <Container maxWidth="lg">
//           <Grid container justifyContent="left" sx={{ pt: 5 }}>
//             <h2 className="font-gloucester color-gray video-title01">
//               IWC Help Video Center
//             </h2>

//           </Grid>

//           <Container>
//             <Box className="swiper-01">
//               {showSwiper && (
//                 <Swiper
//                   navigation={true}
//                   modules={[Navigation, Manipulation, Pagination]}
//                   initialSlide={1}
//                   // className="mySwiper"
//                   grabCursor={true}
//                   pagination={false}
//                   spaceBetween={20}
//                   // slidesPerView={1}
//                   breakpoints={{
//                     320: {
//                       slidesPerView: 1,
//                     },
//                     480: {
//                       slidesPerView: 1,
//                     },
//                     640: {
//                       slidesPerView: 1,
//                     },
//                     800: {
//                       slidesPerView: 2,
//                     },
//                     1000: {
//                       slidesPerView: 4,
//                     },
//                   }}
//                   autoplay
//                   loop
//                   sx={{
//                     // width: "80%",
//                     overflow: "hidden",
//                     justifyContent: "center",
//                     padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
//                   }}
//                 >
//                   {videoList?.iwcVideoHelpCenter?.length > 0
//                     ? videoList?.iwcVideoHelpCenter?.map((item, index) => (
//                       <SwiperSlide
//                       key={index}
//                       style={{borderRadius: 10, height: "200px", display: 'flex', alignItems: 'center' }}
//                     >
//                       <VideoCard
//                         videoContainer={{ height: "100%" }}
//                         description={item?.synopsis}
//                         imageUrl={item.cover_image}
//                         timeStamp={item?.duration}
//                         title={item?.new_title}
//                         s3_video_id={item?.s3_video_id}
//                         video_id={item?.video_id}
//                         release_date={item?.release_date}
//                       />
//                     </SwiperSlide>
//                       ))
//                     : null}
//                 </Swiper>
//               )}
//             </Box>
//           </Container>
//         </Container>
//       </Box>

//       <Dialog
//         open={
//           isLoading ||
//           isVideoListLoading ||
//           iwcVideoFetching ||
//           allVideoFetching
//         }
//         onClose={handleClose}
//         PaperProps={{
//           style: {
//             backgroundColor: "transparent",
//             boxShadow: "none",
//             overflow: "hidden",
//           },
//         }}
//       >
//         <CircularProgress />
//       </Dialog>

//       <Dialog
//         open={videoOpen}
//         onClose={handleVideoClose}
//         PaperProps={{
//           style: {
//             backgroundColor: "transparent",
//             boxShadow: "none",
//             overflow: "visible",
//           },
//         }}
//       >
//         <CustomVideoPlayer
//           handleVideoClose={handleVideoClose}
//           videoUrl={videoUrl}
//         />
//       </Dialog>
//     </Box>
//     <Footer />
//     </>
//   );
// };

// export default VideoGallery;



import React, { useContext, useState, useEffect } from "react";
import { Container, Box, Grid, Dialog, CircularProgress, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Manipulation, Pagination } from "swiper/modules";
import iwcCollection from "../../Assets/Images/IWC-collection.png"

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./VideoGallery.css";

import { LocalImages } from "../../Utils/images";
import { VideoCard } from "../../Components/VideoCard/VideoCard";
import { LiveVideo } from "../../Components/LiveVideo";
import { AuthContext } from "../../Context/AuthContext";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import CustomVideoPlayer from "../../Components/CustomVideoPlayer";
import { randomImage } from "../../Utils/videoSupport";
import Footer from "../../Components/Footer";
import { SwiperVideoCard } from "../../Components/VideoCard/SwiperVideoCard";
import FilterModal from "./FilterModal";
import MainHeader from "../../Components/MainHeader";
import moment from 'moment';
import CollectionList from "../Collection/collectionlist";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
const VideoGallery = () => {
  const {
    isVideoListLoading,
    listAllVideos,
    videoList,
    videoListErrorMsg,
    getIWCVideos,
    iwcVideoFetching,
    getAllVideos,
    allVideoFetching,
  } = useVideoHooks();

  const navigate = useNavigate();

  const { liveStreamStatus } = useContext(AuthContext);

  const [showSwiper, setShowSwiper] = useState(false);
  const [videoUrl, setVideoUrl] = useState({ url: "", id: "", title: "" });
  const [isLoading, setLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleClose = () => {
    setLoading(false);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
    setVideoUrl({ id: "", url: "", title: "" });
  };

  useEffect(() => {
    listAllVideos();
  }, []);

  const navigateToStreaming = (key) => {
    navigate(`/videoStreaming`);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSwiper(true);
    }, 50);
  }, []);



  const onClickExclusiveWatchMore = () => {
    const pageNo = videoList.iwc.pageNo + 1;
    getIWCVideos(pageNo, 4);
  };

  const onClickAllVideosWatchMore = () => {
    const pageNo = videoList.allVideos.pageNo + 1;
    getAllVideos(pageNo, 3);
  };

  return (
    <>
      <div className="sticky-header">
        <MainHeader />
      </div>

      <Box>
        <Box >
          <Box className="top-layout-header-bg" sx={{ minHeight: "180px", pt: "250px", borderRadius: "0px 0px 16px 8px", }}>
            <Box className="top-layout-header-bg-curve" sx={{ minHeight: "180px" }}></Box>
          </Box>

          <Box className="fixed-filter-content" sx={{ top: '580px', marginRight: '-25px' }}>
            <img src={LocalImages.filterIcon} alt="Filter Icon" onClick={handleOpen} style={{ height: '30px', marginTop: '17px', cursor: 'pointer', marginRight: '5px' }} />
          </Box>
          <FilterModal open={open} onClose={handleCloseModal} />

          {liveStreamStatus ? (
            <LiveVideo
              clickHandler={() => {
                navigateToStreaming();
              }}
              currentTimeStamp={"01:34:45"}
              title={"Admin Panel"}
              description={
                "Start saving your time with Framer Wireframe Web UI Kit."
              }
              img={"https://ak2.rmbl.ws/s8/6/o/z/j/2/ozj2f.qNqd.1.jpg"}
            />
          ) : null}

          <Container maxWidth="lg" sx={{ top: '-350px', position: "relative" }}>
            <Grid container justifyContent="left" >
              <h1 className="font-gloucester color-white video-title01" style={{ marginBottom: '-20px' }}>
                Recent Live Stream Videos
              </h1>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '0px' }}>
              {videoList.top3.length > 0
                ? videoList.top3.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      my: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={item?.id}
                  >
                    <VideoCard
                      description={item?.synopsis}
                      imageUrl={item.cover_image}
                      timeStamp={item?.duration}
                      title={item?.new_title}
                      s3_video_id={item?.s3_video_id}
                      video_id={item?.video_id}
                      release_date={item?.release_date}
                    />
                  </Grid>
                ))
                : null}
            </Grid>
          </Container>
        </Box>


        <Container maxWidth="lg" sx={{ top: '-180px', position: "relative" }}>
          <Grid container justifyContent="left">
            <h1 className="font-gloucester color-gray video-title01">
            Michaels Favorite Videos
            </h1>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            {videoList?.iwc?.list?.length > 0
              ? videoList?.iwc?.list?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  mt={index >= 4 ? "16px" : "0px"}
                  key={item?.id}
                >
                  <VideoCard
                    description={item?.synopsis}
                    imageUrl={item.cover_image}
                    timeStamp={item?.duration}
                    title={item?.new_title}
                    s3_video_id={item?.s3_video_id}
                    video_id={item?.video_id}
                    release_date={item?.release_date}
                  />
                </Grid>
              ))
              : null}
          </Grid>
          {videoList?.iwc?.list?.length > 0 ? (
            <Grid>
              <Box sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}>
                <div className="loadmore-btn-container">
                  <button
                    className="loadmore-btn-inner-container"
                    onClick={onClickExclusiveWatchMore}
                    disabled={videoList?.iwc?.list?.length == 0}
                  >
                    LOAD MORE
                  </button>
                </div>
              </Box>
            </Grid>
          ) : null}
        </Container>


        <Box className="video-page-center-collections">
          <Box sx={{ textAlign: "center", pb: 5 }}>
            <h2
              sx={{ py: 5, my: 5 }}
              className="font-gloucester color-gray video-title01"
            >
              Video Collections
            </h2>
          </Box>
          <CollectionList />
        </Box>

        <Box className="video-page-bottom">
          <Container maxWidth="lg">
            <Grid container justifyContent="left" sx={{ pt: 5 }}>
              <h1 className="font-gloucester color-gray video-title01">
                Watch All Videos
              </h1>
            </Grid>

            <Grid container spacing={2} justifyContent="center" display="flex">
              {videoList.allVideos?.list?.length > 0
                ? videoList.allVideos?.list?.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      my: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={index > 2 ? "16px" : "0px"}
                  >
                    <VideoCard
                      description={item?.synopsis}
                      imageUrl={item.cover_image}
                      timeStamp={item?.duration}
                      title={item?.new_title}
                      key={item?.id}
                      s3_video_id={item?.s3_video_id}
                      video_id={item?.video_id}
                      release_date={item?.release_date}
                    />
                  </Grid>
                ))
                : null}
            </Grid>
            {videoList?.allVideos?.list?.length > 0 ? (
              <Grid>
                <Box
                  sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}
                >
                  <div className="loadmore-btn-container">
                    <button
                      className="loadmore-btn-inner-container"
                      onClick={onClickAllVideosWatchMore}
                      disabled={videoList?.allVideos?.list?.length === 0}
                    >
                      LOAD MORE
                    </button>
                  </div>
                </Box>
              </Grid>
            ) : null}
          </Container>
          {/* IWC HELP CENTER SECTION IS COMMENTED OUT HERE */}
          {/* <Container maxWidth="lg">
            <Grid container justifyContent="left" sx={{ pt: 5 }}>
              <h2 className="font-gloucester color-gray video-title01">
                IWC Help Video Center
              </h2>
            </Grid>

            <Container>
              <Box className="swiper-01">
                {showSwiper && (
                  <Swiper
                    navigation={true}
                    modules={[Navigation, Manipulation, Pagination]}
                    initialSlide={1}
                    grabCursor={true}
                    pagination={false}
                    spaceBetween={20}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      480: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 1,
                      },
                      800: {
                        slidesPerView: 2,
                      },
                      1000: {
                        slidesPerView: 4,
                      },
                    }}
                    autoplay
                    loop
                    sx={{
                      overflow: "hidden",
                      justifyContent: "center",
                      padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
                    }}
                  >
                    {videoList?.iwcVideoHelpCenter?.length > 0
                      ? videoList?.iwcVideoHelpCenter?.map((item, index) => (
                          <SwiperSlide
                            key={index}
                            style={{
                              borderRadius: 10,
                              height: "200px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <VideoCard
                              videoContainer={{ height: "100%" }}
                              description={item?.synopsis}
                              imageUrl={item.cover_image}
                              timeStamp={item?.duration}
                              title={item?.new_title}
                              s3_video_id={item?.s3_video_id}
                              video_id={item?.video_id}
                              release_date={item?.release_date}
                            />
                          </SwiperSlide>
                        ))
                      : null}
                  </Swiper>
                )}
              </Box>
            </Container>
          </Container> */}
        </Box>

        <Dialog
          open={
            isLoading ||
            isVideoListLoading ||
            iwcVideoFetching ||
            allVideoFetching
          }
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
        >
          <CircularProgress />
        </Dialog>

        <Dialog
          open={videoOpen}
          onClose={handleVideoClose}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "visible",
            },
          }}
        >
          <CustomVideoPlayer
            handleVideoClose={handleVideoClose}
            videoUrl={videoUrl}
          />
        </Dialog>
      </Box>
      <FixedBottomNavigation />
      <Footer />
    </>
  );
};

export default VideoGallery;
