import React, { useEffect, useState } from "react";
import {
  Container,
  CircularProgress,
  Backdrop,
  Typography,
  CardMedia,
  Box,
  Menu,
  MenuItem,
  Dialog,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { LocalImages } from "../../Utils/images";
import { collectionlistVideo, collectionDelete } from "../../Features/Services/collectionVideo";
import EditCollection from "./EditCollection";
import CollectionView from "./collectionView";
import AddSelectedVideo from "./AddSelectedVideo";

const CollectionList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userID, setUserID] = useState('');
  const [videos, setVideos] = useState([]);
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedVideoContent, setSelectedVideoContent] = useState(null);
  const [isListView, setIsListView] = useState(true);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const storeuserID = localStorage.getItem('userInfo');
    if (storeuserID) {
      const userId = JSON.parse(storeuserID);
      setUserID(userId.id);
    }

    const fetchVideos = async () => {
      setIsLoading(true);
      try {
        const data = await collectionlistVideo(userID);
        setVideos(data);
      } catch (error) {
        console.error("Error fetching video data:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch video data.",
          severity: "error",
        });
        setVideos([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (userID) {
      fetchVideos();
    }
  }, [userID]);

  const handlePopupOpen = (event, video) => {
    setPopupAnchor(event.currentTarget);
    setSelectedVideo(video);
  };

  const handlePopupClose = () => {
    setPopupAnchor(null);
  };

  const handleEditClick = () => {
    setIsEditOpen(true);
    handlePopupClose();
  };

  const handleDeleteClick = async (collectionID) => {
    if (!collectionID) return;

    try {
      setIsLoading(true);
      const response = await collectionDelete(collectionID);
      setSnackbar({
        open: true,
        message: response.message || "Collection deleted successfully.",
        severity: response.status === 200 ? "success" : "error",
      });

      if (response.status === 200) {
        setVideos((prevVideos) => prevVideos.filter((video) => video.id !== collectionID));
      }

      handlePopupClose();
    } catch (error) {
      console.error("Error deleting collection:", error);
      setSnackbar({
        open: true,
        message: "An unexpected error occurred while deleting the collection.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const handleAddClick = () => {
    setIsAddOpen(true);
    handlePopupClose();
  };

  const handleAddClose = () => {
    setIsAddOpen(false);
  };

  const handleSave = (selectedVideos) => {
    console.log("Selected videos:", selectedVideos);
    setSnackbar({
      open: true,
      message: "Videos added successfully.",
      severity: "success",
    });
  };

  const handleCollectionClick = (video) => {
    setSelectedVideoContent(video);
    setIsListView(false);
  };

  const handleBackToList = () => {
    setIsListView(true);
    setSelectedVideoContent(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
      {isListView ? (
        <div className="collection-list-show-main">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div className="collection-list-card" key={video.id}>
                <div
                  className="clickable-content"
                  onClick={() => handleCollectionClick(video)}
                  style={{ cursor: "pointer" }}
                >
                  <CardMedia
                    component="img"
                    src={
                      video.image
                        ? `data:image/png;base64,${video.image}`
                        : LocalImages.iwcCollection
                    }
                    style={{
                      width: "271px",
                      height: "482px",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    alt={video.title}
                  />
                </div>
                <div className="group-icon-wrapper">
                  <div className="collection-title">{video.title}</div>
                  <div className="group-icon">
                    <Box sx={{ position: "relative", marginRight: "10px" }}>
                      <img
                        src={LocalImages.groupIcon}
                        style={{
                          width: "auto",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        alt="Upload Icon"
                        onClick={(event) => handlePopupOpen(event, video)}
                      />
                    </Box>
                  </div>
                </div>
                <div className="video-count-show">
                  {video.video_id && Array.isArray(video.video_id)
                    ? video.video_id.length
                    : 0}{" "}
                  videos
                </div>
              </div>
            ))
          ) : (
            <Typography variant="h6" align="center" sx={{ width: "100%" }}>
              No Collections available
            </Typography>
          )}
        </div>
      ) : (
        <div className="collection-detail-view">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleBackToList}
          >
            <Box sx={{ position: "relative" }}>
              <img
                src={LocalImages.iconUndo}
                style={{
                  width: "18px",
                  height: "18px",
                  objectFit: "cover",
                }}
                alt="Back Icon"
              />
            </Box>
            <span className="back-text">Back to Collection List</span>
          </Box>
          {selectedVideoContent ? (
            <CollectionView collection={selectedVideoContent} />
          ) : (
            <Typography variant="h6" align="center" sx={{ width: "100%" }}>
              Select a video to view details
            </Typography>
          )}
        </div>
      )}

      {/* Popup Menu */}
      <Menu
        anchorEl={popupAnchor}
        open={Boolean(popupAnchor)}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleAddClick}>Add Video</MenuItem>
        <MenuItem onClick={() => handleDeleteClick(selectedVideo.id)}>Remove</MenuItem>
      </Menu>

      {/* Add Video Dialog */}
      <Dialog open={isAddOpen} onClose={handleAddClose} fullWidth maxWidth="sm" className="add-selected-video">
        <AddSelectedVideo collection={selectedVideo} onSave={handleSave} onClose={handleAddClose} />
      </Dialog>

      {/* Edit Collection Dialog */}
      <Dialog open={isEditOpen} onClose={handleEditClose} fullWidth maxWidth="sm">
        <EditCollection collection={selectedVideo} onClose={handleEditClose} />
      </Dialog>

      {/* Loading Indicator */}
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CollectionList;
