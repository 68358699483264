import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  Snackbar
} from "@mui/material";
import MainHeader from "../../Components/MainHeader";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import ShareIcon from "@mui/icons-material/Share";
import { Swiper, SwiperSlide } from "swiper/react";
import AddLiveChat from "./addLivechat";
import { Navigation, Manipulation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../VideoGallery/VideoGallery.css";

import { LocalImages } from "../../Utils/images";
import Header from "../VideoGallery/Header";
import { useLocation } from "react-router-dom";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import { SwiperVideoCard } from "../../Components/VideoCard/SwiperVideoCard";
import { generateVideoUrl, randomImage } from "../../Utils/videoSupport";
import { isValidString } from "../../Utils/validators";
import axios from "axios";
import Footer from "../../Components/Footer";
import moment from "moment/moment";
import LoginHeader from "../../Components/LoginHeader";
import { useStreamStatus } from "../../StreamStatusContext";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { videolike } from "../../Features/Services/videolike";
import { profileUserData } from "../../Features/Services/profile";

const VideoStreaming = () => {
  const { state, pathname } = useLocation();
  const [username, setUsername] = useState('');
  const [userid, setUserID] = useState('');
  const [message, setMessage] = useState('');
  const [videoLike, setvideoLike] = useState([]);
  const [videolikeCount, setvideoLikeCount] = useState(0);
  const [videoLikeStatus, setVideoLikeStatus] = useState(false); 
  const [videoSaveStatus, setVideoSaveStatus] = useState(false);
  const [videoviews, setVideoView] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const pageHandle = pathSegments[1];
  const [copied, setCopied] = useState(false);
  

  console.log("video_id in videoStreaming--", state?.id);

  const videoURL = process.env.REACT_APP_BASE_URL;
  const pageTitle = pageHandle;
  const videoshareid = state?.id;

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      setUsername(userInfo.username); 
      setUserID(userInfo.id);
    }
  }, []);
  const {
    getLatestVideos,
    latestVideos,
    latestVideoFetching,
    latestVideoFetchError,
  } = useVideoHooks();

  const { isStreaming, startStreaming, stopStreaming, isLiveEnded } =
    useStreamStatus();

  // const [showSwiper, setShowSwiper] = useState(false);
  // const [videoInfo, setVideoInfo] = useState({
  //   id: "",
  //   title: "",
  //   url: "",
  //   release_date: "",
  //   description: "",
  // });
  // const [isLoading, setLoading] = useState(true);

  // const showLive = false;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getLatestVideos(1, 9);
  // }, []);

  // useEffect(() => {
  //   setVideoInfo(state);
  //   setLoading(false);
  //   setTimeout(() => {
  //     setShowSwiper(true);
  //   }, 50);
  // }, [state]);

  // const generateUrl = (item) => {
  //   if (isValidString(item?.s3_video_id)) {
  //     setLoading(true);
  //     generateVideoUrl(item?.s3_video_id)
  //       .then((url) => {
  //         setVideoInfo({
  //           id: item?.video_id,
  //           title: item?.new_title,
  //           url,
  //           release_date: item?.release_date,
  //           description: item?.synopsis,
  //         });
  //       })
  //       .catch((error) => {
  //         console.log("error => ", error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   } else {
  //     setLoading(false);
  //     alert("Not a valid video");
  //   }
  // };

  const [showSwiper, setShowSwiper] = useState(false);

  const [videoInfo, setVideoInfo] = useState({
    id: "",
    title: "",
    url: "",
    release_date: "",
    description: "",
  });

  const [isLoading, setLoading] = useState(true);

  const showLive = false;

  useEffect(() => {
    getLatestVideos(1, 9);
  }, []);

  useEffect(() => {
    if (state?.id) {
      setVideoInfo(state);
      setLoading(false);
      setTimeout(() => {
        setShowSwiper(true);
      }, 50);
    } else {
      // If state.id is empty, extract the video ID from the URL and call the API
      const videoIdFromUrl = pathname.split("/").pop();
      if (videoIdFromUrl) {
        fetchVideoDetails(videoIdFromUrl);
        setShowSwiper(true);
      } else {
        setLoading(false);
        console.log("Invalid video URL");
      }
    }
  }, [state, pathname]);

  const fetchVideoDetails = (videoId) => {
    console.log("videoId--", videoId);
    setLoading(true);
    fetch(
      `https://michaelkjaco-streaming-node-app.onrender.com/api/video/getVideoDetailsById?video_id=${videoId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && data.data.length > 0) {
          console.log("data.videoDetails--", data.data[0]);
          generateUrl(data.data[0]);
        } else {
          setLoading(false);
          console.log("Failed to fetch video details");
        }
      })
      .catch((error) => {
        console.error("Error fetching video details:", error);
        setLoading(false);
      });
  };

  const generateUrl = (item) => {
    
    if (isValidString(item?.s3_video_id)) {
      setLoading(true);
      generateVideoUrl(item?.s3_video_id)
        .then((url) => {
          setVideoInfo({
            id: item?.video_id,
            title: item?.new_title,
            url,
            release_date: item?.release_date,
            description: item?.synopsis,
          });
        })
        .catch((error) => {
          console.log("error => ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      console.log("Not a valid video");
    }
  };

  const handleRecentlyWatchedVideo = () => {
    console.log("recently watched video ---", videoInfo);
    axios
      .post("YOUR_API_ENDPOINT", videoInfo)
      .then((response) => {
        console.log("Video saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving video:", error);
      });
  };

  const handleSavedVideo = () => {
    console.log("saved video ---", videoInfo);
    axios
      .post("YOUR_API_ENDPOINT", videoInfo)
      .then((response) => {
        console.log("Video saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving video:", error);
      });
  };

  console.log("latestVideos---", latestVideos?.list);

/* Fetch Video Like Detail */
const videoID = state?.id;
const fetchVideolike = (successCB, failureCB) => { 
  setLoading(true);
  axios
    .get(`https://michaelkjaco-streaming-node-app.onrender.com/api/video-likes/${videoID}`)
    .then((response) => {
      console.log("API Response:", response.data);
      if (response.data && response.data.length > 0) {
        successCB(response.data);
      } else {
        failureCB("No data returned from the API");
      }
    })
    .catch((error) => {
      console.error("Error fetching video likes:", error);
      failureCB(error);
    })
    .finally(() => {
      setLoading(false);
    });
};

useEffect(() => {
  const successCallback = (data) => {
    console.log("data like", data);
    
    setvideoLike(data[0]); 
    setLoading(false);
  };

  const failureCallback = (error) => {
    console.error("Error in fetching video likes:", error); 
    setLoading(false); 
  };

  if (videoID) {
    fetchVideolike(successCallback, failureCallback); 
  }
}, [videoID]);

const handleVideoLike = async () => {
  try {
    const videolikePayload = {
      video_id: videoID,
      user_id: `${userid}`,
      like_status: !videoLikeStatus, 
      save_video: videoSaveStatus, 
    };

    await videolike(
      videolikePayload,
      () => {
        setMessage('Video liked successfully!');
        setVideoLikeStatus(!videoLikeStatus);
      },
      () => setMessage('Error liking the video!')
    );
  } catch (error) {
    console.error(error);
    setMessage('Error liking the video!');
  }
};
console.log("videoLikeStatus", videoLikeStatus);


const handleSaveVideo = async () => {
  try {
    const videolikePayload = {
      video_id: videoID,
      user_id: `${userid}`,
      like_status: videoLikeStatus,  
      save_video: !videoSaveStatus, 
    };

    await videolike(
      videolikePayload,
      () => {
        setMessage('Video saved successfully!');
        setVideoSaveStatus(!videoSaveStatus);
      },
      () => setMessage('Error saving the video!')
    );
  } catch (error) {
    console.error(error);
    setMessage('Error saving the video!');
  }
};

/* Fetch video count */

const fetchLikeCount = (successCB, failureCB) => { 
  setLoading(true);
  axios
    .get(`https://michaelkjaco-streaming-node-app.onrender.com/api/video-likes-count/${videoID}`)
    .then((response) => {
      console.log("API Response:", response.data);
      if (response.data) {
        successCB(response.data.like_count); // Extract like_count directly
      } else {
        failureCB("No data returned from the API");
      }
    })
    .catch((error) => {
      console.error("Error fetching video likes:", error);
      failureCB(error);
    })
    .finally(() => {
      setLoading(false);
    });
};

console.log("video count", videolikeCount);

useEffect(() => {
  const successCallback = (likeCount) => {
    console.log("data count", likeCount);
    setvideoLikeCount(likeCount); 
    setLoading(false);
  };

  const failureCallback = (error) => {
    console.error("Error in fetching video likes:", error); 
    setLoading(false); 
  };

  if (videoID) {
    fetchLikeCount(successCallback, failureCallback); 
  }
}, [videoID]);

const fetchVideoDetailsViews = (successCallback, failureCallback) => {
  setLoading(true);
  fetch(
    `https://michaelkjaco-streaming-node-app.onrender.com/api/video/getVideoDetailsById?video_id=${videoID}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success" && data.data.length > 0) {
        console.log("data.videoDetails views--", data);
        successCallback(data.data[0]); // Pass video details to the callback
      } else {
        failureCallback("Failed to fetch video details");
      }
    })
    .catch((error) => {
      console.error("Error fetching video details:", error);
      failureCallback(error);
    })
    .finally(() => {
      setLoading(false);
    });
};

useEffect(() => {
  const successCallback = (videoDetails) => {
    console.log("Video details fetched:", videoDetails);
    setVideoView(videoDetails); 
  };

  const failureCallback = (error) => {
    console.error("Error in fetching video details:", error);
    console.log("Failed to fetch video details");
  };

  if (videoID) {
    fetchVideoDetailsViews(successCallback, failureCallback); 
  }
}, [videoID]);

const handleShare = () => {
  const fullURL = `${videoURL}/${pageTitle}/${videoshareid}`;
  navigator.clipboard.writeText(fullURL)
    .then(() => {
      console.log("URL copied to clipboard!");
      setCopied(true); 
    })
    .catch((error) => {
      console.error("Failed to copy URL: ", error);
    });
};

const handleClose = () => {
  setCopied(false); 
};

useEffect(() => {
  const successCallback = (data) => {
    setProfileData(data[0]);
    setLoading(false); 
  };

  const failureCallback = (error) => {
    console.error("Error fetching profile data:", error); 
    setLoading(false); 
  };

  const fetchProfileData = async () => {
    setLoading(true); 
    profileUserData(null, successCallback, failureCallback);
  };

  fetchProfileData();
}, []);

  return (
    <>
      <Box>
        <MainHeader />
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={"48px"} />
          </Box>
        ) : (
          <>
            <Grid container mt={"24px"}>
              <Grid item lg={10} sx={{ px: 1.5 }}>
                {videoInfo?.url !== "" ? (
                  <>
                  
                    <video
                      style={{ width: "100%", height: "100%" }}
                      autoPlay={false}
                      controls
                      onPlay={handleRecentlyWatchedVideo}
                    >
                      <source src={videoInfo?.url} type="video/mp4" />
                    </video>
                    
                  </>
                ) : (
                  <CircularProgress color="success" />
                )}
              </Grid>
              <AddLiveChat room={'room1'} userName={username} />
              <Grid item lg={2}>
                <Box
                  sx={{
                    marginLeft: "2%",
                    height: "100%",
                    width: "15vw",
                    backgroundColor: "rgba(0,0,0,0.07)",
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              mt={"4px"}
              sx={{
                display: "flex",
                flexDirection: {
                  lg: "row",
                  xs: "column-reverse",
                  sm: "column-reverse",
                },
                justifyContent: {
                  lg: "flex-start",
                  xs: "center",
                  sm: "center",
                },
                padding: "8px",
                alignItems: { lg: "flex-start", xs: "center", sm: "center" },
                textAlign: { lg: "left", xs: "center", sm: "center" },
              }}
            >
              
              <Grid item lg={7} xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      md: "flex-start",
                      xs: "center",
                      sm: "center",
                    },
                    flexDirection: { md: "row", xs: "column", sm: "column" },
                    padding: "6px",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={LocalImages.michaelJaco}
                      style={{
                        width: "75px",
                        height: "75px",
                        ...(showLive && { border: "2px solid red" }),
                        borderRadius: "20%",
                        objectFit: "cover",
                      }}
                      alt="michaeljaco-logo"
                    />
                    {showLive ? (
                      <Typography
                        sx={{
                          position: "absolute",
                          backgroundColor: "red",
                          borderRadius: "4px",
                          textAlign: "center",
                          color: "#fff",
                          fontSize: "12px",
                          bottom: "8px",
                          padding: "0px 16px",
                          left: "15%",
                        }}
                      >
                        Live
                      </Typography>
                    ) : null}
                  </Box>

                  <Box sx={{ ml: "8px", mt: "16px" }}>
                    <Typography component={"h5"} variant="h5">
                      {videoInfo?.title || "Name of Show Title"}
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {videoInfo?.release_date
                        ? moment(videoInfo.release_date).format(
                            "h:mm A ,DD MMMM, YYYY"
                          )
                        : "Date of show"}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", mt: "8px" }}>
                      {videoInfo?.description || "Description"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item display="flex" xs={12} sm={12} lg={2} my="16px">
                 <FavoriteIcon
                    className="favoriteicon"
                    onClick={handleVideoLike}
                    sx={{
                      fill: videoLikeStatus === true ? "#18315C" : "transparent",
                      stroke: "#18315C",
                      strokeWidth: 2,
                      cursor: "pointer",
                    }}
                  />
                <Typography m="0px 8px">{videolikeCount}</Typography>
                <PeopleIcon />
                <Typography m="0px 8px">{videoviews.views}</Typography>
                {profileData && profileData.Role !== 4 ? (
                  <>
                    <TurnedInNotIcon
                      onClick={handleSaveVideo}
                      sx={{
                        fill: videoSaveStatus === true ? "#18315C" : "transparent",
                        stroke: "#18315C",
                        strokeWidth: 1,
                        cursor: "pointer",
                      }}
                    />
                    <Typography m="0px 8px">Save</Typography>
                  </>
                ) : null}
                <ShareIcon />
                <Typography m="0px 8px 0px 0px" onClick={handleShare} style={{ cursor: "pointer" }}>Share</Typography>
                <Snackbar
                  open={copied}
                  autoHideDuration={2000} // Message disappears after 2 seconds
                  onClose={handleClose}
                  message="Copied!"
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                />
              </Grid>

              <Grid item xs={0} sm={0} lg={2} />
            </Grid>
          </>
        )}

        <Divider sx={{ margin: "5% 0px 0px 0px" }} />

        <Grid container sx={{ paddingLeft: "3%" }}>
          <h2 className="video-title02">Recent Shows</h2>
        </Grid>
        {latestVideoFetching ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={"48px"} />
          </Box>
        ) : (
          <Box className="">
            <Container maxWidth="xl">
              <Box className="swiper-01">
                {showSwiper && (
                  <Swiper
                    navigation={true}
                    modules={[Navigation, Manipulation, Pagination]}
                    initialSlide={1}
                    // className="mySwiper"
                    effect={"coverflow"}
                    grabCursor={true}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 100,
                      depth: 600,
                      modifier: 1,
                      slideShadows: false,
                    }}
                    pagination={false}
                    spaceBetween={20}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      480: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 2,
                      },
                      767: {
                        slidesPerView: 2,
                      },
                      1000: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },

                    }}
                    autoplay
                    loop
                    slidesOffsetBefore={80}
                    slidesOffsetAfter={80}
                    sx={{
                      overflow: "hidden",
                      justifyContent: "center",
                      padding: { xs: "0%", md: "0 2%", xl: "0 5%" },
                    }}
                  >
                    {latestVideos?.list?.map((item) => (
                      <SwiperSlide
                        style={{
                          borderRadius: 10,
                          height: "180px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          generateUrl(item);
                        }}
                      >
                        <SwiperVideoCard
                          videoContainer={{ height: "100%" }}
                          description={item?.synopsis}
                          imageUrl={item.cover_image}
                          timeStamp={item?.duration}
                          title={item?.new_title}
                          key={item?.id}
                          imgStyle={{ width: "115%" }}
                          s3_video_id={item?.s3_video_id}
                          video_id={item?.video_id}
                          release_date={item?.release_date}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Box>
            </Container>
          </Box>
        )}
      </Box>

      <FixedBottomNavigation />
      <Footer />
    </>
  );
};

export default VideoStreaming;
