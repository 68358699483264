import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
  IconButton,
  Menu
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { videoDeleteById } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { profileUserData } from "../../Features/Services/profile";
import { watchVideoFetch } from "../../Features/Services/videolike";
import { LocalImages } from "../../Utils/images";

const Watchlater = () => {
  const [userID, setUserID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [videos, setVideos] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [videoDetails, setVideoDetails] = useState([]);  // Store video details here

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const successCallback = (data) => {
      setProfileData(data[0]);
      setIsLoading(false);
    };

    const failureCallback = (error) => {
      console.error("Error fetching profile data:", error);
      setIsLoading(false);
    };

    const fetchProfileData = async () => {
      setIsLoading(true);
      profileUserData(null, successCallback, failureCallback);
    };

    fetchProfileData();
  }, []);

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/send-notification" },
    { label: "Videos", path: "/videoGallery" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  const rolemenuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Watch Later", path: "/watchlater" },
    { label: "Collections", path: "/collection" },
    { label: "Profile Settings", path: "/profile" },
  ];

  useEffect(() => {
    const successCallback = (data) => {
      console.log("Videos fetched successfully:", data);
      setVideoDetails(data); 
      setIsLoading(false); 
    };

    const failureCallback = (error) => {
      console.error("Error fetching video data:", error);
      setIsLoading(false);
    };

    const fetchVideos = async () => {
      setIsLoading(true);
      await watchVideoFetch(null, successCallback, failureCallback); 
    };

    fetchVideos();
  }, []);


  const removeVideo = async (video_id) => {
    console.log("Attempting to delete video with ID:", video_id);
    if (!video_id) return;

    try {
      setIsLoading(true);
      const response = await videoDeleteById(video_id);
      setVideoDetails((prevVideos) =>
        prevVideos.filter((video) => video.video_id !== video_id)
      );
      setMessage("Video successfully deleted");
      console.log(`Video with ID: ${video_id} successfully deleted.`);

    } catch (error) {
      console.error("Error deleting video:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const ActionMenu = ({ video }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick}>
        <img
                                        src={LocalImages.groupIcon}
                                        style={{
                                            width: "auto",
                                            height: "100%",
                                        }}
                                    />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              if (!isLoading) removeVideo(video.video_id); // Disable click if loading
            }}
            style={{ color: isLoading ? "gray" : "red", pointerEvents: isLoading ? "none" : "auto" }}
          >
            Remove
          </MenuItem>

        </Menu>
      </>
    );
  };

  const handlevideoID = (videoId) => {
    navigate(`/videoStreaming/${videoId}`);
  };

  return (
    <>
      <MainHeader />
      <Backdrop
        open={isLoading}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
      <InnerHeader />

      <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "5%" }}>
        <div className="page-url-wrapper">
          {profileData && (
            <ul className="ul-content">
              {(profileData && profileData.Role === 1 || profileData.Role === 2 || profileData.Role === 3 || profileData.Role === 5 || profileData.Role === 6 || profileData.Role === null ? rolemenuItems : menuItems).map((item) => (
                <li key={item.label}>
                  <Link
                    to={item.path}
                    rel="noopener noreferrer"
                    className={`li-content ${location.pathname === item.path ? "active-link" : ""
                      }`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress size={"48px"} />
          </Backdrop>
        </div>


            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table>
                <TableHead sx={{ backgroundColor: "#19315c" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>S No</TableCell>
                    <TableCell sx={{ color: "white" }}>Video Title</TableCell>
                    <TableCell sx={{ color: "white" }}>Speaker Name</TableCell>
                    <TableCell sx={{ color: "white" }}>Views</TableCell>
                    <TableCell sx={{ color: "white" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {videoDetails.length > 0 ? (
                    videoDetails
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((video, index) => (
                        <TableRow key={video.video_id}>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell className="video-text-title" onClick={() => handlevideoID(video.video_id)}>{video.new_title}</TableCell>
                          <TableCell>{video.speaker_name}</TableCell>
                          <TableCell>{video.views}</TableCell>
                          <TableCell>
                            <ActionMenu video={video} />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="h6" color="textSecondary">
                          Video not found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>

            </TableContainer>

            {videoDetails.length > 5 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={videoDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            {message && (
              <Typography
                sx={{ mt: 2, textAlign: "center", color: "green" }}> {message}</Typography>

            )}
       

      </Container>

      <FixedBottomNavigation />
      <Footer />
    </>
  );
};

export default Watchlater;
